import * as yup from 'yup';
import errors from '../../constant/errors';

export const cFieldNames = {
  name: 'name',
  nameInternal: 'nameInternal',
  exportLabel: 'exportLabel',
  type: 'type',
  calculationType: 'calculationType',
  choices: 'choices',
  internalNote: 'internalNote',
  required: 'required',
  tooltip: 'tooltip',
  defaultValue: 'defaultValue',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.name]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.nameInternal]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.type]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.calculationType]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
});
