import { useEffect, useState } from 'react';
import { useApiPagination } from './useApiPagination';
import { FileResponse } from '../types/files.types';

export const useGalleryAPI = (initialUrl) => {
  const [modalOpen, setModal] = useState(false);
  const { data, isLoading, isError, reFetch, fetchData, pagination, changePage, changePerPage } =
    useApiPagination<FileResponse>(initialUrl, { pageSize: 20, pageNumber: 0 });

  useEffect(() => {
    fetchData(initialUrl, { pageSize: 100, pageNumber: 0 });
  }, []);

  const postNewItem = () => {
    reFetch();
  };

  const toggleModal = () => {
    setModal(!modalOpen);
  };
  return {
    data,
    isLoading,
    isError,
    toggleModal,
    modalOpen,
    postNewItem,
    pagination,
    changePage,
    changePerPage,
  };
};
