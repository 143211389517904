// styled component
import { Box, MenuItem, Pagination, Select, SelectChangeEvent, styled } from '@mui/material';
import React from 'react';
import { IPagination } from '../../hooks/useApiPagination';
import {
  PAGE_NUMBER_QUERY,
  PAGE_SIZE_QUERY,
  SetQueryParamFunc,
} from '../../hooks/query/generic/useGenericQueryParams';
import { toString } from 'ramda';

export const MUIStyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  '& .MuiPaginationItem-page:hover': {
    borderRadius: 20,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: 20,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiPaginationItem-previousNext': {
    margin: 10,
    borderRadius: 20,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': { backgroundColor: 'transparent' },
  },
}));

interface Props {
  data: IPagination<any> | undefined;
  setQueryParam: SetQueryParamFunc | undefined;
  sizes?: number[];
}

export const StyledPagination: React.FC<Props> = ({
  data,
  setQueryParam,
  sizes = [10, 25, 50, 100],
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (setQueryParam) {
      setQueryParam(PAGE_NUMBER_QUERY, value === 1 ? 0 : value - 1);
    }
  };

  const pageSizeChange = (event: SelectChangeEvent) => {
    if (setQueryParam) {
      setQueryParam(PAGE_SIZE_QUERY, event.target.value);
    }
  };
  if (!setQueryParam || !data) {
    return <></>;
  }
  return (
    <Box display={'flex'} mt={1} justifyContent={'flex-end'}>
      <Select
        value={toString(data.elementsPerPage)}
        onChange={pageSizeChange}
        size={'small'}
        disableUnderline
        variant={'standard'}
      >
        {sizes?.map((s) => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
      <Box mx={2} />
      <MUIStyledPagination
        count={data.totalPages ?? 0}
        shape="rounded"
        onChange={handleChange}
        page={data.pageNumber + 1}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
