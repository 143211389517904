import { File } from './files.types';

export enum InputType {
  TEXT = 'TEXT',
  INT = 'INT',
  CHOICE = 'CHOICE',
  SELECT = 'SELECT',
}

export enum CalculationInputType {
  BOOL = 'BOOL',
  PERCENT = 'PERCENT',
  MIN = 'MIN',
  MULTIPLY = 'MULTIPLY',
  SKIP = 'SKIP',
}

export interface InputChoice {
  id?: number;
  value: string;
  description: string;
  tooltip: string;
  image?: File;
  imageId?: number;
}
export interface Input {
  id: number;
  name: string;
  nameInternal: string;
  internalNote: string;
  exportLabel: string;
  type: InputType;
  calculationType: CalculationInputType;
  choices: Array<InputChoice>;
  required: boolean;
  tooltip: string;
}

export const typeOptions = [
  {
    id: InputType.INT,
    name: 'Číslo',
  },
  {
    id: InputType.TEXT,
    name: 'Text',
  },
  {
    id: InputType.CHOICE,
    name: 'Výběr radio',
  },
  {
    id: InputType.SELECT,
    name: 'Výběr',
  },
];

export const calculationTypeOptions = [
  {
    id: CalculationInputType.BOOL,
    name: 'Podmínka',
  },
  {
    id: CalculationInputType.MIN,
    name: 'Minimum',
  },
  {
    id: CalculationInputType.MULTIPLY,
    name: 'Násobení',
  },
  {
    id: CalculationInputType.PERCENT,
    name: 'Procento',
  },
  {
    id: CalculationInputType.SKIP,
    name: 'Přeskočit',
  },
];

export const NewInput: Input = {
  id: 0,
  name: '',
  nameInternal: '',
  internalNote: '',
  exportLabel: '',
  type: InputType.TEXT,
  calculationType: CalculationInputType.MULTIPLY,
  choices: [],
  required: false,
  tooltip: '',
};
export const NewInputChoice: InputChoice = {
  value: '',
  description: '',
  tooltip: '',
  imageId: undefined,
};
export type InputResponse = Array<Input>;
