import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import commonEn from './locale/en/common.json';
import commonCs from './locale/cs/common.json';
import formsCs from './locale/cs/forms.json';
import formsEn from './locale/en/forms.json';
import errorsCs from './locale/cs/errors.json';
import errorsEn from './locale/en/errors.json';
import tableEn from './locale/en/table.json';
import tableCs from './locale/cs/table.json';
import pagesEn from './locale/en/pages.json';
import pagesCs from './locale/cs/pages.json';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'cs',
        debug: true,
        resources: {
            en: {
                common: commonEn, // 'common' is our custom namespace
                forms: formsEn, // 'common' is our custom namespace
                errors: errorsEn,
                tables: tableEn,
                pages: pagesEn,
            },
            cs: {
                common: commonCs,
                forms: formsCs,
                errors: errorsCs, // 'common' is our custom namespace
                tables: tableCs,
                pages: pagesCs,
            },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
