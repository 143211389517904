// @ts-ignore
import ID from '../dnd/UUID';

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination): Array<any> => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  // @ts-ignore
  destClone.splice(droppableDestination.index, 0, { ...item });
  return destClone;
};

const move = (
  source,
  destination,
  droppableSource,
  droppableDestination,
): [Array<any>, Array<any>] => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  return [sourceClone, destClone];
};

interface IDragAndDropHelpers {
  move: (source, destination, droppableSource, droppableDestination) => [Array<any>, Array<any>];
  copy: (source, destination, droppableSource, droppableDestination) => Array<any>;
  reorder: (list: Array<any>, startIndex: number, endIndex: number) => Array<any>;
}
export const DragAndDropHelpers: IDragAndDropHelpers = {
  move: move,
  copy: copy,
  reorder: reorder,
};
