import { Autocomplete, Box } from '@mui/material';
import React, { SyntheticEvent, useContext } from 'react';
import { SelectMenuItem } from './TextFormikInput';
import { FormikFieldTypes } from './types';
import CustomTextField from '../CustomElements/CustomTextField';
import { isEmpty, toString } from 'ramda';
import CustomFormLabel from '../CustomElements/CustomFormLabel';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';
import { isNilOrEmpty } from '../../../utils/ramda_utils';

interface Props extends FormikFieldTypes {
  options: Array<SelectMenuItem>;
  name: string;
  onChange?: (e: SyntheticEvent, data: any) => void;
  label: string;
}

export const AutocompleteSyncFormik = ({ options, name, field, onChange, form }: Props) => {
  const { label: labelTranslate } = useContext(FormTranslationContext);

  const opt = options.map((i) => ({
    ...i,
    label: i.name,
  }));

  const searchForOption = (id: number) => opt.filter((o) => o.id === id);
  return (
    <>
      {!isEmpty(labelTranslate(field.name)) && (
        <CustomFormLabel htmlFor={name}>{labelTranslate(field.name)}</CustomFormLabel>
      )}
      <Autocomplete
        value={isNilOrEmpty(searchForOption(field.value)) ? null : searchForOption(field.value)[0]}
        autoHighlight
        options={opt}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(event, newValue);
          } else {
            if (newValue) {
              form.setFieldValue(field.name, newValue.id);
            }
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value;
        }}
        getOptionLabel={(option) => toString(option.name)}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.name}
          </Box>
        )}
        fullWidth
        renderInput={(params) => (
          <CustomTextField
            {...params}
            size={'small'}
            fullWidth
            variant={'outlined'}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
};
