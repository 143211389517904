import { useFormikContext } from 'formik';
import { and, path, split } from 'ramda';
import { isNilOrEmpty } from '../utils/ramda_utils';
import { useContext } from 'react';
import { FormTranslationContext } from '../context/FormTranlsationContext';

export const useFormikFieldError = (name: string): [boolean, string] => {
  // @ts-ignore
  const { tError } = useContext(FormTranslationContext);
  const { errors, touched } = useFormikContext();
  const errorText = path<string>(split('.', name), errors);
  const isError = !isNilOrEmpty(errorText);
  const isTouched = !isNilOrEmpty(path(split('.', name), touched));
  // @ts-ignore
  const error = and(isError, isTouched);
  return [error, tError(errorText ?? '')];
};
