import errors from '../../constant/errors';
import * as yup from 'yup';

export const cFieldNames = {
  password: 'password',
  passwordAgain: 'passwordAgain',
};

export interface ChangePasswordFormFields {
  password: string;
  passwordAgain: string;
}
export const initialValues: ChangePasswordFormFields = {
  password: '',
  passwordAgain: '',
};
export const validationSchema = yup.object().shape({
  [cFieldNames.password]: yup
    .string()
    .min(6, errors.MIN_6_ERROR)
    .max(100, 'error')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])/,
      'Must Contain One Uppercase, One Lowercase, One Number, One special case character',
    )
    .required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.passwordAgain]: yup
    .string()
    .oneOf([yup.ref(cFieldNames.password), null], 'Passwords must match')
    .required(errors.FIELD_REQUIRED_ERROR),
});
