import React from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { Step } from '../../types/step.types';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { PATHS } from '../../routes/paths';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Card, CardContent, LinearProgress } from '@mui/material';
import { NavButton } from '../../components/NavButton/NavButton';
import { useTranslation } from 'react-i18next';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { equals } from 'ramda';
import { StepsTable } from '../../tables';
import { useStepsFetch } from '../../hooks/query/steps/useStepsFetch';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { useStepsDeleteMutation } from '../../hooks/query/steps/useDeleteStepsMutation';
import { useOnSortEndSteps } from '../../hooks/query/steps/useOnSortEndSteps';

export const Steps = () => {
  const {
    apiCall: { data, isLoading, isFetching, isError },
    params,
    setQueryParam,
  } = useStepsFetch();
  const deleteMutation = useStepsDeleteMutation();
  const onSortEndMutation = useOnSortEndSteps(params);
  const { t } = useTranslation('pages');

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (equals(oldIndex, newIndex)) return;
    if (!data) return;
    const newArray: Array<Step> = arrayMove<Step>(data?.elements ?? [], oldIndex, newIndex);
    const newOrder = newArray.map((s, index) => ({ id: s.id, sort: index }));
    onSortEndMutation.mutateAsync({ newOrder, newArray, oldIndex, newIndex });
  };

  const onDelete = async (id) => deleteMutation.mutateAsync({ id });

  return (
    <PageContainer>
      <Breadcrumb title={t('STEPS')} items={[{ title: 'STEPS' }]}>
        <NavButton title={t('ADD_BUTTON')} to={`${PATHS.STEPS_PATH}${PATHS.NEW}`} icon={'plus'} />
      </Breadcrumb>
      <Card>
        <CardContent>
          {isLoading || isFetching || (onSortEndMutation.isLoading && <LinearProgress />)}
          <LoadingErrorContainer isError={isError}>
            <StepsTable
              data={data?.elements}
              pagination={<StyledPagination data={data} setQueryParam={setQueryParam} />}
              onDelete={onDelete}
              onSortEnd={onSortEnd}
            />
          </LoadingErrorContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
