import * as yup from 'yup';
import { NewUser } from '../../types/users.types';
import { TENANT_SROVNEJSITO } from '../../constant/config';
import errors from '../../constant/errors';

export const cFieldNames = {
  authsPassword: 'auths[0].password',
  authsSelector: 'auths[0].selector',
  password: 'password',
  email: 'email',
  profile: 'profile.fullname',
  scopes: 'scopes',
  tenants: 'tenants',
  userId: 'userId',
  verificationDate: 'verificationDate',
  verified: 'verified',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.password]: yup
    .string()
    .min(6, errors.MIN_6_ERROR)
    .max(100, 'error')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])/,
      'Must Contain One Uppercase, One Lowercase, One Number, One special case character',
    )
    .required(errors.FIELD_REQUIRED_ERROR)
});

export const initialValues: NewUser = {
  auths: [
    {
      password: '',
      selector: '',
    },
  ],
  email: '',
  profile: {
    fullname: '',
  },
  tenants: [TENANT_SROVNEJSITO],
};
