import React from 'react';
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

export interface BreadCrumbItem {
  title: string;
  to?: string;
}

interface Props {
  subtitle?: string;
  items?: Array<BreadCrumbItem>;
  title: string;
  children?: any;
  crumbs?: BreadCrumbItem[];
}

const Breadcrumb = ({ items, title, children }: Props) => {
  const { t } = useTranslation('pages');
  return (
    <Grid
      container
      sx={{
        p: '15px',
      }}
    >
      <Grid item xs={12} sm={6} lg={8}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link
            underline="none"
            color="inherit"
            style={{ display: 'flex', alignItems: 'center' }}
            component={NavLink}
            to={'/'}
          >
            {t('HOME')}
          </Link>
          {items
            ? items.map((item) => (
                <div key={item.title}>
                  {item.to ? (
                    <Link underline="none" color="inherit" component={NavLink} to={item.to}>
                      {t(item.title)}
                    </Link>
                  ) : (
                    <Typography color="textPrimary">{t(item.title)}</Typography>
                  )}
                </div>
              ))
            : ''}
        </Breadcrumbs>
        <Typography
          fontWeight="700"
          variant="h1"
          sx={{
            lineHeight: '1.235',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4} display="flex" alignItems="flex-end">
        <Box
          sx={{
            display: { xs: 'none', md: 'block', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
export default Breadcrumb;
