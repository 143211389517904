import { File } from './files.types';

export interface Bank {
  id: number;
  name: string;
  image?: File;
  url: string;
}

export const NewBank: Bank = {
  id: 0,
  name: '',
  url: '',
};
export type BankResponse = Array<Bank>;
