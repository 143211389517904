import { PaginationSearchQueryParams, UseQueryWithParams } from '../generic/useGenericQueryParams';
import { IPagination } from '../../useApiPagination';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { AUTH_USERS_API } from '../../../constant/api_url';
import { UserProfile } from '../../../types/users.types';

export const useUsersFetch = (): UseQueryWithParams<IPagination<UserProfile>,
  PaginationSearchQueryParams> => {
  return usePaginationQueryParams<UserProfile>(AUTH_USERS_API);
};
