import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { NewUser, ProfileUpdate } from '../../../types/users.types';
import { axiosAuth } from '../../../utils/apiUtils';
import { AUTH_USER_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export const useUpdateProfile = () => {
  return useMutation<AxiosResponse<NewUser>, AxiosError, ProfileUpdate>(
    (data: ProfileUpdate) => axiosAuth.patch<NewUser>(AUTH_USER_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat profil');
      },
      onSuccess: () => {
        toast.success('Profil byl aktualizován');
      },
    },
  );
};
