import {
  PAGE_SIZE_QUERY,
  PaginationSearchQueryParams,
  UseQueryWithParams,
} from '../generic/useGenericQueryParams';
import { IPagination } from '../../useApiPagination';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { File } from '../../../types/files.types';
import { NumberParam, withDefault } from 'use-query-params';

export const useGalleryFetch = (): UseQueryWithParams<
  IPagination<File>,
  PaginationSearchQueryParams
> => {
  return usePaginationQueryParams<File>('/file', {
    [PAGE_SIZE_QUERY]: withDefault(NumberParam, 16),
  });
};
