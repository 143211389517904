const errors = {
  INVALID_EMAIL_ERROR: 'INVALID_EMAIL_ERROR',
  FIELD_REQUIRED_ERROR: 'FIELD_REQUIRED_ERROR',
  NO_NUMBER_ERROR: 'NO_NUMBER_ERROR',
  MIN_9_ERROR: 'MIN_9_ERROR',
  MIN_6_ERROR: 'MIN_6_ERROR',
  PASSWORD_ERROR: 'PASSWORD_ERROR',
  PASSWORD_NO_MATCH: 'PASSWORD_NO_MATCH',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
};
export default errors;
