import React from 'react';
import { useModal } from '../../../hooks/useModal';
import modals from '../../../constant/modals';
import { InputChoiceForm } from '../../../forms/Input/choices/InputChoiceForm';
import { isEmpty, isNil, not } from 'ramda';
import { isFunction } from 'formik';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useCreateInputChoice,
  useEditInputChoice,
} from '../../../hooks/query/inputChoice/useCreateEditInputChoice';

export const NewInputChoiceModal = ({ id, onAdd, onUpdate }) => {
  const { isOpen, close, payload, setContent } = useModal(modals.NEW_INPUT_CHOICE);
  const { t } = useTranslation('forms');
  const createMutation = useCreateInputChoice();
  const editMutation = useEditInputChoice();
  const onSubmit = async (values) => {
    if (not(isNil(payload) || isEmpty(payload))) {
      // IS in item edit mode
      if (isNil(id) || isEmpty(id)) {
        // Parameter entity not created, locale adding values NO API CALL
        isFunction(onUpdate) && onUpdate(values, payload.index);
        setContent(null);
        return close();
      } else {
        // I am in parameter edit, edit item direclty via API
        editMutation.mutateAsync({ id, values }).then((value) => {
          onUpdate(value.data, payload.index);
          setContent(null);
          return close();
        });
      }
    } else {
      // Not in edit mode, new item creating
      if (isNil(id) || isEmpty(id)) {
        // I am NOT in edit parameter entity, so add item locally noAPI CALL
        isFunction(onAdd) && onAdd(values);
        return close();
      } else {
        // I am in edit parameter entity mode, so create it via API call
        createMutation.mutateAsync({ id, values }).then((value) => {
          onAdd(value.data);
          close();
        });
      }
    }
  };
  return (
    <Dialog open={isOpen} onClose={close} maxWidth={'sm'} fullWidth>
      <DialogTitle className={'justify-content-center'}>{t('INPUT_CHOICE.title')}</DialogTitle>
      <DialogContent>
        <InputChoiceForm
          data={payload.value}
          onSubmit={onSubmit}
          loading={createMutation.isLoading || editMutation.isLoading}
          onClose={close}
        />
      </DialogContent>
    </Dialog>
  );
};
