import React, { useContext } from 'react';
import { equals, isEmpty, isNil, propOr } from 'ramda';
import { isFunction } from 'formik';
import { MenuItem } from '@mui/material';
import CustomFormLabel from '../CustomElements/CustomFormLabel';
import CustomTextField from '../CustomElements/CustomTextField';
import { FormikFieldTypes } from './types';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';
import { FormikFieldError } from './FormikFieldError';
import { useFormikFieldError } from '../../../hooks/useFormikFieldError';

export interface SelectMenuItem {
  id: any;
  defaultValue?: string | number;
  name: any;
}

export interface InputProps {
  noLabel?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (e) => void;
  onBlur?: (e) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  name: string;
  disableFormikSetFieldValue?: boolean;
  multiline?: boolean;
  maxRows?: number;
  rows?: number;
  type?: InputTypesProps;
  size?: InputSizesProps;
}

export type TextFormikFieldProps = InputProps;

export interface SelectFormikFieldProps extends InputProps {
  options: Array<SelectMenuItem>;
}

export type InputTypesProps = 'text' | 'number' | 'select' | 'password' | undefined;
export type InputSizesProps = 'small' | 'medium' | undefined;

export interface InputTypeProps {
  type?: InputTypesProps;
}

export interface Props extends InputProps, SelectFormikFieldProps, FormikFieldTypes {}

export const TextFormikInput = ({
  name,
  onChange,
  onBlur,
  disabled,
  fullWidth,
  field,
  form,
  disableFormikSetFieldValue,
  type,
  options,
  multiline,
  maxRows,
  rows,
  size = 'small',
}: Props) => {
  const { label: labelTranslate, placeholder } = useContext(FormTranslationContext);
  const [error, errorText] = useFormikFieldError(field.name);

  const onFieldBlur = (e) => {
    isFunction(onBlur) && onBlur(e);
    field.onBlur(e);
  };
  const onFieldChange = ({ target }) => {
    !disableFormikSetFieldValue &&
      form.setFieldValue(field.name, equals(type, 'number') ? Number(target.value) : target.value);
    isFunction(onChange) && onChange(target.value);
  };

  return (
    <>
      {!isEmpty(labelTranslate(field.name)) && (
        <CustomFormLabel htmlFor={name}>{labelTranslate(field.name)}</CustomFormLabel>
      )}
      <CustomTextField
        {...field}
        id={name}
        placeholder={placeholder(field.name)}
        variant="outlined"
        fullWidth={fullWidth ?? true}
        disabled={disabled}
        size={size}
        type={type ?? 'text'}
        multiline={multiline}
        maxRows={maxRows}
        rows={rows}
        value={propOr('', 'value', field)}
        error={error}
        // helperText={translatedErrorText}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        // InputProps={{}}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) =>
              `${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#dee3e9'}`,
            '& input:invalid + fieldset': {
              borderColor: 'rgb(252, 75, 108)!important',
            },
          },
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              defaultValue={option.defaultValue}
              value={!isNil(option.id) ? option.id : ''}
              key={option.id}
            >
              {option.name}
            </MenuItem>
          ))}
      </CustomTextField>
      <FormikFieldError error={error} text={errorText} />
    </>
  );
};
