import React from 'react';

import RUG from 'react-upload-gallery';
import { DragADropHeaderArea } from './DragADropHeaderArea';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { customRequest } from '../../api/custom_request_file';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  close: () => void;
  addItem: () => void;
  removeItem: (i: number) => void;
}
const NewGalleryItem = ({ open, close, addItem }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Dialog open={open} onClose={close} maxWidth={'md'} fullWidth>
      <DialogTitle className={'text-center d-flex justify-content-center'}>Galerie</DialogTitle>
      <DialogContent>
        <RUG
          header={({ openDialogue }) => (
            <DragADropHeaderArea openDialogue={openDialogue} hideGallerySelect />
          )}
          sorting={false}
          source={(response) => response.url}
          accept={['jpg', 'png', 'gif', 'jpeg']}
          customRequest={customRequest(addItem)}
          initialState={[]}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'end'} alignItems={'center'}>
          <Grid item>
            <Button color={'primary'} variant={'outlined'} onClick={close}>
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default NewGalleryItem;
