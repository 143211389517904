import { authFetcher, QueryKeyOnlyT } from '../reactQuery';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { UserProfile } from '../../../types/users.types';
import { AUTH_GET_ME_API } from '../../../constant/api_url';

export const useGetMe = () =>
  useQuery<UserProfile, AxiosError, UserProfile, QueryKeyOnlyT>(
    [AUTH_GET_ME_API!],
    // @ts-ignore
    ({ queryKey }) => authFetcher<UserProfile>({ queryKey }),
  );
