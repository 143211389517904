import { ActionMap } from './AuthContext';
import { User } from '../../types/user.types';

export enum Types {
  Init = 'INIT',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: User };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
};

export const authInitialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
