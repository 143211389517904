import { Bank } from '../../../types/bank.types';
import { BANK_API } from '../../../constant/api_url';
import { IPagination } from '../../useApiPagination';
import { PaginationSearchQueryParams, UseQueryWithParams } from '../generic/useGenericQueryParams';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { useGetFetch } from '../reactQuery';

export const useBanksFetch = (): UseQueryWithParams<
  IPagination<Bank>,
  PaginationSearchQueryParams
> => {
  return usePaginationQueryParams<Bank>(BANK_API);
};

export const useBanksAll = () => useGetFetch<IPagination<Bank>>(BANK_API);
