import { cond, equals } from 'ramda';
import { DragAndDropHelpers } from '../../components/StepBuilder/DragAndDropHelpers';
import { ReducerState } from './ProductBankDataProvider';
import { ACTIONS } from './actions';
import { ProductBankInput } from '../../types/productBankInput.types';
import { Input } from '../../types/input.types';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { NewProductBank } from '../../types/productBanks.types';

export const reducer = (state: ReducerState<Input, ProductBankInput>, action) =>
  cond([
    [
      equals(ACTIONS.LOAD_DATA),
      () => {
        return {
          ...state,
          data: action.payload,
        };
      },
    ],
    [
      equals(ACTIONS.LOAD_INPUTS),
      () => {
        const newInputs = action.payload;
        return {
          ...state,
          inputs: newInputs,
        };
      },
    ],
    [
      equals(ACTIONS.MOVE),
      () => {
        const [inputs, data] = DragAndDropHelpers.move(
          state.inputs,
          state.data,
          action.droppableSource,
          action.droppableDestination,
        );
        const input = state.inputs[action.droppableSource.index];
        const dest: ProductBankInput = state.data[action.droppableDestination.index];
        data[action.droppableDestination.index] = {
          input,
          value: 0,
          sort: action.droppableDestination.index,
          productBank: isNilOrEmpty(dest) ? NewProductBank : dest.productBank,
          id: {},
        };

        return {
          inputs,
          data,
        };
      },
    ],
    [
      equals(ACTIONS.DELETE),
      () => {
        const [data, inputs] = DragAndDropHelpers.move(
          state.data,
          state.inputs,
          action.droppableSource,
          action.droppableDestination,
        );
        const productBankInput: ProductBankInput = state.data[action.droppableSource.index];
        inputs[action.droppableDestination.index] = productBankInput.input;
        return {
          inputs,
          data: data,
        };
      },
    ],
    [
      equals(ACTIONS.REORDER),
      () => {
        const newArray = DragAndDropHelpers.reorder(state.data, action.oldIndex, action.newIndex);
        return {
          ...state,
          data: newArray,
        };
      },
    ],
    [
      equals(ACTIONS.SET_DATA),
      () => {
        return {
          ...state,
          data: action.payload,
        };
      },
    ],
    [
      equals(ACTIONS.UPDATE_PRODUCT_BANK_INPUT_VALUE),
      () => {
        const value = action.payload.value;
        const inputId = action.payload.inputId;
        const arrayWithUpdatedItem = state.data.map((i) =>
          equals(i.input.id, inputId) ? { ...i, value: value } : i,
        );
        return {
          ...state,
          data: arrayWithUpdatedItem,
        };
      },
    ],
    [
      equals(ACTIONS.UPDATE_PRODUCT_BANK_INPUT_MIN),
      () => {
        const value = action.payload.value;
        const inputId = action.payload.inputId;
        const arrayWithUpdatedItem = state.data.map((i) =>
          equals(i.input.id, inputId) ? { ...i, min: value } : i,
        );
        return {
          ...state,
          data: arrayWithUpdatedItem,
        };
      },
    ],
    [
      equals(ACTIONS.UPDATE_PRODUCT_BANK_INPUT_TYPE),
      () => {
        const value = action.payload.value;
        const inputId = action.payload.inputId;
        const arrayWithUpdatedItem = state.data.map((i) =>
          equals(i.input.id, inputId) ? { ...i, type: value } : i,
        );
        return {
          ...state,
          data: arrayWithUpdatedItem,
        };
      },
    ],
    [
      equals(ACTIONS.UPDATE_PRODUCT_BANK_INPUT_CREATE_VALUE),
      () => {
        const value = action.payload.value;
        const inputId = action.payload.inputId;
        const arrayWithUpdatedItem = state.data.map((i) =>
          equals(i.input.id, inputId) ? { ...i, createValue: value } : i,
        );
        return {
          ...state,
          data: arrayWithUpdatedItem,
        };
      },
    ],
    [
      equals(ACTIONS.REPLACE_PRODUCT_BANK_INPUT_BY_INPUT),
      () => {
        const newProductInput: ProductBankInput = action.payload;
        const data = state.data.map((p) =>
          equals(p.input.id, newProductInput.input.id) ? newProductInput : p,
        );
        return {
          ...state,
          data,
        };
      },
    ],
    // @ts-ignore
  ])(action.type);
