import BaseCard from '../BaseCard/BaseCard';
import { Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { Input } from '../../types/input.types';
import { ProductBankInput } from '../../types/productBankInput.types';
import { useProductBankInputData } from '../../hooks/useProductBankInputData';
import { Delete, Save } from '@mui/icons-material';
import { propOr } from 'ramda';

interface Props {
  input: Input;
  onDelete?: (id: number) => void;
  onRequiredClick?: (id: number) => void;
  deleteAllowed?: boolean;
  handle?: any;
  hideInput?: boolean;
  productBankInput?: ProductBankInput;
  onButtonValueSaveClick?: (
    value: string,
    min: string,
    createValue: string,
    inputId: number,
  ) => void;
}

export const InputDraggableItemWithExportLabelAndInput = ({
  input,
  handle,
  hideInput,
  productBankInput,
  onButtonValueSaveClick,
  onDelete,
  deleteAllowed,
}: Props) => {
  const {
    updateProductBankInputValue,
    updateProductBankInputMin,
    updateProductBankInputCreateValue,
  } = useProductBankInputData();
  return (
    <BaseCard
      style={{ borderRadius: '5px' }}
      cardContentStyle={{ padding: '0.2rem 0.5rem' }}
      cardHeaderStyle={{ padding: '0.3rem 0.5rem' }}
      title={
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={9}>
            <span style={{ fontSize: '15px' }}>{input.nameInternal}</span>
          </Grid>
          <Grid container item xs={2} alignItems={'center'} justifyContent={'end'}>
            <Grid item>
              {deleteAllowed && (
                <Tooltip title="Odstranit toto pole?">
                  <IconButton
                    size={'small'}
                    onClick={() => {
                      onDelete && onDelete(input.id);
                    }}
                  >
                    <Delete fontSize={'small'} color={'error'} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item>{handle}</Grid>
          </Grid>
        </Grid>
      }
    >
      <Box ml={1} mb={1}>
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <span style={{ fontSize: '14px' }}>{input.internalNote}</span>
          </Grid>
          <Grid container item spacing={1}>
            {!hideInput && (
              <Grid item sm={4}>
                <TextField
                  size={'small'}
                  onChange={(e) => updateProductBankInputValue(e.target.value, input?.id)}
                  value={productBankInput?.value}
                  label={'Hodnota'}
                />
              </Grid>
            )}
            {!hideInput && (
              <Grid item sm={4}>
                <TextField
                  size={'small'}
                  onChange={(e) => updateProductBankInputMin(e.target.value, input?.id)}
                  value={productBankInput?.min ?? 0}
                  label={'Min'}
                />
              </Grid>
            )}
            {!hideInput && (
              <Grid item sm={3}>
                <TextField
                  size={'small'}
                  onChange={(e) => updateProductBankInputCreateValue(e.target.value, input?.id)}
                  value={productBankInput?.createValue ?? 0}
                  label={'Založení'}
                />
              </Grid>
            )}
            {!hideInput && (
              <Grid item sm={1}>
                <IconButton
                  size={'medium'}
                  onClick={() =>
                    onButtonValueSaveClick &&
                    onButtonValueSaveClick(
                      propOr('', 'value', productBankInput),
                      propOr('', 'min', productBankInput),
                      propOr('', 'createValue', productBankInput),
                      input.id,
                    )
                  }
                >
                  <Save color={'primary'} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </BaseCard>
  );
};
