import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { BANK_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { PATHS } from '../../../routes/paths';
import { Bank } from '../../../types/bank.types';

export const useCreateBank = () => {
  const navigate = useNavigate();
  return useMutation<AxiosResponse<Bank>, AxiosError, Bank>(
    (data: Bank) => axios.post<Bank>(BANK_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit banku');
      },
      onSuccess: ({ data }) => {
        toast.success('Banka byla vytvořena');
        navigate(`${PATHS.BANKS_PATH}${PATHS.EDIT}/${data.id}`);
      },
    },
  );
};

export const useEditBank = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Bank>, AxiosError, Bank>(
    (data: Bank) => axios.put<Bank>(`${BANK_API}/${data.id}`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat banku');
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([`${BANK_API}/${data.id}`]);
        toast.success('Banka byla aktualizována');
      },
    },
  );
};
