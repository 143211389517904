import { Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Grid, Paper } from '@mui/material';
import React from 'react';
import { Notice } from '../StepBuilder/Droppable';
import { ProductBankInputDraggableListItem } from './ProductBankInputDraggableListItem';
import { DroppableZones } from '../../context/ProductBankDataContext/DroppableZones';
import { ProductBankInput } from '../../types/productBankInput.types';

interface Props {
  items: ProductBankInput[];
  onDelete: (result: DropResult) => void;
  droppableId: DroppableZones;
  onButtonValueSaveClick?: (
    value: string,
    min: string,
    createValue: string,
    inputId: number,
  ) => void;
}

export const ProductBankInputDroppableArea = ({
  droppableId,
  items,
  onDelete,
  onButtonValueSaveClick,
}: Props) => {
  return (
    <>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <Paper variant={'outlined'} ref={provided.innerRef}>
            <Box
              sx={{
                pt: 1,
                pb: 1,
                m: 2,
              }}
            >
              <Grid container>
                {items
                  ? items.map((item, index) => (
                      <Grid item xs={12}>
                        <ProductBankInputDraggableListItem
                          onDelete={() =>
                            onDelete({
                              source: { index: index, droppableId: droppableId },
                              destination: {
                                index: 0,
                                droppableId: DroppableZones.PRODUCT_BANK_FREE_INPUTS,
                              },
                            } as DropResult)
                          }
                          onButtonValueSaveClick={onButtonValueSaveClick}
                          key={item.input.id}
                          item={item}
                          index={index}
                        />
                      </Grid>
                    ))
                  : !provided.placeholder && <Notice>Drop items here</Notice>}
              </Grid>
              {provided.placeholder}
            </Box>
          </Paper>
        )}
      </Droppable>
    </>
  );
};
