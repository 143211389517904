import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { AUTH_USER_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { PATHS } from '../../../routes/paths';
import { axiosAuth } from '../../../utils/apiUtils';
import { NewUser } from '../../../types/users.types';

export const useCreateUserMutation = () => {
  const navigate = useNavigate();
  return useMutation<AxiosResponse<NewUser>, AxiosError, NewUser>(
    (data: NewUser) => axiosAuth.post<NewUser>(AUTH_USER_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit uživatele');
      },
      onSuccess: () => {
        toast.success('Uživatel byl vytvořen');
        navigate(`${PATHS.USERS}`);
      },
    },
  );
};
