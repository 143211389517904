export const BANK_API = '/v1/bank';

export const STEP_API = '/v1/step';

export const INPUT_API = '/v1/input';

export const GALLERY_API = '/v1/gallery';

export const PRODUCT_BANK_API = '/v1/bank-product';

export const CHANGE_PASSWORD = '/v1/account/change-password';

export const RESET_PASSWORD = '/public/v1/account/reset-password';

export const REQUEST_PASSWORD_CHANGE = '/public/v1/account/request-change-password';

export const AUTH_USERS_API = '/v1/users';

export const AUTH_USER_API = '/v1/user';

export const AUTH_GET_ME_API = '/v1/me';
