import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from 'ramda-extension';
import { identity, not } from 'ramda';
import { FormikFieldTypes } from '../Forms/Formik/types';
import { serverCore } from '../../config';
import GalleryModal from '../GalleryModal';
import { ImageItem } from '../NewGalleryItem/ImageItem';
import { ImageSearchOutlined } from '@mui/icons-material';
import { useFormikFieldError } from '../../hooks/useFormikFieldError';
import { useFormikContext } from 'formik';
import { FormikFieldError } from '../Forms/Formik/FormikFieldError';
import { Button } from '@mui/material';

interface Props extends FormikFieldTypes {
  imageDefault?: any;
  valueAsObject?: boolean;
  rectangleSelect?: boolean;
  close?: () => void;
  smallPreview?: boolean;
  muiPreview?: boolean;
}

const ImageSelect = ({ field: { name }, imageDefault, valueAsObject }: Props) => {
  const [error, errorText] = useFormikFieldError(name);
  const { setFieldValue } = useFormikContext();
  const [image, setImage] = useState(imageDefault);
  const [modalOpen, setModalOpen] = useState(false);

  const onRemove = () => {
    setFieldValue(name, null);
    setImage(null);
  };

  const open = () => setModalOpen(true);
  useEffect(() => {
    if (not(isNilOrEmpty(imageDefault))) {
      setImage({
        ...imageDefault,
        source: `${serverCore}${imageDefault.url}`,
      });
      if (valueAsObject) {
        setFieldValue(name, imageDefault, true);
      } else {
        setFieldValue(name, imageDefault.id, true);
      }
    }
  }, [imageDefault]);

  return (
    <div className="">
      <GalleryModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        onSelected={(i) => {
          if (valueAsObject) {
            setFieldValue(name, i);
          } else {
            setFieldValue(name, i.id);
          }
          setImage(i);
          setModalOpen(false);
        }}
      />
      {isNilOrEmpty(image) ? (
        <Button
          color={error ? 'error' : 'primary'}
          variant={'outlined'}
          startIcon={<ImageSearchOutlined />}
          onClick={open}
        >
          Vybrat obrázek
        </Button>
      ) : (
        <ImageItem
          noConfirm
          onDelete={onRemove}
          image={{ ...image, remove: onRemove }}
          smallPreview
          onClick={identity}
        />
      )}

      <FormikFieldError error={error} text={errorText} />
    </div>
  );
};
export default ImageSelect;
