import React from 'react';

import { Box, Table as MaUTable, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { Column, TableProps, useExpanded, useTable } from 'react-table';
// @ts-ignore
import {
  // @ts-ignore
  sortableContainer,
  // @ts-ignore
  sortableElement,
  // @ts-ignore
  sortableHandle,
  SortEnd,
  SortEvent,
} from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ReactTableTableBody } from './ReaactTableTableBody';
import DeleteOrUpdate from '../../DeleteOrUpdate';
import { IDeleteOrUpdate } from '../../DeleteOrUpdate/DeleteOrUpdate';
import { isFunction } from 'formik';
import { isNilOrEmpty } from '../../../utils/ramda_utils';

const DragHandle = sortableHandle(() => (
  <Box sx={{ cursor: 'row-resize' }}>
    <DragHandleIcon />
  </Box>
));

const SortableItem = sortableElement(({ render }) => {
  return render;
});

export interface ColumnsAndData<T extends object> {
  columns: ReadonlyArray<Column<T>>;
  data: readonly T[];
}

export interface ReactTableProps extends TableProps {
  sortable?: boolean;
  onSortEnd?: (sort: SortEnd, event: SortEvent) => void;
}

interface Props<T extends object> extends ReactTableProps, ColumnsAndData<T>, IDeleteOrUpdate<T> {
  hideDeleteUpdate?: boolean;
  afterTableContent?: any;
  renderRowSubComponent?: any;
  tableHeadStyle?: any;
  handleDeleteItem?: (r: T, index: number) => void;
  deleteReturnRow?: boolean;
  handleEditItem?: (row: T, index: number) => void;
}

export function ReactTable<T extends object>({
  columns,
  handleEditItem,
  tableHeadStyle,
  handleDeleteItem,
  renderRowSubComponent,
  afterTableContent,
  link,
  hideDeleteUpdate,
  hideDelete,
  hideUpdate,
  data,
  sortable,
  onSortEnd,
}: Props<T>) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, visibleColumns, getTableBodyProps, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useExpanded,
    );

  // Render the UI for your table
  return (
    <>
      <MaUTable
        {...getTableProps()}
        size={'small'}
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        <TableHead style={tableHeadStyle}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {sortable && <TableCell style={{ width: 30, maxWidth: 30 }} />}
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  <Typography variant="h5" fontWeight="400">
                    {column.render('Header')}
                  </Typography>
                </TableCell>
              ))}
              {!hideDeleteUpdate && <TableCell />}
            </TableRow>
          ))}
        </TableHead>

        <ReactTableTableBody onSortEnd={onSortEnd} sortable={sortable} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const r = (
              <TableRow {...row.getRowProps()}>
                {sortable && (
                  <TableCell>
                    <DragHandle />
                  </TableCell>
                )}
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      <Typography color="textSecondary" variant="h6" fontWeight="400">
                        {cell.render('Cell')}
                      </Typography>
                    </TableCell>
                  );
                })}
                {!hideDeleteUpdate && (
                  <TableCell style={{ width: '8%' }}>
                    <DeleteOrUpdate
                      link={link}
                      onClickUpdate={() => {
                        handleEditItem && handleEditItem(row.original, i);
                      }}
                      deleteFunc={() => {
                        handleDeleteItem && handleDeleteItem(row.original, i);
                      }}
                      isUpdateFunctionExists={!isNilOrEmpty(handleEditItem)}
                      row={row.original as T}
                      hideDelete={hideDelete}
                      hideUpdate={hideUpdate}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
            return (
              <>
                {sortable ? <SortableItem key={`item-${i}`} index={i} render={r} /> : r}
                {/* @ts-ignore */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length + 1}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </ReactTableTableBody>
      </MaUTable>
      {afterTableContent}
    </>
  );
}
