import { always, equals, ifElse, prop } from 'ramda';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { SelectField } from '../../components/Forms/Formik/Fields/SelectField';
import { getImagePreview } from '../Input/DefaultValueFieldByType';
import { CustomForm } from '../../components/Forms/Formik/Form';
import React from 'react';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { Input, InputType } from '../../types/input.types';
import { Card, CardContent, Grid } from '@mui/material';
import { cFieldNames, initialValues, InputDependency, validationSchema } from './schema';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { StepDependency } from '../../types/stepDependency.types';
import { AutocompleteSync } from '../../components/Forms/Formik/Fields/AutocompleteSync';
import { SelectMenuItem } from '../../components/Forms/Formik/TextFormikInput';
import { Form } from '../../components/Forms/Formik/forms.types';

interface Props extends Form<InputDependency> {
  isLoading: boolean;
  stepDependency?: StepDependency;
  inputs: Input[];
}

export const StepDependencyForm = ({ onSubmit, inputs, isLoading }: Props) => {
  const findInputById = (inputId, inputs) => {
    const index = inputs.findIndex((i) => equals(i.id, inputId));
    return isNilOrEmpty(index) ? null : inputs[index];
  };

  const inputsToOptions = (i: Input[]): SelectMenuItem[] =>
    ifElse(isNilOrEmpty, always([]), (inputs: Input[]) =>
      inputs
        .filter((i: Input) => i.type === InputType.CHOICE || i.type === InputType.SELECT)
        .map((i) => ({
          id: i.id,
          name: i.name,
        })),
    )(i);

  const choicesToOptions = ifElse(isNilOrEmpty, always([]), (values) =>
    values[cFieldNames.choices].map((ch) => ({
      id: ch.id,
      name: getImagePreview(ch),
    })),
  );

  const onInputChange = (value, setFieldValue) => {
    if (!isNilOrEmpty(prop('id', value))) {
      const id = value.id;
      setFieldValue(cFieldNames.inputId, id, false);
      const input = findInputById(id, inputs);
      setFieldValue(cFieldNames.choices, input.choices, false);
    } else {
      setFieldValue(cFieldNames.choices, [], false);
      setFieldValue(cFieldNames.inputId, null, false);
    }
  };

  return (
    <LoadingErrorContainer isLoading={isLoading}>
      {inputs && (
        <CustomForm
          data={initialValues}
          initValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formName={'STEP_FORM'}
        >
          {({ values, setFieldValue }) => (
            <Card>
              <CardContent>
                <Grid container direction={'column'} spacing={1}>
                  <AutocompleteSync
                    name={cFieldNames.inputId}
                    onChange={(e, data) => onInputChange(data, setFieldValue)}
                    options={inputsToOptions(inputs)}
                  />
                  <SelectField options={choicesToOptions(values)} name={cFieldNames.choiceId} />
                  <SubmitButton loading={isLoading} />
                </Grid>
              </CardContent>
            </Card>
          )}
        </CustomForm>
      )}
    </LoadingErrorContainer>
  );
};
