import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';

interface Props {
  title: any;
  children?: any;
  style?: object;
  cardHeaderStyle?: any;
  cardContentStyle?: any;
}
const BaseCard = ({ title, children, style, cardHeaderStyle, cardContentStyle }: Props) => (
  <Card
    sx={{
      width: '100%',
      p: 0,
      m: 0,
    }}
    style={style}
    variant={'outlined'}
  >
    <CardHeader title={title} style={cardHeaderStyle} />

    <Divider />
    <CardContent style={cardContentStyle}>{children}</CardContent>
  </Card>
);

BaseCard.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default BaseCard;
