import {
  PAGE_SIZE_QUERY,
  PaginationSearchQueryParams,
  UseQueryWithParams,
} from '../generic/useGenericQueryParams';
import { IPagination } from '../../useApiPagination';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { PRODUCT_BANK_API } from '../../../constant/api_url';
import { ProductBank } from '../../../types/productBanks.types';
import { NumberParam, withDefault } from 'use-query-params';

export const useBanksFetch = (
  id: any,
): UseQueryWithParams<IPagination<ProductBank>, PaginationSearchQueryParams> => {
  return usePaginationQueryParams<ProductBank>(`${PRODUCT_BANK_API}/${id}/products`, {
    [PAGE_SIZE_QUERY]: withDefault(NumberParam, 10),
  });
};
