import React, { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { OutlinedTextFieldProps, TextField } from '@mui/material';
import { equals } from 'ramda';
import { InputTypesProps } from '../Formik/TextFormikInput';

interface Props extends OutlinedTextFieldProps {
  variant: 'outlined';
  type?: InputTypesProps;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export const CustomTextField = styled(({ variant, type, onChange, ...other }: Props) => (
  <TextField
    variant={variant ?? 'outlined'}
    onChange={onChange}
    select={equals('select', type)}
    type={type}
    {...other}
  />
))(({ theme }) => ({
  '& .MuiOutlinedInput-input::-webkit-input-placeholder': {
    color: '#767e89',
    opacity: '1',
  },
  '& .input:invalid + fieldset': {
    borderColor: `rgb(252, 75, 108)!important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#dee3e9'}`,
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    backgroundColor: `${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.12) ' : '#f8f9fb '}`,
  },
  '& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder': {
    color: '#767e89',
    opacity: '1',
  },
}));

export default CustomTextField;
