import { Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

interface Props {
  icon: string;
  text: string;
}
export const FormBoxDivider = ({ icon = 'alert-circle', text }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      p={2}
      sx={{
        backgroundColor: 'primary.light',
        color: 'primary.main',
      }}
    >
      <FeatherIcon icon={icon} width="18" />
      <Box sx={{ ml: 1 }}>{text}</Box>
    </Box>
  );
};
