import { PaginationSearchQueryParams, UseQueryWithParams } from '../generic/useGenericQueryParams';
import { IPagination } from '../../useApiPagination';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { PRODUCT_BANK_API } from '../../../constant/api_url';
import { ProductBank } from '../../../types/productBanks.types';

export const useProductBanksFetch = (): UseQueryWithParams<
  IPagination<ProductBank>,
  PaginationSearchQueryParams
> => {
  return usePaginationQueryParams<ProductBank>(PRODUCT_BANK_API);
};
