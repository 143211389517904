import { useEffect, useState } from 'react';
import { Input } from '../../types/input.types';
import useHistoryReducer from 'react-use-history-reducer';
import { equals } from 'ramda';
import { DragAndDropHelpers } from '../../components/StepBuilder/DragAndDropHelpers';
import { reducer } from './reducer';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { ACTIONS } from './actions';
import { useParams } from 'react-router-dom';
import { DroppableZones } from './DroppableZones';
import { ProductBankDataContext } from './ProductBankDataContext';
import { ProductBankInput } from '../../types/productBankInput.types';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { useFilter } from '../../utils/search.utils';
import { useAvailableInputsForProductBankById } from '../../hooks/query/product-banks/useAvailableInputsForProductBankById';
import { useConnectedInputsToProductBank } from '../../hooks/query/product-banks/useConnectedInputsToProductBank';
import {
  useConnectProductBankAndInputMutation,
  useDeleteProductBankAndInputMutation,
  useReorderProductBankInputsMutation,
} from '../../hooks/query/connectStepAndInput/useConnectProductBankAndInputMutation';

export interface ReducerState<V, T> {
  inputs: V[];
  data: T[];
}

export const ProductBankInputDataProvider = ({ children }) => {
  const { id } = useParams();
  const [leftSearchInputsFieldValue, setLeftSearchLeftInputsFieldValue] = useState('');
  const [rightSearchInputsFieldValue, setRightSearchLeftInputsFieldValue] = useState('');
  // @ts-ignore
  const [state, dispatch, history] = useHistoryReducer<ReducerState<Input, ProductBankInput>>(
    reducer,
    {
      inputs: [],
      data: [],
    },
  );

  const connectStepAndInputMutation = useConnectProductBankAndInputMutation();
  const deleteStepAndInputMutation = useDeleteProductBankAndInputMutation();
  const reorderInputsMutation = useReorderProductBankInputsMutation();

  const {
    data: inputs,
    isLoading: isInputLoading,
    isError: isInputError,
  } = useAvailableInputsForProductBankById(id);
  const { data, isLoading, isError, refetch } = useConnectedInputsToProductBank(id);

  useEffect(() => {
    dispatch({ type: ACTIONS.LOAD_DATA, payload: data });
  }, [data]);

  useEffect(() => {
    dispatch({ type: ACTIONS.LOAD_INPUTS, payload: inputs });
  }, [inputs]);

  const connectToStep = async (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => {
    if (!droppableDestination || !droppableSource) return;
    // Find input from source to get id to connect via api
    let input = state.inputs[droppableSource.index];
    // If filter si active drop source cames from filtered stat array!! need to fix it !
    if (!isNilOrEmpty(rightSearchInputsFieldValue)) {
      // apply filter same in UI
      const filteredInputs = useFilter<Input>(rightSearchInputsFieldValue, ['name'], state.inputs);
      // Find input in filtered array
      const inputNew = filteredInputs[droppableSource.index];
      // by input id in filtered array find index in state array
      const indexInRootArray = state.inputs.findIndex((i) => inputNew.id === i.id);
      // Replace doppable source index to index in state array
      // Do move from right to left droppable
      dispatch({
        type: ACTIONS.MOVE,
        droppableSource: { ...droppableSource, index: indexInRootArray },
        droppableDestination,
      });
      input = inputNew;
    } else {
      // Do move from right to left droppable
      dispatch({ type: ACTIONS.MOVE, droppableSource, droppableDestination });
    }

    // do api call to connect step and input
    connectStepAndInputMutation
      .mutateAsync({
        productBankId: id,
        inputId: input.id,
        params: {
          sort: droppableDestination.index,
        },
      })
      .then(({ data }) => {
        refetch();
        dispatch({ type: ACTIONS.REPLACE_PRODUCT_BANK_INPUT_BY_INPUT, payload: data });
      })
      .catch(() => {
        history.undo();
      });
  };

  const reorder = async (oldIndex: number, newIndex: number) => {
    // do reorder in state
    const newArraysData = DragAndDropHelpers.reorder(state.data, oldIndex, newIndex);
    // do api fetch reordering
    await dispatch({ type: ACTIONS.SET_DATA, payload: newArraysData });
    const data = newArraysData.map((productBank: ProductBankInput, index) => ({
      id: productBank.input.id,
      sort: index,
    }));
    reorderInputsMutation.mutateAsync({ id, data }).catch(() => {
      history.undo();
    });
  };

  const deleteItem = async (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => {
    if (!droppableDestination || !droppableSource) return;
    // Find input from source to get id to connect via api
    const productBankInput: ProductBankInput = state.data[droppableSource.index];
    const inputId = productBankInput.input.id;
    // Do move from right to left droppable
    dispatch({ type: ACTIONS.DELETE, droppableSource, droppableDestination });
    deleteStepAndInputMutation.mutateAsync({ productBankId: id, inputId }).catch(() => {
      history.undo();
    });
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }
    switch (source.droppableId) {
      case destination.droppableId:
        if (equals(source.index, destination.index)) {
          return;
        }
        reorder(source.index, destination.index);
        break;
      case DroppableZones.PRODUCT_BANK_FREE_INPUTS:
        connectToStep(source, destination);
        break;
      case DroppableZones.PRODUCT_BANK_CONNECTED_INPUTS:
        deleteItem(source, destination);
        break;
      default:
        break;
    }
  };

  const updateProductBankInputValue = (value: string, inputId: number) => {
    dispatch({ type: ACTIONS.UPDATE_PRODUCT_BANK_INPUT_VALUE, payload: { value, inputId } });
  };

  const updateProductBankInputMin = (value: string, inputId: number) => {
    dispatch({ type: ACTIONS.UPDATE_PRODUCT_BANK_INPUT_MIN, payload: { value, inputId } });
  };
  const updateProductBankInputCreateValue = (value: string, inputId: number) => {
    dispatch({ type: ACTIONS.UPDATE_PRODUCT_BANK_INPUT_CREATE_VALUE, payload: { value, inputId } });
  };

  return (
    <ProductBankDataContext.Provider
      value={{
        isLoading: isLoading || isInputLoading,
        isError: isError || isInputError,
        state: {
          data: useFilter<ProductBankInput>(
            leftSearchInputsFieldValue,
            ['input', 'name'],
            state.data,
          ),
          inputs: useFilter<Input>(rightSearchInputsFieldValue, ['name'], state.inputs),
        },
        searchLeftInputsFieldValue: leftSearchInputsFieldValue,
        searchRightInputsFieldValue: rightSearchInputsFieldValue,
        leftInputsSearchOnChange: setLeftSearchLeftInputsFieldValue,
        rightInputsSearchOnChange: setRightSearchLeftInputsFieldValue,
        reorder: reorder,
        connectToStep: connectToStep,
        deleteItem: deleteItem,
        onDragEnd: onDragEnd,
        updateProductBankInputValue,
        updateProductBankInputMin,
        updateProductBankInputCreateValue,
      }}
    >
      {children}
    </ProductBankDataContext.Provider>
  );
};
