import React from 'react';
import { TableProps } from './table.props';
import { Bank } from '../types/bank.types';
import { PATHS } from '../routes/paths';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';
import { Column } from 'react-table';
import TableAvatar from '../components/TableAvatar';

export const BanksTable = ({ pagination, onDelete, data }: TableProps<Bank>) => {
  const columns: Column<Bank>[] = React.useMemo(
    () => [
      {
        Header: 'Ikona',
        Cell: (props) => <TableAvatar url={props.cell.row.original.image?.url} />,
        width: '3%',
      },
      {
        Header: 'Název',
        accessor: 'name',
      },
    ],
    [],
  );
  return (
    <>
      {data && (
        <ReactTable
          columns={columns}
          handleDeleteItem={onDelete}
          data={data}
          link={`${PATHS.BANKS_PATH}${PATHS.EDIT}`}
          afterTableContent={pagination}
        />
      )}
    </>
  );
};
