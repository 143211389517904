import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { PRODUCT_BANK_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { ProductBank } from '../../../types/productBanks.types';

interface UseConnectStepAndInputMutationVariables {
  productBankId: any;
  inputId: any;
  params: Record<any, any>;
}

export const useConnectProductBankAndInputMutation = () => {
  return useMutation<
    AxiosResponse<ProductBank>,
    AxiosError,
    UseConnectStepAndInputMutationVariables
  >(
    ({ productBankId, inputId, params }: UseConnectStepAndInputMutationVariables) =>
      axios.post<ProductBank>(
        `${PRODUCT_BANK_API}/${productBankId}/input/${inputId}`,
        {},
        {
          params,
        },
      ),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit spojení');
      },
      onSuccess: () => {
        toast.success('Spojení vytvořeno');
      },
    },
  );
};

interface UseDeleteStepAndInputMutationVariables {
  productBankId: any;
  inputId: any;
}

export const useDeleteProductBankAndInputMutation = () => {
  return useMutation<
    AxiosResponse<ProductBank>,
    AxiosError,
    UseDeleteStepAndInputMutationVariables
  >(
    ({ productBankId, inputId }: UseDeleteStepAndInputMutationVariables) =>
      axios.delete<ProductBank>(`${PRODUCT_BANK_API}/${productBankId}/input/${inputId}`),
    {
      onError: () => {
        toast.error('Nepodařilo se odstranit spojení mezi krokem výběrem');
      },
      onSuccess: () => {
        toast.success('Spojení odstraněno');
      },
    },
  );
};

interface SortItem {
  id: any;
  sort: number;
}

interface UseReorderStepInputsMutationVariables {
  id: any;
  data: SortItem[];
}

export const useReorderProductBankInputsMutation = () => {
  return useMutation<AxiosResponse<ProductBank>, AxiosError, UseReorderStepInputsMutationVariables>(
    ({ id, data }: UseReorderStepInputsMutationVariables) =>
      axios.post<ProductBank>(`${PRODUCT_BANK_API}/${id}/input-order`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se změnit pořadí');
      },
      onSuccess: () => {
        toast.success('Pořádí změněno');
      },
    },
  );
};

interface UpdateInfo {
  value: string;
  min: string;
  createValue: string;
}

interface UseUpdateValueInConnectedProductVariables {
  id: string;
  inputId: any;
  data: UpdateInfo;
}

export const useUpdateValueInConnectedProductBankInput = () => {
  return useMutation<
    AxiosResponse<ProductBank>,
    AxiosError,
    UseUpdateValueInConnectedProductVariables
  >(
    ({ id, inputId, data }: UseUpdateValueInConnectedProductVariables) =>
      axios.put<ProductBank>(`${PRODUCT_BANK_API}/${id}/input/${inputId}/value`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat údaje');
      },
      onSuccess: () => {
        toast.success('Data aktualizována');
      },
    },
  );
};
