import React from 'react';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { cFieldNames, ChangePasswordFormFields, initialValues, validationSchema } from './schema';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { Grid } from '@mui/material';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { FormikStatus } from '../../components/Forms/Formik/FormikStatus';
import { Form } from '../../components/Forms/Formik/forms.types';

interface Props extends Form<ChangePasswordFormFields> {
  loading: boolean;
}

export const ChangePasswordForm: React.FC<Props> = ({ loading, onSubmit }) => {
  return (
    <CustomForm
      data={initialValues}
      initValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      formName={'CHANGE_PASSWORD_FORM'}
    >
      {() => (
        <Grid item container spacing={1} direction={'column'}>
          <FormikStatus />
          <TextField name={cFieldNames.password} type={'password'} size={'small'} />
          <TextField name={cFieldNames.passwordAgain} type={'password'} size={'small'} />
          <SubmitButton loading={loading} fullWidth size={'large'} />
        </Grid>
      )}
    </CustomForm>
  );
};
