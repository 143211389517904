import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { INPUT_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export interface DeleteInputMutationVariables {
  id: number;
}

export const useInputsDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, DeleteInputMutationVariables>(
    (data: DeleteInputMutationVariables) => axios.delete(`${INPUT_API}/${data.id}`),
    {
      onError: (err, _, context) => {
        toast.error('Nepodařilo se vymazat vstup');
      },
      onSuccess: (data) => {
        toast.success('Vstup vymazán');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === INPUT_API,
        });
      },
    },
  );
};
