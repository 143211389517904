import { STEP_API } from '../../constant/api_url';
import { useParams } from 'react-router';
import { Step } from '../../types/step.types';
import { StepForm } from '../../forms/Step';
import { ifElse, isNil } from 'ramda';
import { PATHS } from '../../routes/paths';
import PageContainer from '../../components/PageContainer/PageContainer';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { Box, Card, CardContent, Grid, Tab, Tabs } from '@mui/material';
import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { StepBuilderLayout } from '../../components/StepBuilder/StepBuilderLayout';
import { StepInputDataProvider } from '../../context/StepInputDataContext/StepInputDataProvider';
import React from 'react';
import { StepDependency } from '../../components/StepDependency';
import { useTranslation } from 'react-i18next';
import { useCreateStep, useEditStep } from '../../hooks/query/steps/useCreateStep';
import { useGetById } from '../../hooks/query/reactQuery';

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </div>
  );
}

export const NewEditStep = () => {
  const { id } = useParams();
  const { data, isLoading, isError, refetch } = useGetById<Step>(STEP_API, id);
  const { t } = useTranslation('common');
  const pageName = useNewEditPageTranslate('STEPS');
  const createMutation = useCreateStep();
  const editMutation = useEditStep();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onSubmit = (values) =>
    ifElse(
      isNil,
      async () => {
        createMutation.mutateAsync(values);
      },
      async () => {
        editMutation.mutateAsync(values);
      },
    )(id);

  return (
    <PageContainer>
      <Breadcrumb
        title={pageName}
        items={[{ title: 'STEPS', to: `${PATHS.STEPS_PATH}` }, { title: pageName }]}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              p: 0,
            }}
          >
            <CardContent>
              <Box p={2}>
                <LoadingErrorContainer isLoading={isLoading} isError={isError}>
                  <StepForm
                    data={data}
                    onSubmit={onSubmit}
                    loading={createMutation.isLoading || editMutation.isLoading}
                  />
                </LoadingErrorContainer>
              </Box>
            </CardContent>

            {!isNilOrEmpty(id) && (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t('inputs')} {...a11yProps(0)} />
                    <Tab label={t('dependencies')} {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <CardContent>
                    <Box p={1}>
                      <StepInputDataProvider>
                        <StepBuilderLayout />
                      </StepInputDataProvider>
                    </Box>
                  </CardContent>
                </TabPanel>
                {data && (
                  <TabPanel value={value} index={1}>
                    <CardContent>
                      <Box p={1}>
                        <StepDependency refresh={refetch} step={data} />
                      </Box>
                    </CardContent>
                  </TabPanel>
                )}
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
