import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { STEP_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

interface UseInputConnectWithStepVariables {
  id: any;
  draggableId: any;
  sort: any;
}

export const useInputConnectWithStep = () => {
  return useMutation<
    AxiosResponse<UseInputConnectWithStepVariables>,
    AxiosError,
    UseInputConnectWithStepVariables
  >(
    ({ id, draggableId, sort }) =>
      axios.post(`${STEP_API}/${id}/input/${draggableId}`, {}, { params: { sort } }),
    {
      onSuccess: () => {
        toast.success('Krok a vstup byl spojen');
      },
      onError: () => {
        toast.error('Krok a vstup byl se nepodařilo spojit');
      },
    },
  );
};
