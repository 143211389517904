import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { isNotEmpty } from 'ramda-extension';
import React from 'react';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';
import {
  defaultTranslationFormContext,
  TranslateFormContext,
  useFormTranslate,
} from '../../../hooks/useFormTranslate';
import { isNilOrEmpty } from '../../../utils/ramda_utils';

export interface ICustomForm<T> {
  data: T | Array<T>;
  initValues: T;
  validationSchema: any;
  onSubmit: (a: T, helpers: FormikHelpers<T>) => void;
  formName: string;
  children: (val: FormChildrenCallback) => any;
}
export interface FormChildrenCallback extends FormikProps<any>, TranslateFormContext {}
export function CustomForm<T>({
  data,
  initValues,
  validationSchema,
  onSubmit,
  formName,
  children,
}: ICustomForm<T>) {
  const translateContext = useFormTranslate(formName);
  const init = !isNilOrEmpty(data) ? data : initValues;
  return (
    <FormTranslationContext.Provider
      value={formName ? translateContext : defaultTranslationFormContext}
    >
      <Formik
        // @ts-ignore
        initialValues={init}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnMount={false}
      >
        {({ handleSubmit, ...other }: FormikProps<any>) => (
          <Form
            className={isNotEmpty(other.errors) ? 'needs-validation' : ''}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {children({
              ...translateContext,
              ...other,
              handleSubmit,
            } as FormChildrenCallback)}
          </Form>
        )}
      </Formik>
    </FormTranslationContext.Provider>
  );
}
