import { SelectFormikFieldProps, TextFormikInput } from '../TextFormikInput';
import { Field } from 'formik';
import { Grid } from '@mui/material';

export const SelectField = (props: SelectFormikFieldProps) => {
  return (
    <Grid item xs={12}>
      <Field {...props} type={'select'} component={TextFormikInput} />
    </Grid>
  );
};
