import { Grid } from '@mui/material';
import React from 'react';
import { FormikStatus } from '../../components/Forms/Formik/FormikStatus';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { cFieldNames, initialValues, ResetPasswordEmailForm, validationSchema } from './schema';
import { Form } from '../../components/Forms/Formik/forms.types';

export interface Props extends Form<ResetPasswordEmailForm> {
  loading: boolean | undefined;
}

export const ChangePasswordEmailForm: React.FC<Props> = ({ loading, onSubmit }) => {
  return (
    <CustomForm
      data={initialValues}
      initValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      formName={'RESET_PASSWORD_FORM'}
    >
      {() => (
        <Grid
          item
          container
          spacing={1}
          direction={'column'}
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <FormikStatus />
          <TextField name={cFieldNames.email} size={'medium'} />
          <SubmitButton size={'large'} fullWidth loading={loading} title={'Reset Password'} />
        </Grid>
      )}
    </CustomForm>
  );
};
