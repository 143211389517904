import { createSlice } from "@reduxjs/toolkit";
import { SLICE } from "./config/names.slices";
import { pathOr } from "ramda";

let modals = {};
Object.keys(modals).forEach((v) => {
  modals[v] = {
    open: false,
    payload: {},
  };
});

export const modalsSlice = createSlice({
  name: SLICE.MODALS_SLICE,
  initialState: modals,
  reducers: {
    openModal: (state, action) => {
      state[action.payload] = {
        ...state[action.payload],
        open: true,
      };
    },
    closeModal: (state, action) => {
      state[action.payload] = {
        open: false,
        payload: {},
      };
    },
    setModalContent: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        payload: action.payload.content,
      };
    },
  },
});

export const ModalReducer = modalsSlice.reducer;

export const { openModal, closeModal, setModalContent } = modalsSlice.actions;

export const isModalOpen = (id) => (state) => {
  return pathOr(false, [SLICE.MODALS_SLICE, id, "open"], state);
};
export const modalPayload = (id) => (state) => {
  return pathOr({}, [SLICE.MODALS_SLICE, id, "payload"], state);
};
