import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { PRODUCT_BANK_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export interface ProductBankInputMutationVariables {
  id: number;
}

export const useProductBankDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductBankInputMutationVariables>(
    (data: ProductBankInputMutationVariables) => axios.delete(`${PRODUCT_BANK_API}/${data.id}`),
    {
      onError: (err, _) => {
        toast.error('Nepodařilo se vymazat produktový vstup');
      },
      onSuccess: () => {
        toast.success('Produktový vstup vymazán');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === PRODUCT_BANK_API,
        });
      },
    },
  );
};
