import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { REQUEST_PASSWORD_CHANGE } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface UserResetEmailVariables {
  email: string;
}

export const useResetResetEmail = () => {
  const { t } = useTranslation();
  return useMutation<AxiosResponse<UserResetEmailVariables>, AxiosError, UserResetEmailVariables>(
    (data) => axios.post(REQUEST_PASSWORD_CHANGE, data),
    {
      onError: () => {
        toast.error(t('common:cannotSendNewPasswordRequest'));
      },
      onSuccess: () => {
        toast.success(t('common:newPasswordEmailSent'));
      },
    },
  );
};
