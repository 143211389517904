import * as yup from 'yup';
import { Input } from '../../types/input.types';

export const cFieldNames = {
  inputId: 'inputId',
  choiceId: 'choiceId',
  choices: 'choices',
};

export const validationSchema = yup.object().shape({});

export interface InputDependency {
  inputId: number;
  choices: Array<any>;
  choiceId: string;
}
export const initialValues: InputDependency = {
  inputId: 0,
  choices: [],
  choiceId: '',
};
