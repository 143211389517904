import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { NavButton } from '../../components/NavButton/NavButton';
import React from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Box, Card, CardActions, CardContent, Grid, LinearProgress } from '@mui/material';
import { File } from '../../types/files.types';
import { useGalleryAPI } from '../../hooks/useGalleryAPI';
import { useTranslation } from 'react-i18next';
import NewGalleryItem from '../../components/NewGalleryItem';
import { ImageItem } from '../../components/NewGalleryItem/ImageItem';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { useGalleryFetch } from '../../hooks/query/gallery/useGalleryFetch';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { useDeleteGalleryMutation } from '../../hooks/query/gallery/useGalleryDeleteMutation';
import { SearchOutlinedField } from '../../components/Forms/CustomElements/SearchOutlinedField';
import { propOr } from 'ramda';
import { PAGE_SEARCH_QUERY } from '../../hooks/query/generic/useGenericQueryParams';

export const Gallery = () => {
  const { modalOpen, toggleModal } = useGalleryAPI(`/file`);
  const { t } = useTranslation('pages');
  const {
    apiCall: { data, isLoading, isError, isFetching, refetch },
    params,
    setQueryParam,
  } = useGalleryFetch();
  const deleteMutation = useDeleteGalleryMutation();
  const deleteItem = (id) => deleteMutation.mutateAsync({ id });

  return (
    <>
      <NewGalleryItem
        open={modalOpen}
        close={toggleModal}
        removeItem={deleteItem}
        addItem={() => refetch()}
      />
      <PageContainer title="Galerie">
        <Breadcrumb title={t('GALLERY')} items={[{ title: 'GALLERY' }]}>
          <NavButton title={t('ADD_BUTTON')} onClick={toggleModal} icon={'plus'} />
        </Breadcrumb>
        <Card>
          <CardContent>
            {isLoading || isFetching || (deleteMutation.isLoading && <LinearProgress />)}
            <SearchOutlinedField
              value={propOr('', PAGE_SEARCH_QUERY, params)}
              onChange={(value) => setQueryParam(PAGE_SEARCH_QUERY, value)}
            />
            <Box mt={2} />
            <LoadingErrorContainer isLoading={isLoading} isError={isError}>
              <Grid container spacing={1}>
                {data &&
                  data.elements.map((i: File) => (
                    <Grid item>
                      <ImageItem smallPreview image={i} onDelete={deleteItem} />
                    </Grid>
                  ))}
              </Grid>
            </LoadingErrorContainer>
          </CardContent>
          <CardActions>
            <Grid container justifyContent={'end'}>
              <Grid item>
                <StyledPagination data={data} setQueryParam={setQueryParam} />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </PageContainer>
    </>
  );
};
