import React, { useEffect } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ChangePasswordForm } from '../../forms/ChangePassword';
import { useNavigate, useParams } from 'react-router-dom';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { useTranslation } from 'react-i18next';
import { cFieldNames } from '../../forms/ChangePassword/schema';
import { toast } from 'react-hot-toast';
import { useResetPassword } from '../../hooks/query/password/useResetPassword';

export const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const changePasswordMutation = useResetPassword();
  useEffect(() => {
    if (isNilOrEmpty(token)) {
      navigate('/');
      toast.error(t('errors:INVALIDATE_TOKEN'));
    }
  }, []);

  const submit = async (values, helpers) => {
    helpers.setStatus();
    changePasswordMutation
      .mutateAsync({
        token,
        password: values[cFieldNames.password],
      })
      .catch(() => {
        helpers.setStatus('Cannot login!');
      });
  };

  return (
    <PageContainer title="Změnit heslo">
      <Box sx={{ height: '90vh' }} display={'flex'} alignItems={'center'}>
        <Container maxWidth={'xs'}>
          <LoadingErrorContainer isLoading={changePasswordMutation.isLoading}>
            <Box display={'flex'} alignItems={'center'} height={400}>
              <Grid container spacing={2} direction={'column'}>
                <Grid item textAlign={'center'}>
                  <Typography variant="h2" fontWeight="700">
                    {t('common:changePassword')}
                  </Typography>
                </Grid>
                <Grid item>
                  <ChangePasswordForm
                    onSubmit={submit}
                    loading={changePasswordMutation.isLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          </LoadingErrorContainer>
        </Container>
      </Box>
    </PageContainer>
  );
};
