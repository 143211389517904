import * as yup from 'yup';
import { NewUser, ProfileUpdate } from '../../types/users.types';
import { TENANT_SROVNEJSITO } from '../../constant/config';

export const cFieldNames = {
  email: 'email',
  profile: 'profile.fullname',
  tenants: 'tenants',
};

export const validationSchema = yup.object().shape({});

export const initialValues: ProfileUpdate = {
  email: '',
  profile: {
    fullname: '',
  },
  tenants: [TENANT_SROVNEJSITO],
};
