import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { toast } from 'react-hot-toast';

export interface DeleteGalleryMutationVariables {
  id: number;
}

export const useDeleteGalleryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, DeleteGalleryMutationVariables>(
    (data: DeleteGalleryMutationVariables) => axios.delete(`/file/${data.id}`),
    {
      onError: () => {
        toast.error('Nepodařilo se vymazat obrázek');
      },
      onSuccess: () => {
        toast.success('Obrázek vymazán');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === '/file',
        });
      },
    },
  );
};
