import { Alert, AlertColor, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { isNilOrEmpty } from '../../../utils/ramda_utils';

export interface FormikStatus {
  type: AlertColor;
  content: any;
}

export const FormikStatus = () => {
  const { status: formStatus } = useFormikContext();
  return isNilOrEmpty(formStatus) ? (
    <></>
  ) : (
    <Box mb={2}>
      <Alert severity={formStatus.type}>{formStatus.content}</Alert>
    </Box>
  );
};
