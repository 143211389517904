import React from 'react';
import { TableProps } from './table.props';
import { ProductBank } from '../types/productBanks.types';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';
import { Column } from 'react-table';
import TableAvatar from '../components/TableAvatar';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Input } from '../types/input.types';
import { PATHS } from '../routes/paths';

export const ProductBanksTable = ({ onDelete, data, pagination }: TableProps<ProductBank>) => {
  const columns: Column<ProductBank>[] = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.

          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </span>
        ),
      },

      {
        Header: 'Název účtu',
        accessor: 'accountName',
        width: '40%',
      },
      {
        Header: 'Externí název',
        accessor: 'externalName',
        width: '30%',
      },
      {
        Header: 'Měsíčně',
        accessor: 'computeValue',
        width: '5%',
      },
      {
        Header: 'Založení',
        accessor: 'createValue',
        width: '5%',
      },
      {
        Header: 'Banka',
        Cell: (props) => <TableAvatar url={props.cell.row.original.bank?.image?.url} />,
        width: '5%',
      },
    ],
    [],
  );

  const columnsCollapsible: Column<Input>[] = React.useMemo(
    () => [
      {
        Header: 'Název',
        accessor: 'name',
        width: '90%',
      },
      {
        Header: 'Typ',
        accessor: 'type',
        width: '5%',
      },
      {
        Header: 'Povinný',
        accessor: 'required',
        Cell: (props) => (
          <div className={'d-flex justify-content-start align-items-center'}>
            <input
              className="m-0 checkbox checkbox-primary form-check-input "
              type={'checkbox'}
              checked={props.cell.row.original.required}
              disabled={true}
            />
          </div>
        ),
        width: '5%',
      },
    ],
    [],
  );
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <ReactTable
        hideDeleteUpdate
        tableHeadStyle={{ background: '#f3f3f3' }}
        columns={columnsCollapsible}
        data={row.original.inputs}
        renderRowSubComponent={renderRowSubComponent}
      />
    );
  }, []);
  return (
    <>
      {data && (
        <ReactTable
          link={`${PATHS.PRODUCT_BANK}${PATHS.EDIT}`}
          columns={columns}
          handleDeleteItem={onDelete}
          data={data}
          renderRowSubComponent={renderRowSubComponent}
          afterTableContent={pagination}
        />
      )}
    </>
  );
};
