import React from 'react';
import { TableProps } from './table.props';
import { UserProfile } from '../types/users.types';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';
import { PATHS } from '../routes/paths';
import { Column } from 'react-table';

export const UsersTable: React.FC<TableProps<UserProfile>> = ({ data, onDelete, pagination }) => {
  const columns: Column<UserProfile>[] = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    [],
  );

  return (
    <>
      {data && (
        <ReactTable
          columns={columns}
          handleDeleteItem={onDelete}
          data={data}
          hideUpdate
          afterTableContent={pagination}
        />
      )}
    </>
  );
};
