import { isEmpty } from 'ramda';
import React, { useContext } from 'react';
import { isFunction } from 'formik';
import { FormControlLabel } from '@mui/material';
import CustomFormLabel from '../CustomElements/CustomFormLabel';
import CustomCheckbox from '../CustomElements/CustomCheckbox';
import { FormikFieldTypes } from './types';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';

export interface CheckboxInputProps {
  noLabel?: boolean;
  label?: string;
  onChange?: (e) => void;
  disabled?: boolean;
  name: string;
  disableFormikSetFieldValue?: boolean;
  checked?: boolean;
  formGroup?: boolean;
}

interface Props extends CheckboxInputProps, FormikFieldTypes {}
export const CheckBoxFormik = ({
  onChange,
  label,
  disabled,
  field,
  form,
  disableFormikSetFieldValue,
  formGroup,
}: Props) => {
  const { label: labelTranslate } = useContext(FormTranslationContext);

  const checkbox = (
    <CustomCheckbox
      disabled={disabled}
      checked={field.value}
      value={field.value}
      onChange={(e) => {
        !disableFormikSetFieldValue && form.setFieldValue(field.name, e.target.checked);
        isFunction(onChange) && onChange(e);
      }}
    />
  );
  return formGroup && !isEmpty(labelTranslate(field.name)) ? (
    <FormControlLabel
      style={{
        marginBottom: '5px',
      }}
      control={checkbox}
      label={labelTranslate(field.name)}
      sx={{
        mb: 1,
      }}
    />
  ) : (
    <>
      {!isEmpty(labelTranslate(field.name)) && (
        <CustomFormLabel htmlFor={labelTranslate(field.name)}>{label}</CustomFormLabel>
      )}
      {checkbox}
    </>
  );
};
