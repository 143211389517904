import { TableProps } from './table.props';
import React from 'react';
import { StepDependency } from '../types/stepDependency.types';
import { Column } from 'react-table';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';

export const StepDependencyTable = ({ onDelete, onEdit, data }: TableProps<StepDependency>) => {
  const columns: Column<StepDependency>[] = React.useMemo(
    () => [
      {
        Header: 'Název',
        Cell: (props) => props.row.original.input.name,
      },
      {
        Header: 'Typ',
        Cell: (props) => props.row.original.input.type,
      },
      {
        Header: 'Hodnota',
        Cell: (props) => props.row.original.choice.value,
      },
    ],
    [],
  );
  return (
    <>
      {data && (
        <ReactTable
          columns={columns}
          data={data}
          handleEditItem={onEdit}
          handleDeleteItem={onDelete}
        />
      )}
    </>
  );
};
