import { anyPass, equals, isEmpty, isNil, pipe, prop } from 'ramda';

export const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every((propName) => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (array1, array2, propName) =>
  array1.filter(
    (item) => !array2.find((foundItem) => equals(prop(propName, item), prop(propName, foundItem))),
  );

export const getItemsInBothArraysByProperty = (array1, array2, propName) =>
  array1.filter((item) =>
    array2.find((foundItem) => equals(prop(propName, item), prop(propName, foundItem))),
  );

export const isTruthy = pipe(Boolean, equals(true));
