import { calculationTypeOptions, Input, NewInput, typeOptions } from '../../types/input.types';
import { cFieldNames, validationSchema } from './schema';
import modals from '../../constant/modals';
import { Form } from '../../components/Forms/Formik/forms.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { SelectField } from '../../components/Forms/Formik/Fields/SelectField';
import React from 'react';
import { useModal } from '../../hooks/useModal';
import { InputChoicesFieldArray } from './choices/InputChoicesFieldArray';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { CheckboxField } from '../../components/Forms/Formik/Fields';
import { isSelectableField } from '../../utils/formik';
import { DefaultValueFieldByType } from './DefaultValueFieldByType';

interface Props extends Form<Input> {
  removeValue: (value, success) => void;
  id?: any;
  onSubmit: (values) => void;
}

export const InputForm = ({ data, onSubmit, id, removeValue, loading }: Props) => {
  const { open } = useModal(modals.NEW_INPUT_CHOICE);
  const handleRemoveValue = (receiver, index, remove) => {
    removeValue(receiver, () => remove(index));
  };

  return (
    <CustomForm
      data={data}
      initValues={NewInput}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      formName={'INPUT_FORM'}
    >
      {({ values }) => (
        <>
          <Card
            sx={{
              p: 0,
            }}
          >
            <Box p={1}>
              <CardContent>
                <Grid container direction={'column'} spacing={1}>
                  <TextField name={cFieldNames.name} />
                  <TextField name={cFieldNames.nameInternal} />
                  <TextField name={cFieldNames.exportLabel} />
                  <SelectField name={cFieldNames.type} options={typeOptions} />
                  <SelectField
                    name={cFieldNames.calculationType}
                    options={calculationTypeOptions}
                  />
                  <TextField name={cFieldNames.internalNote} multiline rows={4} maxRows={5} />
                  <TextField name={cFieldNames.tooltip} />
                  <CheckboxField name={cFieldNames.required} />
                  <DefaultValueFieldByType fieldName={cFieldNames.defaultValue} />
                  <SubmitButton loading={loading} />
                </Grid>
              </CardContent>
            </Box>

            <InputChoicesFieldArray
              id={id}
              enable={isSelectableField(values[cFieldNames.type])}
              handleRemove={handleRemoveValue}
              values={values[cFieldNames.choices]}
              addReceiverWithModalOrNot={open}
            />
          </Card>
        </>
      )}
    </CustomForm>
  );
};
