import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { PATHS } from '../../routes/paths';
import { Box, Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import PageContainer from '../../components/PageContainer/PageContainer';
import { UserForm } from '../../forms/User/UserForm';
import { NewUser } from '../../types/users.types';
import { useCreateUserMutation } from '../../hooks/query/users/useCreateUserMutation';

export const NewEditUser = () => {
  const pageName = useNewEditPageTranslate('USERS');
  const createUserMutation = useCreateUserMutation();
  const onSubmit = (data: NewUser) => {
    createUserMutation.mutateAsync(data);
  };
  return (
    <PageContainer title={pageName}>
      <Breadcrumb
        title={pageName}
        items={[{ title: 'USERS', to: `${PATHS.USERS}` }, { title: pageName }]}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              p: 0,
            }}
          >
            <CardContent>
              <Box p={2}>
                <UserForm onSubmit={onSubmit} loading={createUserMutation.isLoading} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
