import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import img1 from '../../assets/images/backgrounds/login-bg.svg';
import LogoIcon from '../../layouts/FullLayout/logo/LogoIcon';
import { LoginForm } from '../../forms/Login/LoginForm';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Link } from 'react-router-dom';
import { PATHS } from '../../routes/paths';
const Login = () => (
  <PageContainer title="Login" description="this is Login page">
    <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
      <Grid
        item
        xs={12}
        sm={12}
        lg={6}
        sx={{
          background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: {
                xs: 'relative',
                lg: 'absolute',
              },
              height: { xs: 'auto', lg: '100vh' },
              right: { xs: 'auto', lg: '-50px' },
              margin: '0 auto',
            }}
          >
            <img
              src={img1}
              alt="bg"
              style={{
                width: '100%',
                maxWidth: '812px',
              }}
            />
          </Box>

          <Box
            sx={{
              p: 4,
              position: 'absolute',
              top: '0',
            }}
          >
            <LogoIcon />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
        <Grid container spacing={0} display="flex" justifyContent="center">
          <Grid item xs={12} lg={9} xl={6}>
            <Box
              sx={{
                p: 4,
              }}
            >
              <Typography fontWeight="700" variant="h2">
                Vítejte ve SrovnejSiTo.eu
              </Typography>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <LoginForm />
              </Box>
              <Link to={`/auth${PATHS.CHANGE_PASSWORD_EMAIL}`}>
                <Typography variant="caption">Resetovat heslo</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </PageContainer>
);

export default Login;
