import errors from '../../constant/errors';
import * as yup from 'yup';

export const cFieldNames = {
  name: 'name',
  iconId: 'iconId',
  required: 'required',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.name]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.iconId]: yup.string().nullable().required(errors.FIELD_REQUIRED_ERROR),
});
