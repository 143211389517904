import React from 'react';
import { Input } from '../../../types/input.types';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import BaseCard from '../../BaseCard/BaseCard';
import { isNilOrEmpty } from '../../../utils/ramda_utils';
import { Delete } from '@mui/icons-material';
import CustomCheckbox from '../../Forms/CustomElements/CustomCheckbox';

interface Props {
  input: Input;
  onDelete?: (id: number) => void;
  onRequiredClick?: (id: number) => void;
  deleteAllowed?: boolean;
  handle?: any;
}
export const InputDraggableItemOnlyText = ({
  input,
  onDelete,
  onRequiredClick,
  deleteAllowed,
  handle,
}: Props) => {
  const isWithImage = input.choices.reduce((prev, cur) => prev || !isNilOrEmpty(cur.image), false);
  return (
    <BaseCard
      style={{ borderRadius: '5px' }}
      cardContentStyle={{ padding: '0.2rem 0.5rem' }}
      cardHeaderStyle={{ padding: '0.3rem 0.5rem' }}
      title={
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={8}>
            <span style={{ fontSize: '15px' }}>{input.nameInternal}</span>
          </Grid>
          <Grid container item xs={4} alignItems={'center'} justifyContent={'end'}>
            <Grid item>
              {deleteAllowed && (
                <Tooltip title="Odstranit toto pole z kroku?">
                  <IconButton
                    size={'small'}
                    onClick={() => {
                      onDelete && onDelete(input.id);
                    }}
                  >
                    <Delete fontSize={'small'} color={'error'} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Tooltip title="Je pole povinné?">
              <Grid item>
                <CustomCheckbox
                  size={'small'}
                  value={input.required}
                  onChange={() => onRequiredClick && onRequiredClick(input.id)}
                  checked={input.required}
                />
              </Grid>
            </Tooltip>
            <Grid item>{handle}</Grid>
          </Grid>
        </Grid>
      }
    >
      <Box ml={1} mb={1}>
        <Grid container direction={'column'} spacing={1}>
          <Grid item>
            <span style={{ fontSize: '14px' }}>
              {!isNilOrEmpty(input.internalNote) ? input.internalNote : input.name}
            </span>
          </Grid>
        </Grid>
      </Box>
    </BaseCard>
  );
};

/*
      title={
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={8}>
            <span>{input.exportLabel}</span>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent={'end'}
            alignItems={'center'}
            alignContent={'center'}
          >
            <FormControlLabel
              value={input.required}
              control={
                <CustomSwitch
                  value={input.required}
                  onChange={() => onRequiredClick && onRequiredClick(input.id)}
                  checked={input.required}
                />
              }
              label={''}
              labelPlacement="end"
            />
            {deleteAllowed && (
              <IconButton
                size={'small'}
                onClick={() => {
                  onDelete && onDelete(input.id);
                }}
              >
                <Delete fontSize={'small'} color={'error'} />
              </IconButton>
            )}
            {handle}
          </Grid>
        </Grid>
      } */

/*
<Box
        sx={{
          textAlign: 'center',
        }}
      >
        {!isNilOrEmpty(input.choices) &&
          (isWithImage ? (
            <div className="cc-selector-2">
              {input.choices.map((ch) => (
                <>
                  <input id={ch.value} type="radio" name={input.name} value={ch.value} />
                  <label
                    className="drinkcard-cc"
                    style={{ backgroundImage: `url(${serverCore}${ch.image?.url})` }}
                    htmlFor={ch.value}
                  />
                </>
              ))}
            </div>
          ) : (
            <>
              {input.choices.map((ch) => (
                <FormControlLabel
                  value={ch.value}
                  control={<CustomRadio />}
                  label={ch.description}
                  labelPlacement="end"
                />
              ))}
            </>
          ))}
      </Box>
 */
