import { Grid } from '@mui/material';
import { Form } from '../../components/Forms/Formik/forms.types';
import { ProductBank } from '../../types/productBanks.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { cFieldNames, validationSchema } from './schema';
import { SelectField } from '../../components/Forms/Formik/Fields/SelectField';
import { Bank } from '../../types/bank.types';
import { SelectMenuItem } from '../../components/Forms/Formik/TextFormikInput';
import { pathOr, propOr } from 'ramda';
import { NumberField } from '../../components/Forms/Formik/Fields/NumberField';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import TableAvatar from '../../components/TableAvatar';
import React from 'react';

interface Props extends Form<ProductBank> {
  id?: any;
  banks: Array<Bank>;
  onSubmit: (values) => void;
}

export const ProductBankForm = ({ onSubmit, loading, data, banks }: Props) => {
  const formData: ProductBank = {
    id: propOr(undefined, 'id', data),
    externalName: propOr('', 'externalName', data),
    note: propOr('', 'note', data),
    internalNote: propOr('', 'internalNote', data),
    bank: pathOr(-1, ['bank', 'id'], data),
    accountName: propOr('', 'accountName', data),
    computeValue: propOr(0, 'computeValue', data),
    createValue: propOr(0, 'createValue', data),
    url: propOr('', 'url', data),
    inputs: [],
  };

  const mappedOptions = banks.map(
    (b) =>
      ({
        id: b.id,
        name: (
          <Grid container spacing={1}>
            <Grid item>{b.name}</Grid>
            <Grid item>
              <TableAvatar size={25} url={b.image?.url} />{' '}
            </Grid>
          </Grid>
        ),
      } as SelectMenuItem),
  );
  return (
    <CustomForm
      data={formData}
      initValues={formData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      formName={'PRODUCT_BANK_FORM'}
    >
      {({}) => (
        <Grid container direction={'column'} spacing={1}>
          <SelectField name={cFieldNames.bank} options={mappedOptions} />
          <TextField name={cFieldNames.accountName} />
          <NumberField name={cFieldNames.computeValue} />
          <NumberField name={cFieldNames.createValue} />
          <TextField name={cFieldNames.externalName} />
          <TextField name={cFieldNames.url} />
          <TextField name={cFieldNames.note} multiline rows={4} maxRows={5} />
          <TextField name={cFieldNames.internalNote} multiline rows={4} maxRows={5} />
          <SubmitButton loading={loading} />
        </Grid>
      )}
    </CustomForm>
  );
};
