import { NavLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { always, ifElse } from 'ramda';
import { isString } from 'formik';

interface Props {
  title: string;
  to?: string;
  icon: string | React.ReactNode;
  onClick?: () => void;
}
export const NavButton = ({ title, to, icon, onClick }: Props) =>
  to ? (
    <Button
      to={to}
      component={NavLink}
      sx={{
        ml: 1,
      }}
      variant="contained"
      color="primary"
    >
      {ifElse(
        isString,
        always(<FeatherIcon icon={icon} width="18" height="18" />),
        () => icon,
      )(icon)}
      <Box fontWeight="400" sx={{ ml: 1 }}>
        {title}
      </Box>
    </Button>
  ) : (
    <Button
      sx={{
        ml: 1,
      }}
      onClick={onClick}
      variant="contained"
      color="primary"
    >
      {ifElse(
        isString,
        always(<FeatherIcon icon={icon} width="18" height="18" />),
        () => icon,
      )(icon)}
      <Box fontWeight="400" sx={{ ml: 1 }}>
        {title}
      </Box>
    </Button>
  );
