import { createContext, ReactNode, useEffect, useReducer } from 'react';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { authorizatorURI, tenantId } from '../../config';
import { User } from '../../types/user.types';
import {
  ACCESS_TOKEN_KEY,
  AUTHORIZATION_HEADER,
  BEARER_PREFIX,
  TENANT_HEADER,
} from '../../constant/httpHeaders';
import { axios, axiosAuth } from '../../utils/apiUtils';
import { authReducer } from './reducer';
import { authInitialState, Types } from './authState';
import { isNilOrEmpty } from '../../utils/ramda_utils';

// All types
// =============================================
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

const AuthContext = createContext({
  ...authInitialState,
  method: 'JWT',
  login: (username: string, password: string) => Promise.resolve(),
  logout: () => {},
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  const setSession = (accessToken: string | null, tenants: string[]) => {
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      axiosAuth.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      axiosAuth.defaults.headers.common[TENANT_HEADER] = isNilOrEmpty(tenants) ? '' : tenants[0];
      axios.defaults.headers.common[TENANT_HEADER] = isNilOrEmpty(tenants) ? '' : tenants[0];

      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          console.log('WARN ERROR INTERCEPTOR', error.response);

          if (error) {
            if (error.response) {
              if (error.response.status) {
                if (401 === error.response.status) {
                  logout();
                } else if (403 === error.response.status) {
                  logout();
                } else {
                  return Promise.reject(error.response);
                }
              }
            }
          }
        },
      );
    } else {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      delete axios.defaults.headers.common.Authorization;
      delete axiosAuth.defaults.headers.common.Authorization;
      delete axiosAuth.defaults.headers.common[TENANT_HEADER];
      delete axios.defaults.headers.common[TENANT_HEADER];
    }
  };

  const login = async (username: string, password: string): Promise<any> => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<User>(`${authorizatorURI}/token`, {
          username,
          password,
        }, {
          headers: {
            [TENANT_HEADER]: tenantId,
          },
        });
        //@ts-ignore
        const user: User = response.data;
        setSession(user.accessToken, user.tenants);
        dispatch({
          type: Types.Login,
          payload: {
            user,
          },
        });
        res(response);
      } catch (e) {
        rej(e);
      }
    });
  };

  const logout = () => {
    // setSession(null);
    // TODO revoke token
    dispatch({ type: Types.Logout });
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);

        if (accessToken) {
          const response = await axios.post<User>(
            `${authorizatorURI}/refresh`,
            {},
            {
              headers: {
                [AUTHORIZATION_HEADER]: `${BEARER_PREFIX} ${accessToken}`,
                [TENANT_HEADER]: tenantId,
              },
            },
          );
          //@ts-ignore
          const user: User = response.data;
          setSession(user.accessToken, user.tenants);
          dispatch({
            type: Types.Init,
            payload: {
              user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
