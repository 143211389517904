import PageContainer from '../../components/PageContainer/PageContainer';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { PATHS } from '../../routes/paths';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import React from 'react';
import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import { ProductBankForm } from '../../forms/ProductBank';
import { ifElse } from 'ramda';
import { useParams } from 'react-router';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { FormBoxDivider } from '../../components/FormboxDivider';
import { Box, Card, CardContent, Grid, Tab, Tabs } from '@mui/material';
import { ProductBankInputDataProvider } from '../../context/ProductBankDataContext/ProductBankDataProvider';
import { ProductBankInputBuilderLayout } from '../../components/ProductBankInputConnectorBuilder/ProductBankInputBuilderLayout';
import { a11yProps, TabPanel } from '../Steps/NewEditStep';
import { ProductBankInputChangelogTable } from '../../tables/ProductBankInputChangelog';
import {
  useCreateProductBank,
  useEditProductBank,
} from '../../hooks/query/product-banks/useCreateEditProductBank';
import { useBankProductById } from '../../hooks/query/product-banks/useBankProductById';
import { useProductBankLogs } from '../../hooks/query/banks/useProductBankLogs';
import { useBanksAll } from '../../hooks/query/banks/useBanks';

export const NewEditProductBanks = () => {
  const pageName = useNewEditPageTranslate('PRODUCT_BANKS');
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const createMutation = useCreateProductBank();
  const editMutation = useEditProductBank();
  const { data, isError, isLoading } = useBankProductById(id);
  const {
    data: logs,
    isLoading: logsLoading,
    isError: logsError,
    refetch: refetchLogs,
  } = useProductBankLogs(id);
  const { data: banks, isLoading: banksLoading, isError: isBanksError } = useBanksAll();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = async (data) => {
    ifElse(
      isNilOrEmpty,
      () => createMutation.mutateAsync(data),
      () => editMutation.mutateAsync(data),
    )(id);
    await refetchLogs();
  };

  return (
    <PageContainer title={pageName}>
      <Breadcrumb
        title={pageName}
        items={[{ title: 'PRODUCT_BANKS', to: `${PATHS.PRODUCT_BANK}` }, { title: pageName }]}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              p: 0,
            }}
          >
            <CardContent>
              <Box p={2}>
                <LoadingErrorContainer
                  isLoading={isLoading || banksLoading}
                  isError={isError || isBanksError}
                >
                  {banks && (
                    <ProductBankForm
                      data={data}
                      loading={createMutation.isLoading || editMutation.isLoading}
                      id={id}
                      onSubmit={onSubmit}
                      banks={banks.elements}
                    />
                  )}
                </LoadingErrorContainer>
              </Box>
            </CardContent>
            {!isNilOrEmpty(id) && (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label={'Nastavení'} {...a11yProps(0)} />
                  <Tab label={'Změny'} {...a11yProps(1)} />
                </Tabs>
              </Box>
            )}

            <TabPanel value={value} index={0}>
              {!isNilOrEmpty(id) && (
                <>
                  <FormBoxDivider icon={'alert-circle'} text={'Inputy'} />
                  <CardContent>
                    <Box p={2}>
                      <ProductBankInputDataProvider>
                        <ProductBankInputBuilderLayout />
                      </ProductBankInputDataProvider>
                    </Box>
                  </CardContent>
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box p={2}>
                <LoadingErrorContainer isLoading={logsLoading} isError={logsError}>
                  {logs && <ProductBankInputChangelogTable data={logs} />}
                </LoadingErrorContainer>
              </Box>
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
