import { Box, Grid } from '@mui/material';
import ImageSelect from '../../../ImageSelect';
import { Field } from 'formik';
import { File } from '../../../../types/files.types';

interface Props {
  imageDefault: File;
  name: string;
}
export const ImageSelectField = ({ name, imageDefault }: Props) => {
  return (
    <Grid item xs={12}>
      <Box mt={1}>
        <Field name={name} muiPreview component={ImageSelect} imageDefault={imageDefault} />
      </Box>
    </Grid>
  );
};
