import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { InputChoice } from '../../../types/input.types';
import axios from '../../../utils/apiUtils';

interface UseCreateInputChoiceVariable {
  id: any;
  values: InputChoice;
}

export const useCreateInputChoice = () => {
  return useMutation<AxiosResponse<InputChoice>, AxiosError, UseCreateInputChoiceVariable>(
    (data: UseCreateInputChoiceVariable) =>
      axios.post<InputChoice>(`/v1/input/${data.id}/choice`, data.values),
  );
};
export const useEditInputChoice = () => {
  return useMutation<AxiosResponse<InputChoice>, AxiosError, UseCreateInputChoiceVariable>(
    (data: UseCreateInputChoiceVariable) =>
      axios.put<InputChoice>(`/v1/input/choice/${data.values.id}`, data.values),
  );
};
