import * as yup from 'yup';
import errors from '../../constant/errors';

export interface LoginForm {
  username: string;
  password: string;
}
export const cFieldNames = {
  username: 'username',
  password: 'password',
};

export const initialData: LoginForm = {
  username: '',
  password: '',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.username]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.password]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
});
