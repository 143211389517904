import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DraggableToolbarItem } from './DraggableToolbarItem';
import { Box, Paper } from '@mui/material';
import { Input } from '../../types/input.types';
import { DroppableZones } from '../../context/StepInputDataContext/DroppableZones';
import { InputDraggableItemOnlyText } from './InputTypesPreviews/InputDraggableItemOnlyText';

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

interface Props {
  items: Input[];
  droppableId: DroppableZones;
}

export const Toolbar = ({ items, droppableId }: Props) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Paper variant={'outlined'} ref={provided.innerRef}>
          <Box
            sx={{
              pt: 1,
              pb: 1,
              m: 2,
            }}
          >
            {items
              ? items.map((input, index) => (
                  <DraggableToolbarItem
                    key={input.id}
                    content={<InputDraggableItemOnlyText input={input} />}
                    item={input}
                    index={index}
                  />
                ))
              : !provided.placeholder && <Notice>Drop items here</Notice>}
            {provided.placeholder}
          </Box>
        </Paper>
      )}
    </Droppable>
  );
};
