import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/Store';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/Spinner/Spinner';
import App from './App';
import './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-upload-gallery/dist/style.css'; // or scss
import 'react-tabs/style/react-tabs.css';
import './assets/scss/styles.scss';
import { AuthProvider } from './context/AuthContext/AuthContext';
import Head from 'react-helmet';
import { SITE_DESCRIPTION, SITE_IMAGE, SITE_NAME, SITE_TITLE } from './constant/env';
import moment from 'moment';
import 'moment/locale/cs';
import { QueryParamProvider } from 'use-query-params';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';

moment.locale('cs');
const queryClient = new QueryClient();

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );
  return children({ history: adaptedHistory, location });
};

// @ts-ignore
ReactDOM.render(
  <>
    <Head>
      <link rel="shortcut icon" href="/public/favicon.ico" />
      <title>Srovnávač bankovních poplatků | srovnejsito.eu</title>
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&amp;display=swap"
        rel="stylesheet"
      />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <meta
        name={'description'}
        content={
          'Srovnávač bankovních poplatků se stochastickou komponentou. Zjistěte, který účet je pro Vás ten pravý! Bez vazby na banku a podsouvání výsledků. Opravdu spravedlivý porovnávač bankovních poplatků!'
        }
      />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:title" content={SITE_TITLE} />
      <meta property="og:description" content={SITE_DESCRIPTION} />
      <meta property="og:image" content={SITE_IMAGE} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={SITE_NAME} />
      <meta name="twitter:title" content={SITE_TITLE} />
      <meta name="twitter:description" content={SITE_DESCRIPTION} />
      <meta property="twitter:image" content={SITE_IMAGE} />
      <meta name="format-detection" content="telephone=no, address=no, email=no" />
    </Head>
    <Provider store={configureStore()}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ToastContainer />
          <Suspense fallback={<Spinner />}>
            <BrowserRouter>
              {/* @ts-ignore */}
              <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <App />
              </QueryParamProvider>
            </BrowserRouter>
          </Suspense>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
    <Toaster />
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
