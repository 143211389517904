import { isArray, isNilOrEmpty } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { equals } from 'ramda';
import RUG from 'react-upload-gallery';
import { SubmitAndBack } from '../Forms/Submit/SubmitAndBack';
import { useApiPagination } from '../../hooks/useApiPagination';
import { DragADropHeaderArea } from '../NewGalleryItem/DragADropHeaderArea';
import { customRequest } from '../../api/custom_request_file';
import { CardContent, Dialog, DialogActions, Grid } from '@mui/material';
import { ImageItem } from '../NewGalleryItem/ImageItem';
import { ImageRUG } from '../NewGalleryItem/ImagePreview';
import { File } from '../../types/files.types';
import { MUIStyledPagination } from '../Pagination/PaginationStyled';

const GalleryModal = ({ onSelected, open, close }) => {
  const { data, reFetch, pagination, changePage } = useApiPagination<File>(`/file`, {
    pageNumber: 0,
    pageSize: 20,
  });
  const [selected, setSelected] = useState<ImageRUG | null>(null);
  const onClick = (image) => {
    setSelected(image);
  };
  const onAdd = () => {
    onSelected(selected);
    close();
  };

  useEffect(() => {
    reFetch();
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={'xl'}
      fullWidth
      style={{
        maxWidth: '1600px',
        width: '80%',
        margin: '10px auto',
      }}
    >
      <CardContent>
        <RUG
          header={({ openDialogue }) => (
            <DragADropHeaderArea openDialogue={openDialogue} hideGallerySelect />
          )}
          sorting={false}
          source={(response) => response.url}
          accept={['jpg', 'jpeg', 'png']}
          customRequest={customRequest(reFetch)}
          initialState={[]}
        >
          {() => <></>}
        </RUG>
        <Grid container spacing={2}>
          {!isNilOrEmpty(isArray(data)) &&
            data.map((i: File) => (
              <Grid item>
                <ImageItem
                  smallPreview
                  image={i}
                  selected={equals(selected?.id, i.id)}
                  onClick={onClick}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container justifyContent={'flex-end'}>
          <MUIStyledPagination
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              changePage(value === 1 ? 0 : value - 1);
            }}
            count={pagination.totalPages}
            page={pagination.page + 1}
          />
        </Grid>
      </CardContent>
      <DialogActions>
        <SubmitAndBack onSubmitClick={onAdd} onBackClick={close} />
      </DialogActions>
    </Dialog>
  );
};
export default GalleryModal;
