import { Step } from '../../types/step.types';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import React from 'react';
import { StepDependencyForm } from '../../forms/StepDependency';
import { StepDependencyTable } from '../../tables/StepDependencyTable';
import { StepDependency as StepDependencyType } from '../../types/stepDependency.types';
import { useInputsAll } from '../../hooks/query/inputs/useInputs';
import {
  useCreateStepDependencyMutation,
  useDeleteStepDependencyMutation,
} from '../../hooks/query/steps/useStepDependencyMutation';

interface Props {
  step: Step;
  refresh: () => void;
}

export const StepDependency = ({ step, refresh }: Props) => {
  const { data, isLoading } = useInputsAll();
  const createDependencyMutation = useCreateStepDependencyMutation();
  const deleteDependencyMutation = useDeleteStepDependencyMutation();

  const onSubmit = async (values) => {
    await createDependencyMutation
      .mutateAsync({ stepId: step.id, choiceId: values.choiceId })
      .then(refresh);
  };

  const onDelete = async (stepDependency: StepDependencyType) => {
    await deleteDependencyMutation
      .mutateAsync({ stepId: step.id, choiceId: stepDependency.choice.id })
      .then(refresh);
  };

  return (
    <>
      {!isNilOrEmpty(step.stepDependency) && (
        <StepDependencyTable data={step.stepDependency} onDelete={onDelete} />
      )}
      {isNilOrEmpty(step.stepDependency) && data && (
        <StepDependencyForm
          onSubmit={onSubmit}
          inputs={data.elements}
          isLoading={isLoading}
          loading={createDependencyMutation.isLoading}
        />
      )}
    </>
  );
};
