import React from 'react';
import { TableBody } from '@mui/material';
// @ts-ignore
import { sortableContainer } from 'react-sortable-hoc';
import { ReactTableProps } from './ReactTable';

interface TableBodyProps extends ReactTableProps {}

const SortableContainer = sortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

export const ReactTableTableBody: React.FC<TableBodyProps> = ({
  sortable,
  onSortEnd,
  children,
  ...props
}) => {
  const handleSortStart = ({ node }) => {
    const tds = document.getElementsByClassName('SortableHelper')[0].childNodes;
    node.childNodes.forEach(
      // @ts-ignore
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`),
    );
  };
  return sortable ? (
    <SortableContainer
      useDragHandle
      onSortStart={handleSortStart}
      onSortEnd={onSortEnd}
      helperClass="SortableHelper"
    >
      {children}
    </SortableContainer>
  ) : (
    <TableBody {...props}>{children}</TableBody>
  );
};
