import React from 'react';
import { not } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import ImagePlaceholder from '../../assets/images/image_placeholder.png';
import { Avatar } from '@mui/material';
import { serverCore } from '../../config';

interface Props {
  url?: string;
  size?: number;
}
const TableAvatar = ({ url, size = 35 }: Props) => {
  return (
    <Avatar
      src={not(isNilOrEmpty(url)) ? `${serverCore}${url}` : ImagePlaceholder}
      alt={url ?? ''}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
      }}
    />
  );
};
export default TableAvatar;
