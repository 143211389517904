import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PRODUCT_BANK_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { PATHS } from '../../../routes/paths';
import axios from '../../../utils/apiUtils';
import { ProductBank } from '../../../types/productBanks.types';

export const useCreateProductBank = () => {
  const navigate = useNavigate();
  return useMutation<AxiosResponse<ProductBank>, AxiosError, ProductBank>(
    (data: ProductBank) => axios.post<ProductBank>(PRODUCT_BANK_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit krok');
      },
      onSuccess: ({ data }) => {
        toast.success('Krok byl vytvořen');
        navigate(`${PATHS.PRODUCT_BANK}${PATHS.EDIT}/${data.id}`);
      },
    },
  );
};

export const useEditProductBank = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<ProductBank>, AxiosError, ProductBank>(
    (data: ProductBank) => axios.put<ProductBank>(`${PRODUCT_BANK_API}/${data.id}`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat produktový vstup');
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([`${PRODUCT_BANK_API}/${data.id}`]);
        toast.success('Produktový vstup byl aktualizován');
      },
    },
  );
};
