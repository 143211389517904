import { Bank, BankResponse } from './bank.types';
import { Input } from './input.types';

export interface ProductBankAbstract {
  id: number;
  externalName: boolean;
  accountName: string;
  note: string;
  internalNote: string;
  computeValue: number;
  url: string;
  createValue: number;
}

export interface ProductBank extends ProductBankAbstract {
  bank: Bank | number;
  inputs: Array<Input> | Array<number>;
}

export const NewProductBank: ProductBank = {
  id: 0,
  bank: 0,
  externalName: true,
  accountName: '',
  note: '',
  internalNote: '',
  computeValue: 0,
  createValue: 0,
  inputs: [],
  url: '',
};
