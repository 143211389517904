import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { RESET_PASSWORD } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

interface UserResetPasswordVariables {
  token: string;
  password: string;
}

export const useResetPassword = () => {
  const navigate = useNavigate();
  return useMutation<
    AxiosResponse<UserResetPasswordVariables>,
    AxiosError,
    UserResetPasswordVariables
  >(
    (data: UserResetPasswordVariables) =>
      axios.post<UserResetPasswordVariables>(RESET_PASSWORD, data),
    {
      onError: () => {
        toast.error('Nepodařilo se ověřit token');
      },
      onSuccess: () => {
        toast.success('Heslo resetováno');
        navigate('/');
      },
    },
  );
};
