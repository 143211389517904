import React, { useEffect, useState } from 'react';
import { server } from '../config';
import { pathOr, propOr } from 'ramda';
import { Box, Grid, Pagination as Pag } from '@mui/material';
import axios from '../utils/apiUtils';

export interface IPagination<T> {
  elements: Array<T>;
  pageNumber: number;
  totalPages: number;
  hasNextPage: boolean;
  elementsPerPage: number;
  numberOfElements: number;
  totalNumberOfElements: number;
}

export interface PaginationParams {
  pageNumber: number;
  pageSize: number;
  sort?: string;
}

export interface UsePaginationPaginationOutput {
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
}

export interface UsePaginationApi<T> {
  data: Array<T>;
  setData: (items) => void;
  isLoading: boolean;
  isError: boolean;
  fetchData: (iUrl: string, params: PaginationParams) => void;
  fetchDataWithNewUrl: (url: string, params: PaginationParams) => void;
  reFetch: () => void;
  pagination: UsePaginationPaginationOutput;
  changePage: (page: number) => void;
  changePerPage: (perPage: number) => void;
  changePageWithPerPage: (page: number, perPage: number) => void;
  searchWithOldPerPageAndPage: (search) => void;
  Pagination: JSX.Element;
}

export const MAX_PAGINATION: PaginationParams = {
  pageNumber: 0,
  pageSize: 1000000,
};

export function useApiPagination<T>(
  initialUrl,
  params: PaginationParams = { pageNumber: 0, pageSize: 10 },
) {
  const [data, setData] = useState<IPagination<T>>({
    elements: [],
    elementsPerPage: 0,
    hasNextPage: false,
    numberOfElements: 0,
    pageNumber: 0,
    totalPages: 0,
    totalNumberOfElements: 0,
  });
  const [url] = useState(`${server}${initialUrl}`);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchDataWithNewUrl = async (url, params: PaginationParams) => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await axios.get(url, {
        params,
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const fetchData = async (iUrl, params: PaginationParams) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(iUrl, {
        params: {
          ...params,
        },
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const changePage = async (page) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(url, {
        params: {
          pageNumber: page,
          pageSize: params.pageSize,
        },
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const changePerPage = async (perPage) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(url, {
        params: {
          pageNumber: 0,
          pageSize: perPage,
        },
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const changePageWithPerPage = async (page, perPage) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(url, {
        params: {
          pageNumber: page,
          pageSize: perPage,
        },
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const searchWithOldPerPageAndPage = async (search) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(url, {
        params: {
          ...params,
          search: search,
        },
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const reFetch = () => {
    fetchData(url, {
      pageNumber: pathOr(0, ['pageNumber'], data),
      pageSize: params.pageSize,
    });
  };
  useEffect(() => {
    fetchData(`${server}${initialUrl}`, params);
  }, []);

  const pages = Math.ceil(pathOr(0, ['totalNumberOfElements'], data) / params.pageSize);
  const Pagination = (
    <Box mt={1}>
      <Grid container justifyContent={'end'}>
        <Grid item>
          <Pag
            onChange={(e, page) => changePage(page - 1)}
            page={pathOr(0, ['pageNumber'], data) + 1}
            count={pages}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
    </Box>
  );
  const output: UsePaginationApi<T> = {
    data: data.elements ?? [],
    setData: (items) => setData({ ...data, elements: items }),
    isLoading,
    isError,
    fetchData,
    fetchDataWithNewUrl,
    reFetch,
    pagination: {
      page: pathOr(0, ['pageNumber'], data),
      perPage: 100,
      totalCount: pathOr(0, ['numberOfElements'], data),
      totalPages: propOr(0, 'totalPages', data),
    },
    changePage,
    changePerPage,
    changePageWithPerPage,
    searchWithOldPerPageAndPage,
    Pagination,
  };
  return output;
}
