import { DragDropContext } from 'react-beautiful-dnd';
import { Grid } from '@mui/material';
import { LoadingErrorContainer } from '../LoadingErrorContainer';
import { useProductBankInputData } from '../../hooks/useProductBankInputData';
import { ProductBankInputDroppableArea } from './ProductBankInputDroppableArea';
import { DroppableZones } from '../../context/ProductBankDataContext/DroppableZones';
import { ProductBankInputToolbar } from './ProductBankInputToolbar';
import { useParams } from 'react-router';
import { SearchInputs } from '../../context/StepInputDataContext/SearchInputs';
import { useFormTranslate } from '../../hooks/useFormTranslate';
import { useUpdateValueInConnectedProductBankInput } from '../../hooks/query/connectStepAndInput/useConnectProductBankAndInputMutation';

export const ProductBankInputBuilderLayout = () => {
  const {
    isError,
    isLoading,
    state,
    onDragEnd,
    leftInputsSearchOnChange,
    searchLeftInputsFieldValue,
    rightInputsSearchOnChange,
    searchRightInputsFieldValue,
  } = useProductBankInputData();
  const { id } = useParams();
  const { t } = useFormTranslate('PRODUCT_BANK_FORM');
  const updateInfoMutation = useUpdateValueInConnectedProductBankInput();
  const updateInputValue = (value: string, min: string, createValue: string, inputId: number) =>
    updateInfoMutation.mutateAsync({
      id,
      inputId,
      data: {
        value,
        min,
        createValue,
      },
    });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2}>
        <SearchInputs
          leftXs={6}
          rightXs={6}
          leftSearchValue={searchLeftInputsFieldValue}
          leftSearchOnChange={(value) => leftInputsSearchOnChange(value)}
          leftSearchPlaceholder={t('startType')}
          rightSearchValue={searchRightInputsFieldValue}
          rightSearchOnChange={(value) => rightInputsSearchOnChange(value)}
          rightSearchPlaceholder={t('startType')}
        />
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <LoadingErrorContainer isLoading={isLoading} isError={isError}>
              <ProductBankInputDroppableArea
                onButtonValueSaveClick={updateInputValue}
                items={state.data}
                droppableId={DroppableZones.PRODUCT_BANK_CONNECTED_INPUTS}
                onDelete={onDragEnd}
              />
            </LoadingErrorContainer>
          </Grid>
          <Grid item xs={6}>
            <LoadingErrorContainer isLoading={isLoading} isError={isError}>
              <ProductBankInputToolbar
                items={state.inputs}
                droppableId={DroppableZones.PRODUCT_BANK_FREE_INPUTS}
              />
            </LoadingErrorContainer>
          </Grid>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};
