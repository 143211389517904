import { initialValues, NewStep } from '../../types/step.types';
import { cFieldNames, validationSchema } from './schema';
import { Form } from '../../components/Forms/Formik/forms.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { CheckboxField } from '../../components/Forms/Formik/Fields';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { ImageSelectField } from '../../components/Forms/Formik/Fields/ImageSelectField';
import { prop } from 'ramda';

export const StepForm = ({ data, onSubmit, loading }: Form<NewStep>) => {
  return (
    <>
      {data && (
        <CustomForm
          data={data}
          initValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formName={'STEP_FORM'}
        >
          {({ values }) => (
            <Grid container direction={'column'} spacing={1}>
              <TextField name={cFieldNames.name} />
              <ImageSelectField imageDefault={prop('icon', values)} name={cFieldNames.iconId} />
              <CheckboxField name={cFieldNames.required} />
              <SubmitButton loading={loading} />
            </Grid>
          )}
        </CustomForm>
      )}
    </>
  );
};
