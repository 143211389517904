import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { createContext } from 'react';
import { identity } from 'ramda';
import { ReducerState } from './ProductBankDataProvider';
import { ProductBankInput } from '../../types/productBankInput.types';
import { Input } from '../../types/input.types';

export interface ProductBankContextData {
  state: ReducerState<Input, ProductBankInput>;
  isLoading: boolean;
  isError: boolean;
  connectToStep: (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => void;
  reorder: (oldIndex: number, newIndex: number) => void;
  deleteItem: (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => void;
  onDragEnd: (result: DropResult) => void;
  updateProductBankInputValue: (value: string, inputId: number) => void;
  updateProductBankInputMin: (value: string, inputId: number) => void;
  updateProductBankInputCreateValue: (value: string, inputId: number) => void;

  rightInputsSearchOnChange: (search: string) => void;
  searchRightInputsFieldValue: string;
  searchLeftInputsFieldValue: string;
  leftInputsSearchOnChange: (search: string) => void;
}

export const ProductBankDataContext = createContext({
  state: {
    inputs: [],
    data: [],
  },
  isLoading: true,
  isError: false,
  connectToStep: identity,
  reorder: identity,
  deleteItem: identity,
  onDragEnd: identity,
  updateProductBankInputValue: identity,
  updateProductBankInputMin: identity,
  updateProductBankInputCreateValue: identity,
  rightInputsSearchOnChange: identity,
  searchRightInputsFieldValue: '',
  searchLeftInputsFieldValue: '',
  leftInputsSearchOnChange: identity,
} as ProductBankContextData);
