import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { STEP_API } from '../../../constant/api_url';
import { Step } from '../../../types/step.types';
import { IPagination } from '../../useApiPagination';
import { toast } from 'react-hot-toast';

interface ChangeIndexMutationVariables {
  newOrder: { id: number; sort: number }[];
  newArray: Array<Step>;
  oldIndex: number;
  newIndex: number;
}

export const useOnSortEndSteps = (params: Record<any, any>) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ChangeIndexMutationVariables>(
    (data: ChangeIndexMutationVariables) => axios.post(`${STEP_API}/order`, data.newOrder),
    {
      onMutate: async (data: ChangeIndexMutationVariables) => {
        await queryClient.cancelQueries(STEP_API);
        const previousData = queryClient.getQueryData<IPagination<Step>>([STEP_API, params]);
        queryClient.setQueryData([STEP_API, params], { ...previousData, elements: data.newArray });
        return { previousData };
      },
      onError: (err, _) => {
        toast.error('Nepodařilo se změnit pořadí kroků');
      },
      onSuccess: () => {
        toast.success('Podařilo se změnit pořadí kroků');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === STEP_API,
        });
      },
    },
  );
};
