import { useEffect, useState } from 'react';
import { Input } from '../../types/input.types';
import useHistoryReducer from 'react-use-history-reducer';
import { equals, toString } from 'ramda';
import { DragAndDropHelpers } from '../../components/StepBuilder/DragAndDropHelpers';
import { reducer } from './reducer';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { StepInputDataContext } from './StepInputDataContext';
import { ACTIONS } from './actions';
import { useParams } from 'react-router-dom';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { DroppableZones } from './DroppableZones';
import { useFilter } from '../../utils/search.utils';
import { useInputChangeOrder } from '../../hooks/query/step-input/useInputChangeOrder';
import { useInputConnectWithStep } from '../../hooks/query/step-input/useInputConnectWithStep';
import { useToggleRequired } from '../../hooks/query/step-input/useStepInputToggleRequired';
import { useDeleteConnectionStepAndInput } from '../../hooks/query/step-input/useDeleteConnectionStepAndInput';
import { useAvailableInputsForStepById } from '../../hooks/query/inputs/useAvailableInputsForStepById';
import { useConnectedInputsToStep } from '../../hooks/query/inputs/useConnectedInputsToStep';

export interface ReducerState {
  inputs: Input[];
  data: Input[];
}

export const StepInputDataProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const [searchLeftInputs, setSearchLeftInputs] = useState('');
  const { id } = useParams();
  const inputChangeOrderMutation = useInputChangeOrder();
  const connectStepAndInputMutation = useInputConnectWithStep();
  const toggleRequiredMutation = useToggleRequired();
  const deleteConnectionMutation = useDeleteConnectionStepAndInput();
  const {
    data: inputs,
    isLoading: isInputLoading,
    isError: isInputError,
  } = useAvailableInputsForStepById(id);
  const { data, isLoading, isError } = useConnectedInputsToStep(id);
  // @ts-ignore
  const [state, dispatch, history] = useHistoryReducer<ReducerState>(reducer, {
    inputs: [],
    data: [],
  });

  useEffect(() => {
    if (data) {
      dispatch({ type: ACTIONS.LOAD_DATA, payload: data });
    }
  }, [data]);

  useEffect(() => {
    if (inputs) {
      dispatch({ type: ACTIONS.LOAD_INPUTS, payload: inputs });
    }
  }, [inputs]);

  const connectToStep = async (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
    draggableId: string,
  ) => {
    if (!droppableDestination || !droppableSource) return;
    if (!isNilOrEmpty(search)) {
      // Find input by inputId from DataDNDContext
      const indexInRootArray = state.inputs.findIndex((i) => equals(draggableId, toString(i.id)));
      // change it in reducer
      dispatch({
        type: ACTIONS.MOVE,
        droppableSource: { ...droppableSource, index: indexInRootArray },
        droppableDestination,
      });
    } else {
      // Do move from right to left droppable
      dispatch({ type: ACTIONS.MOVE, droppableSource, droppableDestination });
    }
    // do api call to connect step and input
    connectStepAndInputMutation
      .mutateAsync({
        sort: droppableDestination.index,
        draggableId,
        id,
      })
      .catch(() => {
        history.undo();
      });
  };

  const reorder = async (oldIndex: number, newIndex: number) => {
    // do reorder in state
    const newArraysData = DragAndDropHelpers.reorder(state.data, oldIndex, newIndex);
    // do api fetch reordering
    await dispatch({ type: ACTIONS.SET_DATA, payload: newArraysData });
    inputChangeOrderMutation
      .mutateAsync({
        id,
        data: newArraysData.map(({ id }, index) => ({ id, sort: index })),
      })
      .catch(() => {
        history.undo();
      });
  };

  const deleteItem = async (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => {
    if (!droppableDestination || !droppableSource) return;
    // Find input from source to get id to connect via api
    const input = state.data[droppableSource.index];
    // Do move from right to left droppable
    dispatch({ type: ACTIONS.DELETE, droppableSource, droppableDestination });
    deleteConnectionMutation.mutateAsync({ id, inputId: input.id }).catch(() => {
      history.undo();
    });
  };

  const toggleRequired = async (inputId: number) => {
    dispatch({ type: ACTIONS.TOGGLE_REQUIRED, payload: inputId });
    toggleRequiredMutation.mutateAsync({ inputId }).catch(() => {
      history.undo();
    });
  };
  const inputsSearchOnChange = (search: string) => {
    setSearch(search);
  };

  const leftInputsSearchOnChange = (search: string) => {
    setSearchLeftInputs(search);
  };

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) {
      return;
    }
    switch (source.droppableId) {
      case destination.droppableId:
        if (equals(source.index, destination.index)) {
          return;
        }
        reorder(source.index, destination.index);
        break;
      case DroppableZones.FREE_INPUTS:
        connectToStep(source, destination, draggableId);
        break;
      case DroppableZones.CONNECTED_INPUTS:
        deleteItem(source, destination);
        break;
      default:
        break;
    }
  };

  return (
    <StepInputDataContext.Provider
      value={{
        isLoading: isLoading || isInputLoading,
        isError: isError || isInputError,
        data: {
          data: useFilter<Input>(searchLeftInputs, ['name'], state.data),
          inputs: useFilter<Input>(search, ['name'], state.inputs),
        },
        reorder: reorder,
        connectToStep: connectToStep,
        deleteItem: deleteItem,
        inputsSearchOnChange: inputsSearchOnChange,
        inputsSearch: search,
        toggleRequired: toggleRequired,
        onDragEnd: onDragEnd,
        searchLeftInputs: searchLeftInputs,
        leftInputsSearchOnChange: leftInputsSearchOnChange,
      }}
    >
      {children}
    </StepInputDataContext.Provider>
  );
};
