import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Steps } from '../views/Steps';
import { PATHS } from './paths';
import { NewEditStep } from '../views/Steps/NewEditStep';
import { Banks } from '../views/Banks';
import { NewEditBank } from '../views/Banks/NewEditBank';
import { Inputs } from '../views/Inputs';
import { NewEditInputs } from '../views/Inputs/NewEditInputs';
import { Gallery } from '../views/Gallery';
import { ProductBanks } from '../views/ProductBanks';
import { NewEditProductBanks } from '../views/ProductBanks/NewEditProductBanks';
import AuthGuard from '../components/AuthGuard/AuthGuard';
import { ChangePassword } from '../views/ChangePassword';
import { ChangePasswordUser } from '../views/ChangePasswordUser/ChangePasswordUser';
import { ChangePasswordEmail } from '../views/ChangePasswordEmail/ChangePasswordEmail';
import { Users } from '../views/Users/Users';
import { NewEditUser } from '../views/Users/NewEditUser';
import { Profile } from '../views/Users/Profile';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/FullLayout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/BlankLayout/BlankLayout'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/Authentication/Error'));

/* ****Pages***** */
const Dashboard = lazy(() => import('../views/Dashboard/Dashboard'));
/* ****Routes***** */

export interface Route {
  path: string;
  element: React.ReactNode;
  children: Array<Route>;
}

const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboards/starter" /> },
      { path: '/dashboards/starter', exact: true, element: <Dashboard /> },
      { path: PATHS.STEPS_PATH, exact: true, element: <Steps /> },
      { path: `${PATHS.STEPS_PATH}${PATHS.NEW}`, exact: true, element: <NewEditStep /> },
      { path: `${PATHS.STEPS_PATH}${PATHS.EDIT}/:id`, exact: true, element: <NewEditStep /> },
      { path: `${PATHS.BANKS_PATH}`, exact: true, element: <Banks /> },
      { path: `${PATHS.BANKS_PATH}${PATHS.NEW}`, exact: true, element: <NewEditBank /> },
      { path: `${PATHS.BANKS_PATH}${PATHS.EDIT}/:id`, exact: true, element: <NewEditBank /> },
      { path: `${PATHS.INPUTS_PATH}`, exact: true, element: <Inputs /> },
      { path: `${PATHS.INPUTS_PATH}${PATHS.NEW}`, exact: true, element: <NewEditInputs /> },
      { path: `${PATHS.INPUTS_PATH}${PATHS.EDIT}/:id`, exact: true, element: <NewEditInputs /> },
      { path: `${PATHS.GALLERY}`, exact: true, element: <Gallery /> },
      { path: `${PATHS.PRODUCT_BANK}`, exact: true, element: <ProductBanks /> },
      { path: `${PATHS.PRODUCT_BANK}${PATHS.NEW}`, exact: true, element: <NewEditProductBanks /> },
      {
        path: `${PATHS.PRODUCT_BANK}${PATHS.EDIT}/:id`,
        exact: true,
        element: <NewEditProductBanks />,
      },
      { path: `${PATHS.GALLERY}`, exact: true, element: <Gallery /> },
      { path: `${PATHS.CHANGE_PASSWORD}`, exact: true, element: <ChangePassword /> },
      { path: `${PATHS.CHANGE_PASSWORD_USER}`, exact: true, element: <ChangePasswordUser /> },
      { path: `${PATHS.CHANGE_PASSWORD_EMAIL}`, exact: true, element: <ChangePasswordEmail /> },
      { path: `${PATHS.USERS}${PATHS.NEW}`, exact: true, element: <NewEditUser /> },
      { path: `${PATHS.USERS}${PATHS.EDIT}/:id`, exact: true, element: <NewEditUser /> },
      { path: `${PATHS.USERS}`, exact: true, element: <Users /> },
      { path: `${PATHS.PROFILE}`, exact: true, element: <Profile /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: `${PATHS.CHANGE_PASSWORD}`, exact: true, element: <ChangePassword /> },
      { path: `${PATHS.CHANGE_PASSWORD_USER}`, exact: true, element: <ChangePasswordUser /> },
      { path: `${PATHS.CHANGE_PASSWORD_EMAIL}`, exact: true, element: <ChangePasswordEmail /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;

/*
postApi({
                                        url: `${server}${REQUEST_PASSWORD_CHANGE}`,
                                        callAfterSuccess: () => toastSuccess(t('common:newPasswordEmailSent')),
                                        callAfterError: () => toastError(t('common:cannotSendNewPasswordRequest')),
                                    })
 */
