import { useTranslation } from 'react-i18next';
import React from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { ChangePasswordForm } from '../../forms/ChangePassword';
import { cFieldNames } from '../../forms/ChangePassword/schema';
import { useResetPasswordUser } from '../../hooks/query/password/useResetPasswordUser';

export const ChangePasswordUser = () => {
  const { t } = useTranslation();
  const resetPasswordMutation = useResetPasswordUser();
  const submit = async (values, helpers) => {
    helpers.setStatus();
    resetPasswordMutation
      .mutateAsync({
        password: values[cFieldNames.password],
      })
      .then(() => {
        helpers.resetForm();
        helpers.setStatus({
          type: 'success',
          content: 'Password has been changed',
        });
      })
      .catch(() => {
        helpers.setStatus({
          type: 'error',
          content: 'Cannot change password!',
        });
      });
  };

  return (
    <PageContainer title="Změnit heslo">
      <Container maxWidth={'xs'}>
        <Box>
          <Card>
            <CardContent>
              <Grid container spacing={2} direction={'column'}>
                <Grid item textAlign={'center'}>
                  <Typography variant="h2" fontWeight="700">
                    {t('common:changePassword')}
                  </Typography>
                </Grid>
                <Grid item>
                  <ChangePasswordForm loading={resetPasswordMutation.isLoading} onSubmit={submit} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </PageContainer>
  );
};
