import errors from '../../constant/errors';
import * as yup from 'yup';

export const cFieldNames = {
  name: 'name',
  imageId: 'imageId',
  html: 'html',
  url: 'url',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.name]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
});
