import { Draggable } from 'react-beautiful-dnd';
import { HandleBar } from '../StepBuilder/HandleBar';
import React from 'react';
import { Item } from '../StepBuilder/DraggableListItem';
import { InputDraggableItemWithExportLabelAndInput } from './InputDraggableItemWithExportLabelAndInput';
import { ProductBankInput } from '../../types/productBankInput.types';

interface Props {
  item: ProductBankInput;
  index: number;
  onDelete: (id: number) => void;
  onButtonValueSaveClick?: (
    value: string,
    min: string,
    createValue: string,
    inputId: number,
  ) => void;
}

export const ProductBankInputDraggableListItem = ({
  item,
  index,
  onButtonValueSaveClick,
  onDelete,
}: Props) => {
  return (
    <Draggable key={item.input.id} draggableId={item.input.id.toString()} index={index}>
      {(provided, snapshot) => (
        <>
          <Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            style={provided.draggableProps.style}
          >
            <InputDraggableItemWithExportLabelAndInput
              deleteAllowed
              handle={<HandleBar {...provided.dragHandleProps} />}
              input={item.input}
              productBankInput={item}
              onButtonValueSaveClick={onButtonValueSaveClick}
              onDelete={onDelete}
            />
          </Item>
        </>
      )}
    </Draggable>
  );
};
