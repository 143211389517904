import React from 'react';
import { Link } from 'react-router-dom';
import LogoSrovnejSiTo from '../../../assets/images/logos/srovnejsito.png';
import { styled } from '@mui/material/styles';

const Centered = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LogoIcon = () => {
  return (
    <Centered>
      <Link to="/">
        <img src={LogoSrovnejSiTo} width={150} alt={'lgoo'} />
      </Link>
    </Centered>
  );
};

export default LogoIcon;
