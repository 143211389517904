import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  backNavigation?: string;
  onBackClick?: () => void;
  onSubmitClick?: () => void;
  loading?: boolean;
}

export const SubmitAndBack = ({ backNavigation, onBackClick, loading, onSubmitClick }: Props) => {
  const navigation = useNavigate();
  const { t } = useTranslation('common');
  const onClickBack = () => {
    if (backNavigation) {
      navigation(backNavigation);
    } else {
      onBackClick && onBackClick();
    }
  };

  const onsubmitClick = () => {
    if (onSubmitClick) {
      onSubmitClick && onSubmitClick();
    }
  };
  return (
    <Grid item container spacing={1}>
      <Grid item>
        <Button color="primary" variant={'outlined'} onClick={onClickBack}>
          {t('cancel')}
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          loading={loading}
          color="primary"
          type={onSubmitClick ? 'button' : 'submit'}
          variant={'contained'}
          onClick={onsubmitClick}
        >
          {t('save')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
