import {
  PaginationSearchQueryConfigMap,
  PaginationSearchQueryParams,
  PaginationSearchQueryParamsInitType,
  useGenericQueryParams,
  UseQueryWithParams,
} from './useGenericQueryParams';
import {useGetAuthFetch, useGetFetch} from '../reactQuery';
import { IPagination } from '../../useApiPagination';
import { mergeLeft } from 'ramda';
import { AUTH_USERS_API } from '../../../constant/api_url';

export function usePaginationQueryParams<T>(
  api: string,
  init?: PaginationSearchQueryParamsInitType,
): UseQueryWithParams<IPagination<T>, PaginationSearchQueryParams> {
  const [params, setQueryParam] = useGenericQueryParams<PaginationSearchQueryParams>(
    init ? mergeLeft(init, PaginationSearchQueryConfigMap) : PaginationSearchQueryConfigMap,
  );

  //const getApiCall = useGetFetch<IPagination<T>>(api, params, { keepPreviousData: false });
  //const postApiCall = usePostFetch<IPagination<T>>(api, params, { keepPreviousData: false });

  const apiCall = api !== AUTH_USERS_API ? useGetFetch<IPagination<T>>(api, params, { keepPreviousData: false }) : useGetAuthFetch<IPagination<T>>(api, params, { keepPreviousData: false });


  return {
    apiCall: apiCall,
    params: params,
    setQueryParam,
  };
}
