import { Droppable } from 'react-beautiful-dnd';
import { Box, Paper } from '@mui/material';
import { DraggableToolbarItem } from '../StepBuilder/DraggableToolbarItem';
import React from 'react';
import { Notice } from '../StepBuilder/Droppable';
import { Input } from '../../types/input.types';
import { DroppableZones } from '../../context/ProductBankDataContext/DroppableZones';
import { InputDraggableItemWithExportLabelAndInput } from './InputDraggableItemWithExportLabelAndInput';

interface Props {
  items: Input[];
  droppableId: DroppableZones;
}

export const ProductBankInputToolbar = ({ items, droppableId }: Props) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Paper variant={'outlined'} ref={provided.innerRef}>
          <Box
            sx={{
              pt: 1,
              pb: 1,
              m: 2,
            }}
          >
            {}
            {items
              ? items.map((input, index) => (
                  <DraggableToolbarItem
                    key={input.id}
                    item={input}
                    content={<InputDraggableItemWithExportLabelAndInput hideInput input={input} />}
                    index={index}
                  />
                ))
              : !provided.placeholder && <Notice>Drop items here</Notice>}
            {provided.placeholder}
          </Box>
        </Paper>
      )}
    </Droppable>
  );
};
