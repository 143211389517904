export const SLICE = {
  CATEGORY_SLICE: "category",
  CUSTOMIZER: "customizer",
  PRODUCTS: "products",
  PRODUCT: "product",
  SHIPPING: "shipping",
  SHIPPING_VARIANTS: "shippingVariants",
  TAXES_SLICE: "taxes",
  MODALS_SLICE: "ModalReducer",
};
