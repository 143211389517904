import { cFieldNames, validationSchema } from './schema';
import { InputChoice, NewInputChoice } from '../../../types/input.types';
import { Grid } from '@mui/material';
import { CustomForm } from '../../../components/Forms/Formik/Form';
import { TextField } from '../../../components/Forms/Formik/Fields/TextField';
import { SubmitAndBack } from '../../../components/Forms/Submit/SubmitAndBack';
import { prop } from 'ramda';
import { ImageSelectField } from '../../../components/Forms/Formik/Fields/ImageSelectField';
import { Form } from '../../../components/Forms/Formik/forms.types';

interface Props extends Form<InputChoice> {
  onClose?: () => void;
}

export const InputChoiceForm = ({ data, onSubmit, onClose, loading }: Props) => {
  return (
    <>
      {data && (
        <CustomForm
          data={data}
          initValues={NewInputChoice}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formName={'INPUT_CHOICE'}
        >
          {({ handleSubmit, values }) => (
            <Grid container spacing={1}>
              <TextField name={cFieldNames.value} />
              <TextField name={cFieldNames.description} />
              <TextField name={cFieldNames.tooltip} />
              <ImageSelectField imageDefault={prop('image', values)} name={cFieldNames.imageId} />
              <SubmitAndBack
                loading={loading}
                onSubmitClick={() => handleSubmit()}
                onBackClick={onClose}
              />
            </Grid>
          )}
        </CustomForm>
      )}
    </>
  );
};
