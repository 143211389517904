import { DropArea } from 'react-upload-gallery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  openDialogue: () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  openGallery?: () => {};
  hideGallerySelect?: boolean;
}

export const DragADropHeaderArea = ({ openDialogue, openGallery, hideGallerySelect }: Props) => {
  const { t } = useTranslation('forms');
  const formName = 'DRAG_DROP_HEADER';
  return (
    <DropArea>
      {(isDrag) => (
        <div>
          <div className={`rug-handle ${isDrag && '__dragging'}`}>
            <svg viewBox="0 -5 32 52" className="rug-handle-icon">
              <g>
                <polyline points="1 19 1 31 31 31 31 19" />
                <polyline className="__arrow" points="8 9 16 1 24 9" />
                <line className="__arrow" x1="16" x2="16" y1="1" y2="25" />
              </g>
            </svg>
            <div className="rug-handle-info">
              <div className="rug-handle-drop-text">
                <span>
                  {t(`${formName}.title`)}{' '}
                  <Button color={'primary'} variant={'outlined'} onClick={openDialogue}>
                    {t(`${formName}.select`)}
                  </Button>
                </span>
              </div>

              {!hideGallerySelect && (
                <>
                  <span>nebo</span>
                  <Button color={'primary'} onClick={openGallery}>
                    Vyberte z galerie
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </DropArea>
  );
};
