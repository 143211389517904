import React, { useContext } from 'react';
import { equals, isEmpty, isNil, propOr } from 'ramda';
import { isFunction } from 'formik';
import { MenuItem } from '@mui/material';
import CustomFormLabel from '../CustomElements/CustomFormLabel';
import { FormikFieldTypes } from './types';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';
import CustomOutlinedTextField from '../CustomElements/CustomOutlinedTextField';
import { useFormikFieldError } from '../../../hooks/useFormikFieldError';
import { FormikFieldError } from './FormikFieldError';

export interface SelectMenuItem {
  id: any;
  defaultValue?: string | number;
  name: any;
}

export interface InputProps {
  noLabel?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (e) => void;
  onBlur?: (e) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  name: string;
  disableFormikSetFieldValue?: boolean;
  multiline?: boolean;
  maxRows?: number;
  rows?: number;
  size?: InputSizesProps;
  type?: InputTypesProps;
}

export type TextFormikFieldProps = InputProps;

export interface SelectFormikFieldProps extends InputProps {
  options: Array<SelectMenuItem>;
}
export interface InputTypeProps {
  type?: 'text' | 'number' | 'select';
}

export type InputSizesProps = 'small' | 'medium' | undefined;
export type InputTypesProps = 'text' | 'number' | 'select' | undefined;

export interface Props
  extends InputProps,
    InputTypeProps,
    SelectFormikFieldProps,
    FormikFieldTypes {}
export const OutlinedFormikInput = ({
  name,
  onChange,
  onBlur,
  disabled,
  fullWidth,
  field,
  form,
  disableFormikSetFieldValue,
  type = 'text',
  options,
  multiline,
  label,
  maxRows,
  rows,
  size = 'small',
}: Props) => {
  const { label: labelTranslate, placeholder } = useContext(FormTranslationContext);
  const [error, errorText] = useFormikFieldError(field.name);

  const onFieldBlur = (e) => {
    isFunction(onBlur) && onBlur(e);
    field.onBlur(e);
  };
  const onFieldChange = ({ target }) => {
    !disableFormikSetFieldValue &&
      form.setFieldValue(field.name, equals(type, 'number') ? Number(target.value) : target.value);
    isFunction(onChange) && onChange(target.value);
  };

  return (
    <>
      {label && <CustomFormLabel htmlFor={name}>{label}</CustomFormLabel>}
      {!label && !isEmpty(labelTranslate(field.name)) && (
        <CustomFormLabel htmlFor={name}>{labelTranslate(field.name)}</CustomFormLabel>
      )}
      <CustomOutlinedTextField
        {...field}
        id={name}
        placeholder={placeholder(field.name)}
        variant="outlined"
        fullWidth={fullWidth ?? true}
        disabled={disabled}
        size={size}
        type={type}
        multiline={multiline}
        maxRows={maxRows}
        rows={rows}
        value={propOr('', 'value', field)}
        error={error}
        // helperText={translatedErrorText}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        // InputProps={{}}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) =>
              `${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#dee3e9'}`,
            '& input:invalid + fieldset': {
              borderColor: 'rgb(252, 75, 108)!important',
            },
          },
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              defaultValue={option.defaultValue}
              value={!isNil(option.id) ? option.id : ''}
              key={option.id}
            >
              {option.name}
            </MenuItem>
          ))}
      </CustomOutlinedTextField>
      <FormikFieldError error={error} text={errorText} />
    </>
  );
};
