import { IconButton, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import React, { MouseEvent, useState } from 'react';
import Popover from '@mui/material/Popover';
import { InputProductBankAuditLog } from '../../types/productBankAuditLog.types';
import { syntaxHighlight } from '../../utils/syntaxHighlight';
import './style.css';

interface Props {
  item: InputProductBankAuditLog;
}

export const SyntaxHighlightTableItem: React.FC<Props> = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick} size={'small'}>
        <Visibility fontSize={'small'} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant={'caption'} sx={{ p: 2 }}>
          <pre
            dangerouslySetInnerHTML={{
              __html: syntaxHighlight(JSON.stringify(item, undefined, 4)),
            }}
          />
        </Typography>
      </Popover>
    </div>
  );
};
