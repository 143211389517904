import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { NavButton } from '../../components/NavButton/NavButton';
import { PATHS } from '../../routes/paths';
import { Card, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { UsersTable } from '../../tables/UsersTable';
import { usePaginationQueryParamsPost } from '../../hooks/query/generic/useGenericPaginationQueryParamsPost';
import { UserProfile, Users as UsersType } from '../../types/users.types';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { fromAuthPaginationToIPagination } from '../../utils/apiUtils';
import { useDeleteUserMutation } from '../../hooks/query/users/useDeleteUserMutation';
import { useUsersFetch } from '../../hooks/query/users/useUsers';
import { AUTH_USERS_API } from '../../constant/api_url';

export const Users = () => {
  const { t } = useTranslation('pages');
  const {
    apiCall: { data, isFetching, isLoading, isError },
    setQueryParam,
  } = useUsersFetch();

  const deleteMutation = useDeleteUserMutation();

  const onDelete = (item: UserProfile) => {
    deleteMutation.mutateAsync(item.userId);
  };

  return (
    <PageContainer>
      <Breadcrumb title={t('USERS')} items={[{ title: 'USERS' }]}>
        <NavButton title={t('ADD_BUTTON')} to={`${PATHS.USERS}${PATHS.NEW}`} icon={'plus'} />
      </Breadcrumb>
      <Card>
        <CardContent>
          <LoadingErrorContainer isLoading={isLoading} isError={isError}>
            {data && (
              <UsersTable
                onDelete={onDelete}
                data={data['items']}
                pagination={
                  <StyledPagination
                    data={fromAuthPaginationToIPagination(data['pagination'])}
                    setQueryParam={setQueryParam}
                  />
                }
              />
            )}
          </LoadingErrorContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
