import { FieldArray } from 'formik';
import { cFieldNames } from '../schema';
import { InputChoice } from '../../../types/input.types';
import { useModal } from '../../../hooks/useModal';
import modals from '../../../constant/modals';
import { NewInputChoiceModal } from '../../../components/Modal/NewInputChoice';
import { PlusCircle } from 'react-feather';
import { Box, CardContent, Grid, IconButton } from '@mui/material';
import { FormBoxDivider } from '../../../components/FormboxDivider';
import React, { useContext } from 'react';
import { FormTranslationContext } from '../../../context/FormTranlsationContext';
import { ReactTable } from '../../../components/Table/reaact-table/ReactTable';
import { Column } from 'react-table';
import { path } from 'ramda';
import TableAvatar from '../../../components/TableAvatar';

interface Props {
  id?: number;
  enable?: boolean;
  values: Array<InputChoice>;
  handleRemove: (item: InputChoice, index: number, remove: (index: number) => void) => void;
  addReceiverWithModalOrNot: () => void;
}

export const InputChoicesFieldArray = ({
  id,
  enable,
  values,
  handleRemove,
  addReceiverWithModalOrNot,
}: Props) => {
  const { setContent, open } = useModal(modals.NEW_INPUT_CHOICE);
  const { t } = useContext(FormTranslationContext);
  const columns: Column<InputChoice>[] = React.useMemo(
    () => [
      {
        Header: 'Hodnota',
        accessor: 'value',
      },
      {
        Header: 'Popisek',
        accessor: 'description',
      },
      {
        Header: 'Nápověda',
        accessor: 'tooltip',
      },
      {
        Header: 'Obrázek',
        Cell: (props) => {
          if (props.row.original['imageId'] != null && props.row.original['image'] == null) {
            return <TableAvatar url={`/f/${props.row.original['imageId']}`} />;
          }
          if (props.row.original['image'] != null) {
            return <TableAvatar url={path(['image', 'url'], props.row.original)} />;
          }
          return '';
        },
      },
    ],
    [],
  );
  return enable ? (
    <>
      <FormBoxDivider icon={'alert-circle'} text={t('choicesInfo')} />
      <Box px={1}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FieldArray name={cFieldNames.choices}>
                {({ replace, remove, push }) => (
                  <>
                    <ReactTable
                      columns={columns}
                      data={values}
                      handleEditItem={(val, index) => {
                        setContent({
                          value: val,
                          index,
                        });
                        open();
                      }}
                      handleDeleteItem={(val, index) => handleRemove(val, index, remove)}
                    />

                    <Grid container justifyContent={'center'}>
                      <Grid item>
                        <IconButton onClick={addReceiverWithModalOrNot}>
                          <PlusCircle />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <NewInputChoiceModal
                      id={id}
                      onAdd={push}
                      onUpdate={(val, index) => replace(index, val)}
                    />
                  </>
                )}
              </FieldArray>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </>
  ) : (
    <></>
  );
};
