import { IPagination } from '../../useApiPagination';
import { INPUT_API } from '../../../constant/api_url';
import { PaginationSearchQueryParams, UseQueryWithParams } from '../generic/useGenericQueryParams';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { Input } from '../../../types/input.types';
import { useGetFetch } from '../reactQuery';

export const useInputsFetch = (): UseQueryWithParams<
  IPagination<Input>,
  PaginationSearchQueryParams
> => {
  return usePaginationQueryParams<Input>(INPUT_API);
};

export const useInputsAll = () => useGetFetch<IPagination<Input>>(INPUT_API);
