export enum ACTIONS {
  LOAD_DATA = 'LOAD_DATA',
  LOAD_INPUTS = 'LOAD_INPUTS',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
  REORDER = 'REORDER',
  SET_DATA = 'SET_DATA',
  TOGGLE_REQUIRED = 'TOGGLE_REQUIRED',
  UPDATE_PRODUCT_BANK_INPUT_VALUE = 'UPDATE_PRODUCT_BANK_INPUT_VALUE',
  UPDATE_PRODUCT_BANK_INPUT_MIN = 'UPDATE_PRODUCT_BANK_INPUT_MIN',
  UPDATE_PRODUCT_BANK_INPUT_CREATE_VALUE = 'UPDATE_PRODUCT_BANK_CREATE_VALUE',
  UPDATE_PRODUCT_BANK_INPUT_TYPE = 'UPDATE_PRODUCT_BANK_INPUT_TYPE',
  REPLACE_PRODUCT_BANK_INPUT_BY_INPUT = 'REPLACE_PRODUCT_BANK_INPUT_BY_INPUT',
}
