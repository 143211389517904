import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { toast } from 'react-hot-toast';

interface DeleteInputChoiceVariable {
  id: number;
}

export const useDeleteInputChoice = () => {
  return useMutation<AxiosResponse, AxiosError, DeleteInputChoiceVariable>(
    (data: DeleteInputChoiceVariable) => axios.delete(`/v1/input/choice/${data.id}`),
    {
      onError: (err, _) => {
        toast.error('Nepodařilo se vymazat výběr');
      },
      onSuccess: () => {
        toast.success('Vymazáno');
      },
      onSettled: () => {},
    },
  );
};
