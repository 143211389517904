import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { marginInputStyles } from './MarginINputWrapper';

interface ITypography {
  htmlFor?: string;
}
export const CustomFormLabel = styled((props: ITypography) => (
  <Typography variant="h6" {...props} component="label" htmlFor={props.htmlFor} />
))(() => marginInputStyles);

export default CustomFormLabel;
