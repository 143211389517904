import { Form } from '../../components/Forms/Formik/forms.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { cFieldNames, validationSchema } from './schema';
import { Grid } from '@mui/material';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import React from 'react';
import { Bank, NewBank } from '../../types/bank.types';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { ImageSelectField } from '../../components/Forms/Formik/Fields/ImageSelectField';
import { prop } from 'ramda';

export const BanksForm = ({ data, onSubmit, loading }: Form<Bank>) => {
  return (
    <>
      {data && (
        <CustomForm
          data={data}
          initValues={NewBank}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formName={'BANK_FORM'}
        >
          {({ values }) => (
            <Grid container spacing={1} direction={'column'}>
              <TextField name={cFieldNames.name} />
              <TextField name={cFieldNames.html} multiline rows={4} maxRows={5} />
              <TextField name={cFieldNames.url} />
              <ImageSelectField imageDefault={prop('image', values)} name={cFieldNames.imageId} />
              <SubmitButton loading={loading} />
            </Grid>
          )}
        </CustomForm>
      )}
    </>
  );
};
