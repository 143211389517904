import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  isModalOpen,
  modalPayload,
  openModal,
  setModalContent,
} from '../redux/modals.reducer';

type State = {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  setContent: (content: any) => void;
  payload: any;
};
export const useModal = (modalId: number): State => {
  const isOpen = useSelector(isModalOpen(modalId));
  const payload = useSelector(modalPayload(modalId));
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeModal(modalId));
  };
  const open = () => {
    dispatch(openModal(modalId));
  };
  const setContent = (content) => {
    dispatch(
      setModalContent({
        id: modalId,
        content,
      }),
    );
  };

  return { isOpen, close, open, setContent, payload } as State;
};
