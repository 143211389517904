import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { STEP_API } from '../../../constant/api_url';

interface UseInputChangeOrderVariables {
  id: any;
  data: any[];
}

export const useInputChangeOrder = () => {
  const { t } = useTranslation();
  return useMutation<
    AxiosResponse<UseInputChangeOrderVariables>,
    AxiosError,
    UseInputChangeOrderVariables
  >(({ id, data }) => axios.post(`${STEP_API}/${id}/input-order`, data));
};
