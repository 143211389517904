import { prop } from 'ramda';
import { server, serverCore } from '../config';
import { axios } from '../utils/apiUtils';

export const customRequest = (addItem) => ({
                                               uid,
                                               file,
                                               action,
                                               onProgress,
                                               onSuccess,
                                               onError,
}) => {
    const form = new FormData();
    form.append('file', file);

   // const source = CancelToken.source();

    axios
        .post(`${server}/file`, form, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            },
            onUploadProgress: ({ total, loaded }) => {
                onProgress(uid, Math.round((loaded / total) * 100));
            },
           // cancelToken: source.token,
        })
        .then(({ data: response }) => {
            const parseRespone = {
                //url: `${server}${view(lensPath([0, 'url']), response)}`,
                url: `${serverCore}${prop('url', response)}`,
                source: `${serverCore}${prop('url', response)}`,
                id: prop("id", response),
            };
            onSuccess(uid, parseRespone);
            addItem(response);
        })
        .catch((error) => {
            onError(uid, {
                action,
                status: error.request,
                response: error.response,
            });
        });

    return {
        abort() {
            source.cancel();
        },
    };
};
