import React from 'react';
import { TableProps } from './table.props';
import { Step } from '../types/step.types';
import { PATHS } from '../routes/paths';
import { Column } from 'react-table';
import TableAvatar from '../components/TableAvatar';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';

export const StepsTable = ({ onDelete, data, pagination, onSortEnd }: TableProps<Step>) => {
  const columns: Column<Step>[] = React.useMemo(
    () => [
      {
        Header: 'Ikona',
        Cell: (props) => <TableAvatar url={props.cell.row.original.icon?.url} />,
        width: '3%',
      },
      {
        Header: 'Název',
        accessor: 'name',
        width: '80%',
      },
      {
        Header: 'Povinný',
        Cell: (props) => (
          <div className={'d-flex justify-content-start align-items-center'}>
            <input
              className="m-0 checkbox checkbox-primary form-check-input "
              type={'checkbox'}
              checked={props.row.original.required}
              disabled={true}
            />
          </div>
        ),
        width: '10%',
      },
    ],
    [],
  );
  return (
    <>
      {data && (
        <ReactTable
          columns={columns}
          handleDeleteItem={onDelete}
          data={data}
          link={`${PATHS.STEPS_PATH}${PATHS.EDIT}`}
          afterTableContent={pagination}
          sortable
          onSortEnd={onSortEnd}
        />
      )}
    </>
  );
};
