import React from 'react';

export const marginInputStyles = {
  // marginBottom: '5px',
  // marginTop: '10px',
  // display: 'block',
};
export const MarginInputWrapper: React.FC = ({ children }) => {
  return <div style={marginInputStyles}>{children}</div>;
};
