import { Form } from '../../components/Forms/Formik/forms.types';
import { ProfileUpdate } from '../../types/users.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { Grid } from '@mui/material';
import { cFieldNames, initialValues, validationSchema } from './schema';
import React from 'react';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';

interface Props extends Form<ProfileUpdate> {}

export const ProfileForm: React.FC<Props> = ({ onSubmit, loading, data }) => {
  return (
    <>
      {data && (
        <CustomForm
          data={data}
          initValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formName={'PROFILE_FORM'}
        >
          {({ t }) => (
            <Grid container direction={'column'} spacing={1}>
              <TextField name={cFieldNames.profile} label={t('profileLabel')} />
              <TextField name={cFieldNames.email} />
              <SubmitButton loading={loading} />
            </Grid>
          )}
        </CustomForm>
      )}
    </>
  );
};
