import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { STEP_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

interface UseDeleteConnectionStepAndInput {
  id: any;
  inputId: any;
}

export const useDeleteConnectionStepAndInput = () => {
  return useMutation<
    AxiosResponse<UseDeleteConnectionStepAndInput>,
    AxiosError,
    UseDeleteConnectionStepAndInput
  >(({ id, inputId }) => axios.delete(`${STEP_API}/${id}/input/${inputId}`), {
    onSuccess: () => {
      toast.success('Spojení kroku a vstupu bylo odebráno');
    },
    onError: () => {
      toast.error('Spojení kroku a vstupu se nepodařilo odebrat');
    },
  });
};
