import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { equals, identity } from 'ramda';

export interface TranslateFormContext {
  label: (field, options?: any) => string;
  tooltip: (field, options?: any) => string;
  placeholder: (field, options?: any) => string;
  append: (field, options?: any) => string;
  prefix: (field, options?: any) => string;
  t: (text) => string;
  tError: (text, options?: any) => string;
  formName: string;
}

export const defaultTranslationFormContext = {
  label: identity,
  tooltip: identity,
  placeholder: identity,
  append: identity,
  prefix: identity,
  t: identity,
  tError: identity,
  formName: '',
};
export const useFormTranslate = (form: string) => {
  const { t } = useTranslation('forms');
  const { t: tError } = useTranslation('errors');
  return {
    label: useCallback((field, options = {}) => {
      return t(`${form}.${field}Label`, options);
    }, []),
    tooltip: useCallback((field, options = {}) => t(`${form}.${field}Tooltip`, options), []),
    placeholder: useCallback((field, options = {}) => {
      const translate = t(`${form}.${field}Placeholder`, options);
      if (equals(`${form}.${field}Placeholder`, translate)) {
        return '';
      }
      return translate;
    }, []),
    append: useCallback((field, options = {}) => t(`${form}.${field}Append`, options), []),
    prefix: useCallback((field, options = {}) => t(`${form}.${field}Prefix`, options), []),
    tError: useCallback((error, options = {}) => tError(error, options), []),
    t: useCallback((field, options = {}) => t(`${form}.${field}`, options), []),
    formName: form,
  } as TranslateFormContext;
};
