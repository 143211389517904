import axiosRaw from 'axios';
import {authorizatorURI, server} from '../config';
import {AuthPagination} from '../types/users.types';
import {IPagination} from '../hooks/useApiPagination';

export const axios = axiosRaw.create({
  baseURL: `${server}`,
  headers: {},
});

export const axiosAuth = axiosRaw.create({
  baseURL: `${authorizatorURI}`,
  headers: {},
});

export default axios;

export const fromAuthPaginationToIPagination = (pagination: AuthPagination): IPagination<any> => ({
  numberOfElements: pagination.total,
  totalNumberOfElements: pagination.total,
  pageNumber: pagination.page,
  hasNextPage: ((pagination.page + 1) * pagination.perPage) < pagination.total,
  elementsPerPage: pagination.perPage,
  totalPages: Math.ceil(pagination.total / pagination.perPage),
  elements: [],
})

