import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Step } from '../../../types/step.types';
import { STEP_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import axios from '../../../utils/apiUtils';

interface UseCreateStepDependencyMutationVariables {
  stepId: any;
  choiceId: any;
}

export const useCreateStepDependencyMutation = () => {
  return useMutation<AxiosResponse<Step>, AxiosError, UseCreateStepDependencyMutationVariables>(
    ({ stepId, choiceId }: UseCreateStepDependencyMutationVariables) =>
      axios.post<Step>(`${STEP_API}/${stepId}/dependency/${choiceId}`),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit spojení mezi krokem výběrem');
      },
      onSuccess: () => {
        toast.success('Závislost vytvořena');
      },
    },
  );
};

interface UseCreateStepDependencyMutationVariables {
  stepId: any;
  choiceId: any;
}

export const useDeleteStepDependencyMutation = () => {
  return useMutation<AxiosResponse<Step>, AxiosError, UseCreateStepDependencyMutationVariables>(
    ({ stepId, choiceId }: UseCreateStepDependencyMutationVariables) =>
      axios.delete<Step>(`${STEP_API}/${stepId}/dependency/${choiceId}`),
    {
      onError: () => {
        toast.error('Nepodařilo se odstranit spojení mezi krokem výběrem');
      },
      onSuccess: () => {
        toast.success('Závislost odstraněna');
      },
    },
  );
};
