import React, { useState } from 'react';
import DeleteConfirmModal from '../Modal/DeleteConfirmModal';
import { IconButton } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { prop, propOr } from 'ramda';
import { isFunction } from 'formik';

export interface IDeleteOrUpdate<T> {
  link?: string | ((item: T) => string);
  row?: T;
  deleteFunc?: () => void;
  deleteLoading?: boolean;
  after?: any;
  onClickUpdate?: () => void;
  hideUpdate?: boolean;
  hideDelete?: boolean;
  isUpdateFunctionExists?: boolean;
}
const DeleteOrUpdate = <T extends unknown>({
  row,
  deleteFunc,
  onClickUpdate,
  hideUpdate,
  link,
  hideDelete,
  isUpdateFunctionExists,
}: IDeleteOrUpdate<T>) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const closeModal = () => {
    setConfirmOpen(false);
  };
  const openModal = () => {
    setConfirmOpen(true);
  };
  const onConfirm = () => {
    closeModal();
    if (deleteFunc) {
      deleteFunc();
    }
  };
  const showUpdate = () => {
    if (isUpdateFunctionExists) {
      if (!hideUpdate) {
        return (
          <IconButton size={'small'} onClick={onClickUpdate}>
            <EditOutlined fontSize={'small'} />
          </IconButton>
        );
      }
    } else {
      if (!hideUpdate && row) {
        return (
          <Link to={isFunction(link) ? link(row) : `${link}/${propOr('', 'id', row)}`}>
            <IconButton size={'small'}>
              <EditOutlined fontSize={'small'} />
            </IconButton>
          </Link>
        );
      }
    }
  };
  console.log(isUpdateFunctionExists, row);
  return (
    <div className={'d-flex justify-content-end'}>
      <DeleteConfirmModal open={confirmOpen} onConfirm={onConfirm} close={closeModal} />
      {!hideDelete && (
        <IconButton size={'small'} onClick={openModal}>
          <DeleteOutline color={'error'} fontSize={'small'} />
        </IconButton>
      )}

      {showUpdate()}
    </div>
  );
};

export default DeleteOrUpdate;
