import { cond, equals } from 'ramda';
import { DragAndDropHelpers } from '../../components/StepBuilder/DragAndDropHelpers';
import { ReducerState } from './StepInputDataProvider';
import { ACTIONS } from './actions';

export const reducer = (state: ReducerState, action) =>
  cond([
    [
      equals(ACTIONS.LOAD_DATA),
      () => {
        return {
          ...state,
          // get only
          data: action.payload,
        };
      },
    ],
    [
      equals(ACTIONS.LOAD_INPUTS),
      () => {
        const newInputs = action.payload;
        return {
          ...state,
          // get only
          inputs: newInputs,
        };
      },
    ],
    [
      equals(ACTIONS.MOVE),
      () => {
        const [inputs, data] = DragAndDropHelpers.move(
          state.inputs,
          state.data,
          action.droppableSource,
          action.droppableDestination,
        );
        return {
          inputs,
          data,
        };
      },
    ],
    [
      equals(ACTIONS.DELETE),
      () => {
        const [data, inputs] = DragAndDropHelpers.move(
          state.data,
          state.inputs,
          action.droppableSource,
          action.droppableDestination,
        );
        return {
          inputs,
          data,
        };
      },
    ],
    [
      equals(ACTIONS.REORDER),
      () => {
        const newArray = DragAndDropHelpers.reorder(state.data, action.oldIndex, action.newIndex);
        return {
          ...state,
          data: newArray,
        };
      },
    ],
    [
      equals(ACTIONS.SET_DATA),
      () => {
        return {
          ...state,
          data: action.payload,
        };
      },
    ],
    [
      equals(ACTIONS.TOGGLE_REQUIRED),
      () => {
        const arrayWithUpdatedItem = state.data.map((i) =>
          equals(i.id, action.payload) ? { ...i, required: !i.required } : i,
        );
        return {
          ...state,
          data: arrayWithUpdatedItem,
        };
      },
    ],
    // @ts-ignore
  ])(action.type);
