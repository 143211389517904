import PageContainer from '../../components/PageContainer/PageContainer';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import {NavButton} from '../../components/NavButton/NavButton';
import {PATHS} from '../../routes/paths';
import {Box, Card, CardContent, LinearProgress} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LoadingErrorContainer} from '../../components/LoadingErrorContainer';
import {BanksTable} from '../../tables';
import {useBanksFetch} from '../../hooks/query/banks/useBanks';
import {StyledPagination} from '../../components/Pagination/PaginationStyled';
import {useBankDeleteMutation} from '../../hooks/query/banks/useBankDeleteMutation';
import {SearchOutlinedField} from '../../components/Forms/CustomElements/SearchOutlinedField';
import {propOr} from 'ramda';
import {PAGE_SEARCH_QUERY} from '../../hooks/query/generic/useGenericQueryParams';
import {Bank} from '../../types/bank.types';

export const Banks = () => {
  const {t} = useTranslation('pages');

  const {
    apiCall: {data, isFetching, isLoading, isError},
    params,
    setQueryParam,
  } = useBanksFetch();

  const deleteBank = useBankDeleteMutation();

  const onDelete = async (item: Bank) => deleteBank.mutateAsync({id: item.id});

  return (
    <PageContainer title="Banky">
      <Breadcrumb title={t('BANKS')} items={[{title: 'BANKS'}]}>
        <NavButton title={t('ADD_BUTTON')} to={`${PATHS.BANKS_PATH}${PATHS.NEW}`} icon={'plus'}/>
      </Breadcrumb>
      <Card>
        <CardContent>
          <SearchOutlinedField
            value={propOr('', PAGE_SEARCH_QUERY, params)}
            onChange={(value) => setQueryParam(PAGE_SEARCH_QUERY, value)}
          />
          <Box mt={2}/>
          <LoadingErrorContainer isLoading={isLoading} isError={isError}>
            {isFetching || (deleteBank.isLoading && <LinearProgress/>)}
            {data && (
              <BanksTable
                data={data?.elements}
                pagination={<StyledPagination data={data} setQueryParam={setQueryParam}/>}
                onDelete={onDelete}
              />
            )}
          </LoadingErrorContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
