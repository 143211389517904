import { Grid, GridSize, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { isNilOrEmpty } from '../../utils/ramda_utils';

interface Props {
  leftSearchValue: string | number;
  leftSearchPlaceholder?: string;
  leftSearchOnChange: (value: string) => void;
  rightSearchValue: string | number;
  rightSearchPlaceholder?: string;
  rightSearchOnChange: (value: string) => void;
  leftXs?: GridSize;
  rightXs?: GridSize;
}

export const SearchInputs = ({
  leftXs,
  rightXs,
  leftSearchValue,
  leftSearchPlaceholder,
  leftSearchOnChange,
  rightSearchValue,
  rightSearchPlaceholder,
  rightSearchOnChange,
}: Props) => {
  return (
    <Grid container item spacing={1}>
      <Grid item xs={leftXs ?? 6}>
        <OutlinedInput
          value={leftSearchValue}
          onChange={(e) => leftSearchOnChange(e.target.value)}
          placeholder={leftSearchPlaceholder}
          size={'small'}
          startAdornment={
            <InputAdornment position="start">
              <FeatherIcon icon="search" width="20" />
            </InputAdornment>
          }
          endAdornment={
            !isNilOrEmpty(leftSearchValue) && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => leftSearchOnChange('')}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Grid>
      <Grid item xs={rightXs ?? 6}>
        <OutlinedInput
          value={rightSearchValue}
          onChange={(e) => rightSearchOnChange(e.target.value)}
          placeholder={rightSearchPlaceholder}
          size={'small'}
          startAdornment={
            <InputAdornment position="start">
              <FeatherIcon icon="search" width="20" />
            </InputAdornment>
          }
          endAdornment={
            !isNilOrEmpty(rightSearchValue) && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => rightSearchOnChange('')}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Grid>
    </Grid>
  );
};
