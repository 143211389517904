import { DragDropContext } from 'react-beautiful-dnd';
import { Grid } from '@mui/material';
import { Toolbar } from './Toolbar';
import { DroppableArea } from './Droppable';
// @ts-ignore
import ID from '../dnd/UUID';
import { LoadingErrorContainer } from '../LoadingErrorContainer';
import { useStepInputData } from '../../hooks/useStepInputData';
import { DroppableZones } from '../../context/StepInputDataContext/DroppableZones';
import { SearchInputs } from '../../context/StepInputDataContext/SearchInputs';
import { useFormTranslate } from '../../hooks/useFormTranslate';

export const StepBuilderLayout = () => {
  const {
    isError,
    isLoading,
    data,
    toggleRequired,
    onDragEnd,
    inputsSearchOnChange,
    inputsSearch,
    searchLeftInputs,
    leftInputsSearchOnChange,
  } = useStepInputData();
  useStepInputData();
  const { t } = useFormTranslate('STEP_FORM');
  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd(result);
      }}
    >
      <Grid container spacing={2}>
        <SearchInputs
          leftXs={7}
          rightXs={5}
          leftSearchValue={searchLeftInputs}
          leftSearchOnChange={(value) => leftInputsSearchOnChange(value)}
          leftSearchPlaceholder={t('startType')}
          rightSearchValue={inputsSearch}
          rightSearchOnChange={(value) => inputsSearchOnChange(value)}
          rightSearchPlaceholder={t('startType')}
        />
        <Grid container item spacing={1}>
          <Grid item xs={7}>
            <LoadingErrorContainer isLoading={isLoading} isError={isError}>
              <DroppableArea
                items={data.data}
                onRequiredClick={toggleRequired}
                droppableId={DroppableZones.CONNECTED_INPUTS}
                onDelete={onDragEnd}
              />
            </LoadingErrorContainer>
          </Grid>
          <Grid item xs={5}>
            <LoadingErrorContainer isLoading={isLoading} isError={isError}>
              <Toolbar items={data.inputs} droppableId={DroppableZones.FREE_INPUTS} />
            </LoadingErrorContainer>
          </Grid>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};
