import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material';
import { formatBytes } from '../../utils/formatBytes';
import { Delete } from '@mui/icons-material';
import React, { useState } from 'react';
import { ImageRUG } from './ImagePreview';
import { serverCore } from '../../config';
import { identity } from 'ramda';
import { File } from '../../types/files.types';
import DeleteConfirmModal from '../Modal/DeleteConfirmModal';

interface Props {
  image: File;
  onDelete?: (id: any) => void;
  smallPreview?: boolean;
  selected?: boolean;
  onClick?: (image: ImageRUG) => void;
  noConfirm?: boolean;
}
export const ImageItem = ({
  image,
  onDelete,
  noConfirm,
  smallPreview,
  selected,
  onClick,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeModal = () => {
    setConfirmOpen(false);
  };
  const openModal = () => {
    setConfirmOpen(true);
  };
  const onConfirm = () => {
    closeModal();
    onDelete && onDelete(image.id);
  };

  const onDeleteClick = () => {
    onDelete && onDelete(image.id);
  };
  const i: ImageRUG = {
    ...image,
    name: image.name,
    refresh: () => {},
    source: `${serverCore}${image.url}`,
    remove: identity,
  };
  return (
    <ImageListItem
      key={i.id}
      style={{
        border: selected ? '2px solid blue' : 'none',
      }}
      sx={{
        width: smallPreview ? '120px' : '200px',
        height: smallPreview ? '120px!important' : '200px!important',
      }}
      onClick={() => onClick && onClick(i)}
    >
      <DeleteConfirmModal open={confirmOpen} onConfirm={onConfirm} close={closeModal} />
      <img src={`${i.source}`} srcSet={`${i.source}`} alt={i.name} loading="lazy" />
      <ImageListItemBar
        title={i.name}
        subtitle={formatBytes(i.size, 2)}
        actionIcon={
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
            aria-label={`info about ${i.name}`}
            onClick={noConfirm ? onDeleteClick : openModal}
          >
            <Delete />
          </IconButton>
        }
      />
    </ImageListItem>
  );
};
