import { Box, ButtonProps, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface Props extends ButtonProps {
  onClick?: () => void;
  loading?: boolean;
  mt?: number;
}

export const SubmitButton = ({ onClick, loading, title, mt = 1 }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Grid item>
      <Box mt={mt}>
        <LoadingButton
          color="primary"
          loading={loading}
          type={onClick ? 'button' : 'submit'}
          variant={'contained'}
        >
          {title ?? t('save')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};
