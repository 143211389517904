import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { Box, Card, CardContent, Grid } from '@mui/material';
import PageContainer from '../../components/PageContainer/PageContainer';
import React from 'react';
import { BanksForm } from '../../forms/Bank';
import { ifElse, isNil } from 'ramda';
import { PATHS } from '../../routes/paths';
import { useParams } from 'react-router';
import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { ProductBanksTable } from '../../tables/ProductBanksTable';
import { FormBoxDivider } from '../../components/FormboxDivider';
import { useCreateBank, useEditBank } from '../../hooks/query/banks/useCreationEditBank';
import { useBanksFetch } from '../../hooks/query/banks/useBanksProductBanks';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { useBankById } from '../../hooks/query/banks/useBankById';

export const NewEditBank = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useBankById(id);
  const pageName = useNewEditPageTranslate('BANKS');
  const createMutation = useCreateBank();
  const editMutation = useEditBank();
  const {
    apiCall: { data: productBanks, isLoading: isLoadingProductBanks },
    setQueryParam,
  } = useBanksFetch(id);

  const onSubmit = (values) =>
    ifElse(
      isNil,
      async () => createMutation.mutateAsync(values),
      async () => editMutation.mutateAsync(values),
    )(id);

  return (
    <PageContainer title="Nová/editace banky">
      <Breadcrumb
        title={pageName}
        items={[{ title: 'BANKS', to: `${PATHS.BANKS_PATH}` }, { title: pageName }]}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              p: 0,
            }}
          >
            <CardContent>
              <Box p={3}>
                <LoadingErrorContainer isLoading={isLoading} isError={isError}>
                  <BanksForm
                    onSubmit={onSubmit}
                    data={data}
                    loading={createMutation.isLoading || editMutation.isLoading}
                  />
                </LoadingErrorContainer>
              </Box>
            </CardContent>
            {productBanks && id && (
              <>
                <FormBoxDivider icon={'alert-circle'} text={'Produkty banky'} />
                <CardContent>
                  <LoadingErrorContainer isLoading={isLoadingProductBanks}>
                    <ProductBanksTable
                      data={productBanks?.elements}
                      pagination={
                        <StyledPagination data={productBanks} setQueryParam={setQueryParam} />
                      }
                    />
                  </LoadingErrorContainer>
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
