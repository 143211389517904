import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { RESET_PASSWORD } from '../../../constant/api_url';

interface UserResetPasswordUserVariables {
  password: string;
}

export const useResetPasswordUser = () => {
  return useMutation<
    AxiosResponse<UserResetPasswordUserVariables>,
    AxiosError,
    UserResetPasswordUserVariables
  >((data: UserResetPasswordUserVariables) =>
    axios.post<UserResetPasswordUserVariables>(RESET_PASSWORD, data),
  );
};
