import PageContainer from '../../components/PageContainer/PageContainer';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { ChangePasswordEmailForm } from '../../forms/ChangePasswordEmailForm';
import { Link } from 'react-router-dom';
import { useResetResetEmail } from '../../hooks/query/resetEmail/useResetEmail';

export const ChangePasswordEmail = () => {
  const resetEmailMutation = useResetResetEmail();
  const submit = async (values) => {
    resetEmailMutation.mutateAsync({
      email: values.email,
    });
  };
  return (
    <PageContainer title="Reset Password" description="this is Reset Password page">
      <Grid container spacing={0} sx={{ height: '90vh', justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} lg={12} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={8}>
              <Container maxWidth={'xs'}>
                <Box
                  sx={{
                    p: 4,
                  }}
                >
                  <Typography variant="h2" fontWeight="700">
                    Zapomenuté heslo? Nevadí!
                  </Typography>

                  <Typography
                    color="textSecondary"
                    variant="h5"
                    fontWeight="400"
                    sx={{
                      mt: 2,
                    }}
                  >
                    Zadejte emailovou adresu, kterou máte přiřazenou k účtu. Systém Vám zašle odkaz
                    na změnu hesla.
                  </Typography>

                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <ChangePasswordEmailForm
                      onSubmit={submit}
                      loading={resetEmailMutation.isLoading}
                    />

                    <Button
                      color="secondary"
                      size="large"
                      fullWidth
                      component={Link}
                      to="/"
                      sx={{
                        pt: '10px',
                        pb: '10px',
                        mt: 2,
                      }}
                    >
                      zpět na přihlášení
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
