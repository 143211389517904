import errors from '../../constant/errors';
import * as yup from 'yup';

export const cFieldNames = {
  email: 'email',
};

export interface ResetPasswordEmailForm {
  email: string;
}
export const initialValues: ResetPasswordEmailForm = {
  email: '',
};
export const validationSchema = yup.object().shape({
  [cFieldNames.email]: yup
    .string()
    .email(errors.INVALID_EMAIL_ERROR)
    .required(errors.FIELD_REQUIRED_ERROR),
});
