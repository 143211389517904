import { Form } from '../../components/Forms/Formik/forms.types';
import { NewUser } from '../../types/users.types';
import { CustomForm } from '../../components/Forms/Formik/Form';
import { Grid } from '@mui/material';
import { cFieldNames, initialValues, validationSchema } from './schema';
import React from 'react';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';
import { FormikStatus } from '../../components/Forms/Formik/FormikStatus';

interface Props extends Form<NewUser> {}

export const UserForm: React.FC<Props> = ({ onSubmit, loading }) => {
  return (
    <>
      <CustomForm
        data={initialValues}
        initValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        formName={'USER_FORM'}
      >
        {({ values, setFieldValue, t }) => (
          <Grid container direction={'column'} spacing={1}>
            <TextField
              name={cFieldNames.email}
              onChange={(e) => setFieldValue(cFieldNames.authsSelector, e)}
            />
            <FormikStatus />
            <TextField
              name={cFieldNames.password}
              type={'password'}
              label={t('passwordLabel')}
              onChange={(e) => setFieldValue(cFieldNames.authsPassword, e)}
            />
            <TextField name={cFieldNames.profile} label={t('profileLabel')} />
            <SubmitButton loading={loading} />
          </Grid>
        )}
      </CustomForm>
    </>
  );
};
