import React from 'react';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DraggableListItem } from './DraggableListItem';
import { Box, Paper } from '@mui/material';
import { DroppableZones } from '../../context/StepInputDataContext/DroppableZones';
import { Input } from '../../types/input.types';

export const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

interface Props {
  items: Input[];
  onDelete: (result: DropResult) => void;
  droppableId: DroppableZones;
  onRequiredClick: (id: number) => void;
}

export const DroppableArea = ({ items, onDelete, droppableId, onRequiredClick }: Props) => {
  return (
    <>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <Paper variant={'outlined'} ref={provided.innerRef}>
            <Box
              sx={{
                pt: 1,
                pb: 1,
                m: 2,
              }}
            >
              {items
                ? items.map((item, index) => (
                    <DraggableListItem
                      onDelete={() =>
                        onDelete({
                          source: { index: index, droppableId: droppableId },
                          destination: { index: 0, droppableId: DroppableZones.FREE_INPUTS },
                        } as DropResult)
                      }
                      onRequiredClick={onRequiredClick}
                      key={item.id}
                      item={item}
                      index={index}
                    />
                  ))
                : !provided.placeholder && <Notice>Drop items here</Notice>}
              {provided.placeholder}
            </Box>
          </Paper>
        )}
      </Droppable>
    </>
  );
};
