import { TableProps } from './table.props';
import React from 'react';
import { ProductBankInputAuditLog } from '../types/productBankAuditLog.types';
import moment from 'moment';
import { path } from 'ramda';
import { SyntaxHighlightTableItem } from '../components/SyntaxHighlightTableItem/SyntaxHighlightTableItem';
import { Column } from 'react-table';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';

export const ProductBankInputChangelogTable = ({
  pagination,
  data,
}: TableProps<ProductBankInputAuditLog>) => {
  const columns: Column<ProductBankInputAuditLog>[] = React.useMemo(
    () => [
      {
        Header: 'Obrázek',
        accessor: 'action',
        width: '10%',
      },
      {
        Header: 'Název',
        accessor: 'input',
        Cell: (props) => props.row.original.input.inputName,
        width: '10%',
      },
      {
        Header: 'Čas',
        accessor: 'createdAt',
        Cell: (props) => moment(path(['createdAt'], props.row.original)).fromNow(),
        width: '10%',
      },
      {
        Header: 'Zobrazit',
        Cell: (props) => <SyntaxHighlightTableItem item={props.row.original.input} />,
      },
    ],
    [],
  );
  return (
    <>
      {data && (
        <ReactTable columns={columns} data={data} hideDeleteUpdate afterTableContent={pagination} />
      )}
    </>
  );
};
