import React from 'react';
import { Helmet } from 'react-helmet';
import { Spinner } from '../Spinner/Spinner';

interface Props {
  title?: string;
  description?: string;
  children?: any;
  isLoading?: boolean;
}
const PageContainer = ({ title, description, children, isLoading }: Props) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    {isLoading ? <Spinner /> : children}
  </div>
);

export default PageContainer;
