import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { createContext } from 'react';
import { identity } from 'ramda';
import { ReducerState } from './StepInputDataProvider';

export interface StepInputDataProvider {
  data: ReducerState;
  isLoading: boolean;
  isError: boolean;
  connectToStep: (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
    draggableId: string,
  ) => void;
  reorder: (oldIndex: number, newIndex: number) => void;
  deleteItem: (
    droppableSource: DraggableLocation | undefined,
    droppableDestination: DraggableLocation | undefined,
  ) => void;
  inputsSearchOnChange: (search: string) => void;
  inputsSearch: string;
  toggleRequired: (id: number) => void;
  onDragEnd: (result: DropResult) => void;
  searchLeftInputs: string;
  leftInputsSearchOnChange: (search: string) => void;
}

export const StepInputDataContext = createContext({
  data: {
    inputs: [],
    data: [],
  },
  isLoading: true,
  isError: false,
  connectToStep: identity,
  reorder: identity,
  deleteItem: identity,
  inputsSearchOnChange: identity,
  inputsSearch: '',
  toggleRequired: identity,
  onDragEnd: identity,
  searchLeftInputs: '',
  leftInputsSearchOnChange: identity,
} as StepInputDataProvider);
