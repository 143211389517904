import { HandleBar } from './HandleBar';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { InputDraggableItemOnlyText } from './InputTypesPreviews/InputDraggableItemOnlyText';
import { Input } from '../../types/input.types';
import { Box } from '@mui/material';

export const Item = styled.div`
  display: flex;
  margin: 0 0 0.5rem 0;
  position: relative;
`;

interface Props {
  item: Input;
  index: number;
  onDelete: (id: number) => void;
  onRequiredClick: (id: number) => void;
}
export const DraggableListItem = ({ item, index, onDelete, onRequiredClick }: Props) => {
  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <>
          <Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            style={provided.draggableProps.style}
          >
            <InputDraggableItemOnlyText
              onRequiredClick={onRequiredClick}
              deleteAllowed
              handle={
                <Box pl={2}>
                  <HandleBar {...provided.dragHandleProps} />
                </Box>
              }
              onDelete={onDelete}
              input={item}
            />
          </Item>
        </>
      )}
    </Draggable>
  );
};

/*
   {cond([
              [equals(InputType.SELECT), always(<SelectChoiceField
                onRequiredClick={onRequiredClick}
                deleteAllowed
                handle={
                  <Box pl={2}>
                    <HandleBar {...provided.dragHandleProps} />
                  </Box>
                }
                onDelete={onDelete}
                input={item}
              />)],
              [T, always(
                <InputDraggableItemOnlyText
                  onRequiredClick={onRequiredClick}
                  deleteAllowed
                  handle={
                    <Box pl={2}>
                      <HandleBar {...provided.dragHandleProps} />
                    </Box>
                  }
                  onDelete={onDelete}
                  input={item}
                />
              )],
              // @ts-ignore
            ])(item.type)}
 */
