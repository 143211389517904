import {
  PAGE_SIZE_QUERY,
  PaginationSearchQueryParams,
  UseQueryWithParams,
} from '../generic/useGenericQueryParams';
import { IPagination } from '../../useApiPagination';
import { usePaginationQueryParams } from '../generic/useGenericPaginationQueryParams';
import { STEP_API } from '../../../constant/api_url';
import { Step } from '../../../types/step.types';
import { NumberParam, withDefault } from 'use-query-params';

export const useStepsFetch = (): UseQueryWithParams<
  IPagination<Step>,
  PaginationSearchQueryParams
> => {
  return usePaginationQueryParams<Step>(STEP_API, {
    [PAGE_SIZE_QUERY]: withDefault(NumberParam, 100),
  });
};
