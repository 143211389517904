import { Field } from 'formik';
import { CheckBoxFormik, CheckboxInputProps } from '../CheckBoxFormik';
import React from 'react';
import { Grid } from '@mui/material';

export const CheckboxField = (props: CheckboxInputProps) => {
  return (
    <Grid item xs={12}>
      <Field {...props} formGroup component={CheckBoxFormik} />
    </Grid>
  );
};
