import { always, cond, equals, T } from 'ramda';
import { useFormikContext } from 'formik';
import { Input, InputChoice, InputType } from '../../types/input.types';
import { NumberField } from '../../components/Forms/Formik/Fields/NumberField';
import { SelectField } from '../../components/Forms/Formik/Fields/SelectField';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { Avatar, Box, Grid, IconButton } from '@mui/material';
import { serverCore } from '../../config';
import { ClearOutlined } from '@mui/icons-material';
import { isNilOrEmpty } from '../../utils/ramda_utils';

interface Props {
  fieldName: string;
}

export const DefaultValueFieldByType = ({ fieldName }: Props) => {
  const { values, setFieldValue } = useFormikContext<Input>();

  return (
    <Grid item>
      <Box display={'flex'} alignItems={'end'} justifyContent={'center'}>
        {cond([
          [equals(InputType.INT), always(<NumberField name={fieldName} />)],
          [
            equals(InputType.SELECT),
            always(
              <SelectField
                name={fieldName}
                options={
                  isNilOrEmpty(values)
                    ? []
                    : values.choices.map((ch) => ({
                        id: ch.value,
                        name: ch.description,
                      }))
                }
              />,
            ),
          ],
          [
            equals(InputType.CHOICE),
            always(
              <SelectField
                name={fieldName}
                options={
                  isNilOrEmpty(values)
                    ? []
                    : values.choices.map((ch) => {
                        return {
                          id: ch.value,
                          name: getImagePreview(ch),
                        };
                      })
                }
              />,
            ),
          ],
          [T, always(<TextField name={fieldName} />)],
          // @ts-ignore
        ])(values.type)}
        {!isNilOrEmpty(values[fieldName]) && (
          <Box ml={2}>
            <IconButton onClick={() => setFieldValue(fieldName, null)}>
              <ClearOutlined />
            </IconButton>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export const getImagePreview = (input: InputChoice) => {
  if (input.image) {
    return (
      <Box display={'flex'}>
        {input.image && (
          <Avatar sx={{ width: 24, height: 24 }} src={`${serverCore}/f/${input.image.url}`} />
        )}{' '}
        <Box ml={1} /> {input.description}{' '}
      </Box>
    );
  }
  if (input.imageId) {
    return (
      <Box display={'flex'}>
        {input.imageId && (
          <Avatar sx={{ width: 24, height: 24 }} src={`${serverCore}/f/${input.imageId}`} />
        )}{' '}
        <Box ml={1} /> {input.description}{' '}
      </Box>
    );
  }
  return <Box display={'flex'}>{input.description} </Box>;
};
