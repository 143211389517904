import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { NewUser } from '../../../types/users.types';
import { axiosAuth } from '../../../utils/apiUtils';
import { AUTH_USER_API, AUTH_USERS_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<NewUser>, AxiosError, string>(
    (data: string) => axiosAuth.delete<NewUser>(`${AUTH_USER_API}/${data}`),
    {
      onError: () => {
        toast.error('Nepodařilo se odstranit uživatele');
      },
      onSuccess: () => {
        queryClient.invalidateQueries([`${AUTH_USERS_API}`, { 'pageNumber': 0, 'pageSize': 10, 'search': '' }]);
        toast.success('Uživatel byl odstraněn');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === AUTH_USER_API,
        });
      },
    },
  );
};
