import { Grid } from '@mui/material';
import { Field } from 'formik';
import { SelectMenuItem } from '../OutlinedFormikInput';
import { AutocompleteSyncFormik } from '../Autocomplete';
import { SyntheticEvent } from 'react';

interface Props {
  name: string;
  options: Array<SelectMenuItem>;
  onChange?: (e: SyntheticEvent, data: any) => void;
}

export const AutocompleteSync = ({ name, options, onChange, ...props }: Props) => {
  return (
    <Grid item xs={12}>
      <Field
        name={name}
        onChange={onChange}
        component={AutocompleteSyncFormik}
        options={options}
        {...props}
      />
    </Grid>
  );
};
