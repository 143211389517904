import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { QueryFunctionContext } from 'react-query/types/core/types';
import { AxiosError, AxiosResponse } from 'axios';
import axios, { axiosAuth } from '../../utils/apiUtils';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { useState } from 'react';

export interface GetInfinitePagesInterface<T> {
  nextId?: number;
  previousId?: number;
  data: T;
  count: number;
}

export type QueryKeyT = [string, object | string | number | undefined];
export type QueryKeyOnlyT = [string];

export function getFetcher<T>({ queryKey, pageParam }: QueryFunctionContext<QueryKeyT>): Promise<T> {
  const [url, params] = queryKey;
  return axios.get<T>(url, { params }).then((res: AxiosResponse<T>) => res.data);
}

export function getAuthFetcher<T>({ queryKey, pageParam }: QueryFunctionContext<QueryKeyT>): Promise<T> {
  const [url, params] = queryKey;
  return axiosAuth.get<T>(url, { params }).then((res: AxiosResponse<T>) => res.data);
}

// The same as getFetcher above, but necessary for getting all users, which is the POST request
export function postFetcher<T>({ queryKey, pageParam }: QueryFunctionContext<QueryKeyT>): Promise<T> {
  const [url, params] = queryKey;
  return axiosAuth.post<T>(url, { params }).then((res: AxiosResponse<T>) => res.data);
}

export function authFetcher<T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKeyT>): Promise<T> {
  const [url, params] = queryKey;
  return axiosAuth.get<T>(url, { params }).then((res: AxiosResponse<T>) => res.data);
}

export function useLoadMore<T>(url: string | null, params?: object) {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >(
    [url!, params],
    // @ts-ignore
    ({ queryKey, pageParam = 1 }) => getFetcher({ queryKey, pageParam }),
    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? false,
      getNextPageParam: (lastPage) => {
        return lastPage.nextId ?? false;
      },
    },
  );

  return context;
}

export function usePrefetch<T>(url: string | null, params?: object) {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient.prefetchQuery<T, Error, T, QueryKeyT>(
      [url!, params],
      // @ts-ignore
      ({ queryKey }) => getFetcher({ queryKey }),
    );
  };
}

export type FetchByIdQuery = [string, number | string | undefined];

export function useFetchById<T>(
  url: string | null,
  id?: number | string,
  config?: UseQueryOptions<T, Error, T, FetchByIdQuery>,
): UseQueryResult<T> {
  return useQuery<T, Error, T, FetchByIdQuery>(
    [url!, id],
    // @ts-ignore
    ({ queryKey }) => getFetcher<T>({ queryKey }),
    {
      enabled: !isNilOrEmpty(id),
      ...config,
    },
  );
}

export function useGetById<T>(
  url: string | null,
  id?: number | string,
  config?: UseQueryOptions<T, Error, T, FetchByIdQuery>,
): UseQueryResult<T> {
  return useQuery<T, Error, T, FetchByIdQuery>(
    [`${url}/${id}`!, id],
    // @ts-ignore
    ({ queryKey }) => getFetcher<T>({ queryKey }),
    {
      enabled: !isNilOrEmpty(id),
      ...config,
    },
  );
}

export function useQueryById<T>(url: string, id: string | number | undefined) {
  return useGetFetch<T>(url, id, { enabled: !isNilOrEmpty(id) });
}

export function useGetFetch<T>(
  url: string | null,
  params?: object | string | number | null | undefined,
  config?: UseQueryOptions<T, Error, T, QueryKeyT | QueryKeyOnlyT>,
): UseQueryResult<T> {
  return useQuery<T, Error, T, QueryKeyT | QueryKeyOnlyT>(
    params ? [url!, params] : [url!],
    // @ts-ignore
    ({ queryKey }) => getFetcher<T>({ queryKey }),
    {
      enabled: !!url,
      ...config,
    },
  );
}

export function useGetAuthFetch<T>(
  url: string | null,
  params?: object | string | number | null | undefined,
  config?: UseQueryOptions<T, Error, T, QueryKeyT | QueryKeyOnlyT>,
): UseQueryResult<T> {
  return useQuery<T, Error, T, QueryKeyT | QueryKeyOnlyT>(
    params ? [url!, params] : [url!],
    // @ts-ignore
    ({ queryKey }) => getAuthFetcher<T>({ queryKey }),
    {
      enabled: !!url,
      ...config,
    },
  );
}

export function usePostFetch<T>(
  url: string | null,
  params?: object | string | number | null | undefined,
  config?: UseQueryOptions<T, Error, T, QueryKeyT | QueryKeyOnlyT>,
): UseQueryResult<T> {
  return useQuery<T, Error, T, QueryKeyT | QueryKeyOnlyT>(
    params ? [url!, params] : [url!],
    // @ts-ignore
    ({ queryKey }) => postFetcher<T>({ queryKey }),
    {
      enabled: !!url,
      ...config,
    },
  );
}

const useGenericMutation = <T, S>(
  func: (data: S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      // @ts-ignore
      queryClient.setQueryData<T>([url!, params], (oldData) => {
        return updater ? updater(oldData!, data) : data;
      });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export function useDelete<T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
) {
  return useGenericMutation<T, string | number>(
    (id) => axios.delete(`${url}/${id}`),
    url,
    params,
    updater,
  );
}

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>((data) => axios.post<S>(url, data), url, params, updater);
};

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>((data) => axios.patch<S>(url, data), url, params, updater);
};

interface IPagination<T> {
  elements: Array<T>;
  pageNumber: number;
  totalPages: number;
  hasNextPage: boolean;
  elementsPerPage: number;
  numberOfElements: number;
}

interface PaginationParams {
  setPageSize: (size: number) => void;
  setPageNumber: (size: number) => void;
  pageSize: number;
  pageNumber: number;
}

export function usePaginationFetch<T>(
  api: string,
): UseQueryResult<IPagination<T>> & PaginationParams {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const result = useGetFetch<IPagination<T>>(
    api,
    { pageNumber, pageSize },
    { keepPreviousData: true },
  );
  return {
    ...result,
    setPageNumber,
    setPageSize,
    pageNumber,
    pageSize,
  };
}
