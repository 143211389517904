import React from 'react';
import { XCircle } from 'react-feather';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeleteConfirmModal = ({ open, close, onConfirm }) => {
  const { t } = useTranslation('forms');
  const formName = 'DELETE_MODAL';
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onConfirm();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      style={{ width: 'auto' }}
      onKeyDown={handleKeyPress}
      onKeyPress={handleKeyPress}
    >
      <div
        className="modal-dialog modal-confirm"
        style={{ margin: 0 }}
        onKeyDown={handleKeyPress}
        onKeyPress={handleKeyPress}
      >
        <div className="modal-content">
          <div className="modal-header flex-column">
            <div
              className={'d-flex justify-content-center align-items-center'}
              style={{ width: '100%' }}
            >
              <XCircle size={40} />
            </div>
            <h4 className="modal-title w-100">{t(`${formName}.title`)}</h4>
          </div>
          <div className="modal-body">
            <p>{t(`${formName}.description`)}</p>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={close}
            >
              {t(`${formName}.cancel`)}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onKeyPress={handleKeyPress}
              onClick={onConfirm}
            >
              {t(`${formName}.delete`)}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default DeleteConfirmModal;
