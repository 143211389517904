import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { PATHS } from '../../routes/paths';
import React from 'react';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useGetMe } from '../../hooks/query/users/useGetMe';
import { ProfileForm } from '../../forms/Profile/ProfileForm';
import { ProfileUpdate } from '../../types/users.types';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { useUpdateProfile } from '../../hooks/query/users/useUpdateProfile';

export const Profile = () => {
  const pageName = useNewEditPageTranslate('PROFILE');
  const { data, isLoading, isError } = useGetMe();
  const updateMutation = useUpdateProfile();
  const onSubmit = (data: ProfileUpdate) => {
    updateMutation.mutateAsync(data);
  };
  return (
    <PageContainer title={pageName}>
      <Container maxWidth={'md'}>
        <Typography
          fontWeight="700"
          variant="h1"
          sx={{
            lineHeight: '1.235',
          }}
        >
          Profil
        </Typography>
        <LoadingErrorContainer isLoading={isLoading} isError={isError}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{
                  p: 0,
                }}
              >
                <CardContent>
                  <Box p={2}>
                    <ProfileForm
                      onSubmit={onSubmit}
                      data={data}
                      loading={updateMutation.isLoading}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </LoadingErrorContainer>
      </Container>
    </PageContainer>
  );
};
