import { Field } from 'formik';
import React from 'react';
import { TextFormikFieldProps } from '../TextFormikInput';
import { TextField } from './TextField';
import { Grid } from '@mui/material';

export const NumberField = (props: TextFormikFieldProps) => {
  return (
    <Grid item xs={12}>
      <Field {...props} type={'number'} component={TextField} />
    </Grid>
  );
};
