import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/apiUtils';
import { INPUT_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

interface UseInputToggleRequiredVariables {
  inputId;
}

export const useToggleRequired = () => {
  return useMutation<
    AxiosResponse<UseInputToggleRequiredVariables>,
    AxiosError,
    UseInputToggleRequiredVariables
  >(({ inputId }) => axios.patch(`${INPUT_API}/${inputId}/toggle-required`), {
    onSuccess: () => {
      toast.success('Vstup byl označen jako povinný');
    },
    onError: () => {
      toast.error('Nepodařilo se označit vstup jako povinný');
    },
  });
};
