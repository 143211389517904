import { TextFormikFieldProps } from '../TextFormikInput';
import { Field } from 'formik';
import { Grid } from '@mui/material';
import { OutlinedFormikInput } from '../OutlinedFormikInput';

export const TextField = (props: TextFormikFieldProps) => {
  return (
    <Grid item xs={12}>
      <Field {...props} component={OutlinedFormikInput} />
    </Grid>
  );
};
