import { FormHelperText } from '@mui/material';
import React from 'react';

interface Props {
  error: boolean;
  text: string;
}
export const FormikFieldError = ({ error, text }: Props) => {
  return error ? (
    <FormHelperText error id="accountId-error">
      {text}
    </FormHelperText>
  ) : (
    <div />
  );
};
