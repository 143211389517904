import React from 'react';
import { TableProps } from './table.props';
import { Input } from '../types/input.types';
import { PATHS } from '../routes/paths';
import { ReactTable } from '../components/Table/reaact-table/ReactTable';
import { Column } from 'react-table';

export const InputsTable = ({ onDelete, data, pagination }: TableProps<Input>) => {
  const columns: Column<Input>[] = React.useMemo(
    () => [
      {
        Header: 'Název',
        accessor: 'name',
        width: '80%',
      },
      {
        Header: 'Typ',
        accessor: 'type',
      },
      {
        Header: 'Kalkulace',
        accessor: 'calculationType',
      },
    ],
    [],
  );

  return (
    <>
      {data && (
        <ReactTable
          link={`${PATHS.INPUTS_PATH}${PATHS.EDIT}`}
          columns={columns}
          handleDeleteItem={onDelete}
          data={data}
          afterTableContent={pagination}
        />
      )}
    </>
  );
};
