import { File } from './files.types';
import { StepDependency } from './stepDependency.types';

export interface AbstractStep {
  id: number;
  name: string;
  required: boolean;
  sort: number;
  stepDependency: Array<StepDependency>;
}

export interface NewStep extends AbstractStep {
  iconId?: string;
}

export interface Step extends AbstractStep {
  icon?: File;
}
export const initialValues: NewStep = {
  id: 0,
  iconId: '',
  name: '',
  required: false,
  sort: 0,
  stepDependency: [],
};
export type StepResponse = Array<Step>;
