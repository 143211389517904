import { CircularProgress, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React from 'react';
import { isNilOrEmpty } from '../../../utils/ramda_utils';
import ClearIcon from '@mui/icons-material/Clear';
import FeatherIcon from 'feather-icons-react';

interface Props {
  isLoading?: boolean;
  value: string | undefined | number;
  onChange: (value: any) => void;
}

export const SearchOutlinedField: React.FC<Props> = ({ isLoading, value, onChange }) => {
  const getInputEndIcon = () => {
    if (isLoading) {
      return (
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility">
            <CircularProgress size={18} />
          </IconButton>
        </InputAdornment>
      );
    }
    if (!isLoading && !isNilOrEmpty(value)) {
      return (
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={() => onChange('')}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };
  return (
    <OutlinedInput
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      size={'small'}
      startAdornment={
        <InputAdornment position="start">
          <FeatherIcon icon="search" width="20" />
        </InputAdornment>
      }
      endAdornment={getInputEndIcon()}
    />
  );
};
