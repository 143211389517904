import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../utils/apiUtils';
import { BANK_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export interface DeleteBankMutationVariables {
  id: number;
}

export const useBankDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, DeleteBankMutationVariables>(
    (data: DeleteBankMutationVariables) => axios.delete(`${BANK_API}/${data.id}`),
    {
      onError: (err, _, context) => {
        toast.error('Nepodařilo se vymazat banku');
      },
      onSuccess: (data) => {
        toast.success('Banka vymazána');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === BANK_API,
        });
      },
    },
  );
};
