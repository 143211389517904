import { Grid } from '@mui/material';
import { Spinner } from '../Spinner/Spinner';
import React from 'react';
import Error from '../../views/Authentication/Error';
interface Props {
  isLoading?: boolean;
  isError?: boolean | any;
  children: any;
}
export const LoadingErrorContainer = ({ isLoading, isError, children }: Props) => {
  if (isLoading) {
    return (
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        style={{ height: '50vh', width: '100%' }}
      >
        <Grid item>
          <Spinner />
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return <Error />;
  }

  return <>{children}</>;
};
