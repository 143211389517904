export const DEV = process.env.NODE_ENV !== 'production';
export const BASE_URL = process.env.REACT_APP_API_URI || 'https://api.srovnejsito.eu';

export const GA_TRACKING_ID = '';
export const FB_TRACKING_ID = '';
export const SENTRY_TRACKING_ID = '';

export const SITE_NAME = 'srovnejsito.eu';
export const SITE_TITLE = 'Srovnávač bankovních poplatků | srovnejsito.eu';
export const SITE_DESCRIPTION =
  'Srovnávač bankovních poplatků se stochastickou komponentou. Zjistěte, který účet je pro Vás ten pravý! Bez vazby na banku a podsouvání výsledků. Opravdu spravedlivý porovnávač bankovních poplatků!';
export const SITE_IMAGE = '';
