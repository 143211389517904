import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { axios } from '../../../utils/apiUtils';
import { useMutation, useQueryClient } from 'react-query';
import { PATHS } from '../../../routes/paths';
import { Step } from '../../../types/step.types';
import { STEP_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';

export const useCreateStep = () => {
  const navigate = useNavigate();
  return useMutation<AxiosResponse<Step>, AxiosError, Step>(
    (data: Step) => axios.post<Step>(STEP_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit krok');
      },
      onSuccess: ({ data }) => {
        toast.success('Krok byl vytvořen');
        navigate(`${PATHS.STEPS_PATH}${PATHS.EDIT}/${data.id}`);
      },
    },
  );
};

export const useEditStep = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Step>, AxiosError, Step>(
    (data: Step) => axios.put<Step>(`${STEP_API}/${data.id}`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat krok');
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([`${STEP_API}/${data.id}`]);
        toast.success('Krok byl aktualizován');
      },
    },
  );
};
