import * as yup from 'yup';
import errors from '../../constant/errors';

export const cFieldNames = {
  bank: 'bank',
  accountName: 'accountName',
  externalName: 'externalName',
  note: 'note',
  internalNote: 'internalNote',
  inputs: 'inputs',
  computeValue: 'computeValue',
  createValue: 'createValue',
  url: 'url',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.bank]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.computeValue]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
  [cFieldNames.createValue]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
});
