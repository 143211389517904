export const PATHS = {
  BANKS_PATH: '/banks',
  STEPS_PATH: '/steps',
  NEW: '/new',
  EDIT: '/edit',
  INPUTS_PATH: '/inputs',
  GALLERY: '/gallery',
  PRODUCT_BANK: '/product-bank',
  CHANGE_PASSWORD_EMAIL: '/change-password/email',
  CHANGE_PASSWORD: '/change-password/validate/:token',
  CHANGE_PASSWORD_USER: '/change-password',
  USERS: '/users',
  PROFILE: '/profile',
};
