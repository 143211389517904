import React from 'react';
import styled from 'styled-components';
import { Move } from 'react-feather';
const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  margin: -0.5rem 0.5rem -0.5rem -0.5rem;
  padding: 0.5rem;
  line-height: 1.5;
  color: #000;
`;

export const HandleBar = (props) => {
  return (
    <Handle {...props}>
      <Move size={15} />
    </Handle>
  );
};
