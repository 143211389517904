import * as yup from 'yup';
import errors from '../../../constant/errors';

export const cFieldNames = {
  description: 'description',
  value: 'value',
  tooltip: 'tooltip',
  imageId: 'imageId',
};

export const validationSchema = yup.object().shape({
  [cFieldNames.value]: yup.string().required(errors.FIELD_REQUIRED_ERROR),
});
