import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Input } from '../../types/input.types';
import { toString } from 'ramda';

const Item = styled.div`
  display: flex;
  margin: 0 0 0.5rem 0;
  ${(props) => (props.isDragging ? 'border: 1px dashed #000' : '')};
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

interface Props {
  item: Input;
  index: number;
  content: React.ReactNode;
}
export const DraggableToolbarItem = ({ item, index, content }: Props) => {
  return (
    <Draggable key={item.id} draggableId={toString(item.id)} index={index}>
      {(provided, snapshot) => (
        <>
          <Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            style={provided.draggableProps.style}
          >
            {content}
          </Item>
          {snapshot.isDragging && <Clone>{content}</Clone>}
        </>
      )}
    </Draggable>
  );
};
