import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { INPUT_API } from '../../../constant/api_url';
import { toast } from 'react-hot-toast';
import { PATHS } from '../../../routes/paths';
import axios from '../../../utils/apiUtils';
import { Input } from '../../../types/input.types';

export const useCreateInputs = () => {
  const navigate = useNavigate();
  return useMutation<AxiosResponse<Input>, AxiosError, Input>(
    (data: Input) => axios.post<Input>(INPUT_API, data),
    {
      onError: () => {
        toast.error('Nepodařilo se vytvořit vstup');
      },
      onSuccess: ({ data }) => {
        toast.success('Vstup byl vytvořen');
        navigate(`${PATHS.INPUTS_PATH}${PATHS.EDIT}/${data.id}`);
      },
    },
  );
};
export const useEditInputs = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<Input>, AxiosError, Input>(
    (data: Input) => axios.put<Input>(`${INPUT_API}/${data.id}`, data),
    {
      onError: () => {
        toast.error('Nepodařilo se aktualizovat vstup');
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([`${INPUT_API}/${data.id}`]);
        toast.success('Vstup byl aktualizován');
      },
    },
  );
};
