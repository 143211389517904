import { PATHS } from '../../routes/paths';
import PageContainer from '../../components/PageContainer/PageContainer';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { NavButton } from '../../components/NavButton/NavButton';
import { Box, Card, CardContent, LinearProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { InputsTable } from '../../tables';
import { useInputsFetch } from '../../hooks/query/inputs/useInputs';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { useInputsDeleteMutation } from '../../hooks/query/inputs/useInputsDeleteMutation';
import { SearchOutlinedField } from '../../components/Forms/CustomElements/SearchOutlinedField';
import { propOr } from 'ramda';
import { PAGE_SEARCH_QUERY } from '../../hooks/query/generic/useGenericQueryParams';
import { Input } from '../../types/input.types';

export const Inputs = () => {
  const { t } = useTranslation('pages');
  const {
    apiCall: { data, isLoading, isError, isFetching },
    params,
    setQueryParam,
  } = useInputsFetch();

  const inputsDeleteMutation = useInputsDeleteMutation();

  const onDelete = async (item: Input) => {
    await inputsDeleteMutation.mutateAsync({ id: item.id });
  };

  return (
    <PageContainer>
      <Breadcrumb title={t('INPUT')} items={[{ title: 'INPUT' }]}>
        <NavButton title={t('ADD_BUTTON')} to={`${PATHS.INPUTS_PATH}${PATHS.NEW}`} icon={'plus'} />
      </Breadcrumb>
      <Card>
        <CardContent>
          <SearchOutlinedField
            value={propOr('', PAGE_SEARCH_QUERY, params)}
            onChange={(value) => setQueryParam(PAGE_SEARCH_QUERY, value)}
          />
          <Box mt={2} />
          {isFetching || (inputsDeleteMutation.isLoading && <LinearProgress />)}
          <LoadingErrorContainer isLoading={isLoading} isError={isError}>
            <InputsTable
              data={data?.elements}
              pagination={<StyledPagination data={data} setQueryParam={setQueryParam} />}
              onDelete={onDelete}
            />
          </LoadingErrorContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
