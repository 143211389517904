import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import PageContainer from '../../components/PageContainer/PageContainer';
import React from 'react';
import { useParams } from 'react-router';
import { ifElse, isNil, or } from 'ramda';
import { PATHS } from '../../routes/paths';
import { InputForm } from '../../forms/Input';
import { isNilOrEmpty } from '../../utils/ramda_utils';
import { isFunction } from 'formik';
import { useNewEditPageTranslate } from '../../hooks/newEditPageTranslate';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import { useCreateInputs, useEditInputs } from '../../hooks/query/inputs/useCreateEditInputs';
import { useDeleteInputChoice } from '../../hooks/query/inputChoice/useDeleteInputChoice';
import { useInputById } from '../../hooks/query/inputs/useInputById';

export const NewEditInputs = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useInputById(id);
  const pageName = useNewEditPageTranslate('INPUT');
  const createMutation = useCreateInputs();
  const editMutation = useEditInputs();
  const deleteMutation = useDeleteInputChoice();

  const onSubmit = (values) => {
    ifElse(
      isNil,
      async () => createMutation.mutateAsync(values),
      async () => editMutation.mutateAsync(values),
    )(id);
  };

  const removeValue = async (value, success) => {
    if (or(isNilOrEmpty(value), isNilOrEmpty(id))) {
      return isFunction(success) && success();
    }
    deleteMutation.mutateAsync({ id: value.id }).then(() => {
      isFunction(success) && success();
    });
  };

  return (
    <PageContainer>
      <Breadcrumb
        title={pageName}
        items={[{ title: 'INPUT', to: `${PATHS.INPUTS_PATH}` }, { title: pageName }]}
      />
      <LoadingErrorContainer isLoading={isLoading} isError={isError}>
        <InputForm
          onSubmit={onSubmit}
          data={data}
          loading={editMutation.isLoading || createMutation.isLoading}
          removeValue={removeValue}
          id={id}
        />
      </LoadingErrorContainer>
    </PageContainer>
  );
};
