import React from 'react';
import Breadcrumb from '../../layouts/FullLayout/breadcrumb/Breadcrumb';
import { NavButton } from '../../components/NavButton/NavButton';
import { PATHS } from '../../routes/paths';
import { Box, Card, CardContent, LinearProgress } from '@mui/material';
import { LoadingErrorContainer } from '../../components/LoadingErrorContainer';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { ProductBanksTable } from '../../tables/ProductBanksTable';
import { useProductBanksFetch } from '../../hooks/query/product-banks/useProductBanks';
import { StyledPagination } from '../../components/Pagination/PaginationStyled';
import { useProductBankDeleteMutation } from '../../hooks/query/product-banks/useProductBankDeleteMutation';
import { SearchOutlinedField } from '../../components/Forms/CustomElements/SearchOutlinedField';
import { propOr } from 'ramda';
import { PAGE_SEARCH_QUERY } from '../../hooks/query/generic/useGenericQueryParams';
import { ProductBank } from '../../types/productBanks.types';

export const ProductBanks = () => {
  const { t } = useTranslation('pages');
  const {
    apiCall: { data, isLoading, isError, isFetching },
    params,
    setQueryParam,
  } = useProductBanksFetch();
  const deleteMutation = useProductBankDeleteMutation();

  const onDelete = (item: ProductBank) => deleteMutation.mutateAsync({ id: item.id });

  return (
    <PageContainer>
      <Breadcrumb title={t('PRODUCT_BANKS')} items={[{ title: 'PRODUCT_BANKS' }]}>
        <NavButton title={t('ADD_BUTTON')} to={`${PATHS.PRODUCT_BANK}${PATHS.NEW}`} icon={'plus'} />
      </Breadcrumb>
      <Card>
        <CardContent>
          <SearchOutlinedField
            value={propOr('', PAGE_SEARCH_QUERY, params)}
            onChange={(value) => setQueryParam(PAGE_SEARCH_QUERY, value)}
          />
          <Box mt={2} />
          <LoadingErrorContainer isLoading={isLoading} isError={isError}>
            {isFetching || isLoading || (deleteMutation.isLoading && <LinearProgress />)}
            <ProductBanksTable
              data={data?.elements}
              pagination={<StyledPagination data={data} setQueryParam={setQueryParam} />}
              onDelete={onDelete}
            />
          </LoadingErrorContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
