import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { equals, lensProp, set } from 'ramda';
import { QueryParamConfig } from 'serialize-query-params/lib/types';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { useState } from 'react';
import { isNilOrEmpty } from '../../../utils/ramda_utils';
import { AxiosError, AxiosResponse } from 'axios';
export const PAGE_NUMBER_QUERY = 'pageNumber';
export const PAGE_SIZE_QUERY = 'pageSize';

export interface UseQueryWithParams<T, P extends QueryParamConfigMap> {
  apiCall: UseQueryResult<T>;
  params: DecodedValueMap<P>;
  setQueryParam: SetQueryParamFunc;
}

export interface UseMutationWithParams<T, P extends QueryParamConfigMap> {
  apiCall: UseMutationResult<AxiosResponse<T>, AxiosError>;
  params: DecodedValueMap<P>;
  setQueryParam: SetQueryParamFunc;
}

export const PAGE_SEARCH_QUERY = 'search';

export interface PaginationQueryParams extends QueryParamConfigMap {
  [PAGE_NUMBER_QUERY]: QueryParamConfig<number>;
}

export interface PaginationPageNumberQueryParam {
  [PAGE_NUMBER_QUERY]: QueryParamConfig<number>;
}

export interface PaginationPageSizeQueryParam {
  [PAGE_SIZE_QUERY]: QueryParamConfig<number>;
}

export interface PaginationSearchQueryParam {
  [PAGE_SEARCH_QUERY]: QueryParamConfig<string>;
}

export interface PaginationSearchQueryParams
  extends PaginationSearchQueryParam,
    PaginationPageSizeQueryParam,
    PaginationPageNumberQueryParam,
    QueryParamConfigMap {}

export type PaginationSearchQueryParamsInitType =
  | PaginationPageNumberQueryParam
  | PaginationPageSizeQueryParam
  | PaginationSearchQueryParam;
export type SetQueryParamFunc = (param: string, value: any) => void;

export const PaginationQueryConfigMap: PaginationQueryParams = {
  [PAGE_NUMBER_QUERY]: withDefault(NumberParam, 0),
  [PAGE_SIZE_QUERY]: withDefault(NumberParam, 25),
};

export const PaginationSearchQueryConfigMap: PaginationSearchQueryParams = {
  [PAGE_NUMBER_QUERY]: withDefault(NumberParam, 0),
  [PAGE_SIZE_QUERY]: withDefault(NumberParam, 10),
  [PAGE_SEARCH_QUERY]: withDefault(StringParam, ''),
};

export const omitEmpty = (obj: object): object =>
  Object.keys(obj)
    .filter((k) => obj[k] !== '')
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

export function useGenericQueryParams<P extends QueryParamConfigMap>(
  init: P,
): [DecodedValueMap<P>, SetQueryParamFunc] {
  const [history, setHistory] = useState<DecodedValueMap<any>>({});
  const [query, setQuery] = useQueryParams<P>(init);

  const setQueryParam = (param: keyof P, value: any) => {
    if (equals(param, PAGE_SEARCH_QUERY)) {
      /** If search param changes to '', set the history before searching */
      if (isNilOrEmpty(value)) {
        setQuery(omitEmpty(set(lensProp(param), value, history)), 'replace');
        setHistory({});
      } else {
        /** search param has changed, set history before searching. If history is set, no change and keep it save */
        if (isNilOrEmpty(history)) {
          setHistory(query);
        }
        /** Set search result to !!! page=0 !!! and size=10, bcs there are best results */
        setQuery(
          omitEmpty(
            set(lensProp(param), value, {
              [PAGE_NUMBER_QUERY]: 0,
              [PAGE_SIZE_QUERY]: 10,
            } as DecodedValueMap<any>),
          ),
          'replace',
        );
      }
    } else {
      setQuery(omitEmpty(set(lensProp(param), value, query)), 'replace');
    }
  };

  return [query, setQueryParam];
}
