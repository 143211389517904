import { CustomForm } from '../../components/Forms/Formik/Form';
import { TextField } from '../../components/Forms/Formik/Fields/TextField';
import { cFieldNames, initialData, validationSchema } from './schema';
import { Alert, Box, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { SubmitButton } from '../../components/Forms/Submit/SubmitButton';

export const LoginForm = () => {
  const [loading, setLoading] = useState<boolean>();
  const { login } = useAuth();
  return (
    <CustomForm
      data={initialData}
      initValues={initialData}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        setLoading(true);
        await login(values[cFieldNames.username], values[cFieldNames.password]).catch(() => {
          helpers.setStatus('Cannot login!');
        });
        setLoading(false);
      }}
      formName={'LOGIN_FORM'}
    >
      {({ status }) => (
        <>
          {status && (
            <Box mb={1}>
              <Alert severity="error">{status}</Alert>
            </Box>
          )}
          <TextField name={cFieldNames.username} label={'Username'} size={'medium'} />
          <Box my={1} />
          <TextField
            name={cFieldNames.password}
            label={'Password'}
            type={'password'}
            size={'medium'}
          />
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
                lg: 'flex',
              },
              alignItems: 'center',
            }}
          >
            <FormGroup />
          </Box>
          <Box mt={2} />

          <SubmitButton loading={loading} title={'Přihlásit se'} fullWidth size="large" />
        </>
      )}
    </CustomForm>
  );
};
